import React from 'react'
import { Typography, TextField } from '@mui/material'
import { IconButton, IconTag, UploadButton } from 'components'
import { UploadCompleteType, UploadValueType } from 'types'
import { useDebounce } from 'utils'
import { Launch } from '@mui/icons-material'

interface Props {
	icon: React.ReactNode
	label: string
	description: string
	context: string
	fileTypes: string
	value: UploadValueType
	addText?: string
	hideTag?: boolean
	video?: boolean
	maxSize?: number
	onLinkUpdated?: (link: string) => void
	onUploadComplete: (props: UploadCompleteType) => void
}

export const UploadBlock = ({
	icon,
	label,
	description,
	context,
	fileTypes,
	addText = 'Choose File',
	hideTag,
	video,
	maxSize,
	value,
	onLinkUpdated,
	onUploadComplete,
}: Props) => {
	const [link, setLink] = React.useState<string>(value.link || '')
	const debouncedLink = useDebounce(link, 800)

	const validLink =
		debouncedLink.indexOf('.') > 0 && debouncedLink.indexOf('http') >= 0

	const handleVisitSite = () => {
		if (validLink) {
			window.open(value.link, '_blank')
		}
	}

	React.useEffect(() => {
		if (debouncedLink !== value.link && onLinkUpdated) {
			onLinkUpdated(debouncedLink)
		}
	}, [value.link, debouncedLink, onLinkUpdated])

	return (
		<div className="md:grid md:grid-cols-2 md:gap-4 mb-20">
			<div className="md:mr-5 max-w-[400px] mx-auto">
				<div className="flex items-center mb-3">
					{icon}
					<Typography variant="contentLabel" className="block ml-2">
						{label}
					</Typography>
				</div>
				<Typography className="font-semibold">{description}</Typography>
				{!value.key && !hideTag && (
					<div className="mt-2">
						<IconTag
							icon="Warning"
							text={`Upload your ${context.toLowerCase()}!`}
							color="#f48225"
						/>
					</div>
				)}
			</div>
			<div className="mt-4 md:mt-0 max-w-[400px] md:max-w-[480px] mx-auto w-full">
				{'link' in value && (
					<div className="flex items-center justify-between relative max-w-[480px] mb-4 pb-1 border-b border-solid border-gray-300">
						<div className="w-full">
							<TextField
								placeholder="Add a link (optional)"
								defaultValue={value.link}
								size="small"
								onChange={e => setLink(e.target.value)}
								fullWidth
								className="line-clamp-1"
							/>
						</div>
						{value.link && (
							<IconButton
								tooltip={!validLink ? 'Please enter a valid URL' : 'Visit Site'}
								onClick={handleVisitSite}
								className="ml-3 mb-3"
							>
								<Launch />
							</IconButton>
						)}
					</div>
				)}
				<UploadButton
					addText={addText}
					context={context}
					fileTypes={fileTypes}
					onUploadComplete={onUploadComplete}
					maxSize={maxSize}
					value={value}
					video={video}
				/>
			</div>
		</div>
	)
}
