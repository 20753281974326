import React, { FC } from 'react'
import {
	FormControlLabel,
	FormControlLabelProps,
	Checkbox as MuiCheckbox,
	Typography,
} from '@mui/material'

interface Props extends Omit<FormControlLabelProps, 'onChange' | 'control'> {
	checked?: boolean
	onChange: (checked: boolean) => void
}

export const Checkbox: FC<Props> = ({
	checked = false,
	label,
	onChange,
	...rest
}) => {
	return (
		<FormControlLabel
			{...rest}
			onChange={(_, checked) => onChange(checked)}
			control={<MuiCheckbox defaultChecked={checked} />}
			label={<Typography className="font-bold">{label}</Typography>}
		/>
	)
}
