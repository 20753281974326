import React, { FC } from 'react'
import { QRCode } from 'react-qrcode-logo'
import { Box, Typography, useTheme } from '@mui/material'

import {
	ContentState,
	convertFromRaw,
	convertToRaw,
	EditorState,
} from 'draft-js'
import {
	Button,
	Card,
	ClientHeader,
	Page,
	TextField,
	Link,
	CheckboxCompleteButton,
	IconButton,
	Image,
	PopoverButton,
	BackButton,
	StarRating,
	ActiveButtonGroup,
	AddNewButton,
	StartCompleteButton,
	UploadButton,
	Checkbox,
	PropertyCard,
	DashboardCard,
	ItemList,
	ContactItem,
	DataItem,
	DataViewerCard,
	HouseCleaning,
	OfferCard,
	InfoDisplay,
	Fireworks,
	PipelineHeader,
	TextEditor,
	ContentSlider,
	ProgressBar,
	FAQBlock,
	Table,
	StageLabel,
	ColorChip,
	Avatar,
	TimezonePicker,
	DatePicker,
	TimePicker,
} from 'components'
import { useForm } from 'react-hook-form'
import {
	Folder,
	CheckCircle,
	Settings,
	Edit,
	Article,
	Message,
	Notifications,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useDialog } from 'providers'
import { ClientQuestionnaireView } from 'views'
import { GridSelectionModel } from '@mui/x-data-grid'
import { DEFAULT_USER_PHOTO } from 'lib/theme'

type FormData = {
	email: string
	password: string
}

const defaultValues = {
	email: '',
	password: '',
}

export const Components: FC = () => {
	const navigate = useNavigate()
	const [selectedRows, setSelectedRows] = React.useState<GridSelectionModel>()
	const { setDialogOpen, setDialogClosed } = useDialog()
	const [editorState, setEditorState] = React.useState(
		EditorState.createEmpty()
	)
	const theme = useTheme()
	const { handleSubmit, control } = useForm<FormData>({ defaultValues })
	const handleCreateAgent = async (data: FormData) => {
		console.log(data)
		// const result = await createAgent({
		// 	first: 'Walter',
		// 	last: 'Whitaker',
		// 	email: 'test',
		// 	phone: 'test',
		// 	password: 'test',
		// })
		// console.log(result)
	}

	const addresses = [
		{
			key: 'hjfjw382u29842',
			value: '1254 S Michigan Ave, Chicago IL, 60605',
			street: '1255 S Michigan Ave',
			line2: undefined,
			city: 'Chicago',
			state: 'IL',
			zip: '60605',
			beds: 3,
			bath: 3,
			sqft: 4000,
			checked: false,
			primary:
				'Some super long text that is probably unnecessary. laksjdf lasfjl;sak jflksajf lksajfd lksjflksjfd lkjsdfl kjsalfkj alsdkfj alskdfj laskjdf lksajdf lkasjfdlaskdfj aslkj',
		},
		{
			key: 'hjfj24234w382u29842',
			value: '1255 S Michigan Ave, Chicago IL, 60605',
			street: '1255 S Michigan Ave',
			line2: undefined,
			city: 'Chicago',
			state: 'IL',
			zip: '60605',
			beds: 3,
			bath: 3,
			sqft: 4000,
			checked: false,
			primary:
				'Some super long text that is probably unnecessary. laksjdf lasfjl;sak jflksajf lksajfd lksjflksjfd lkjsdfl kjsalfkj alsdkfj alskdfj laskjdf lksajdf lkasjfdlaskdfj aslkj',
		},
	]

	return (
		<Page header={<div />}>
			<div>
				<Box display="flex" justifyContent="center">
					<Card
						contain={380}
						title="Please Sign In"
						action="Sign Up!"
						actionClick={() => navigate('/sign-up')}
					>
						<form onSubmit={handleSubmit(handleCreateAgent)}>
							<TextField control={control} name="first" label="First Name" />
							<TextField control={control} name="last" label="Last Name" />
							<TextField
								type="email"
								control={control}
								name="email"
								label="Email Address"
							/>
							<TextField control={control} name="phone" label="Phone Number" />
							<TextField
								type="password"
								control={control}
								name="password"
								label="Password"
							/>
							<Button type="submit" variant="contained" fullWidth>
								Sign Up
							</Button>
						</form>
					</Card>
				</Box>
				<CheckboxCompleteButton
					text="Listing Step Complete"
					onChange={complete => console.log(complete)}
				/>
				<div className="badhous-pulse">
					<IconButton tooltip="Files">
						<Notifications sx={{ fill: '#e61c1c !important' }} />
					</IconButton>
				</div>
				<Button variant="outlined" startIcon={<CheckCircle />}>
					Website
				</Button>
				<IconButton badge tooltip="Files">
					<Folder />
				</IconButton>
				<Image />
				<PopoverButton title="Sort by" />
				<BackButton />
				<StarRating
					count={5}
					rating={2}
					onChange={rating => console.log(rating)}
				/>
				<ActiveButtonGroup
					options={[
						{ label: 'Agent', value: 9 },
						{ label: 'Buyer', value: 3 },
						{ label: 'Agent', value: 9 },
						{ label: 'Buyer', value: 3 },
					]}
					onChange={value => console.log(value)}
				/>
				<ActiveButtonGroup
					options={['Agent', 'Buyer']}
					onChange={value => console.log(value)}
				/>
				{/* <AddNewButton onClick={() => console.log('add new')} /> */}
				<StartCompleteButton complete={true} path="/forgot-password" />
				{/* <UploadButton>Choose File</UploadButton> */}
				<Checkbox
					checked
					label="Send Listing Questionnaire"
					onChange={checked => console.log(checked)}
				/>
				<QRCode value="https://app.badhous.com" />
				<Box display="flex">
					<PropertyCard
						uuid="1"
						address="1255 S Michigan Ave, Chicago IL, 60605"
						description="3bed 2bath 4000sqft"
						adminViewer
					/>
					<PropertyCard
						uuid="2"
						address="1255 S Michigan Ave, Chicago IL, 60605"
						description="3bed 2bath 4000sqft"
						adminViewer
					/>
				</Box>
				<DashboardCard
					title="My Tasks"
					subtitle="Use the daily routine to help increase productivity, stay motivated, and make progress towards your long term goals."
					action={
						<IconButton tooltip="Settings" noBorder>
							<Settings />
						</IconButton>
					}
				>
					<ItemList
						type="n/a"
						ordered
						hideCheck
						items={addresses}
						secondaryAction={
							<Button variant="contained" onClick={() => console.log('start')}>
								Start
							</Button>
						}
						onClick={value => console.log(value)}
					/>
					<Typography>List type 2</Typography>
					<ItemList
						type="n/a"
						strike
						ordered
						onClick={value => console.log(value)}
						items={addresses}
					/>
				</DashboardCard>
				<Card flat>
					<TextField
						type="email"
						control={control}
						name="email"
						label="Email Address"
					/>
					<TextField
						type="password"
						control={control}
						name="password"
						label="Password"
					/>
				</Card>
				<ContactItem
					name="James Moreau"
					subtext="Client"
					onClick={() => console.log('clicked')}
				/>
				<ContactItem
					name="James Moreau"
					subtext="via Facebook"
					color="blue"
					action={
						<IconButton tooltip="Edit" onClick={() => console.log('edit')}>
							<Edit />
						</IconButton>
					}
				/>
				<ContactItem
					name="James Moreau"
					subtext="via Facebook"
					alert="Send a follow up email"
					color="green"
					action={
						<IconButton tooltip="Edit" onClick={() => console.log('edit')}>
							<Edit />
						</IconButton>
					}
				/>
				<ContactItem
					name="James Moreau"
					subtext="via Facebook"
					color="red"
					action={
						<IconButton tooltip="Edit" onClick={() => console.log('edit')}>
							<Edit />
						</IconButton>
					}
				/>
				{/* <AddNewButton height={72} onClick={() => console.log('add new')} /> */}
				<DataItem title="House Feature/Amenity" />
				<DataItem
					title="Chris Mitchell signed a Lease Contract"
					subtitle="01/08/2022 6:45am"
				/>
				<DataViewerCard
					highlight
					title="Complete Listing Questionnaire"
					headerIcon={<Article sx={{ fontSize: 26 }} color="secondary" />}
					action={
						<Button variant="contained" onClick={() => console.log('start')}>
							Start
						</Button>
					}
				/>
				<DataViewerCard
					disabled
					title="Complete Listing Questionnaire"
					headerIcon={<Article sx={{ fontSize: 26 }} color="primary" />}
					action={
						<Button variant="outlined" onClick={() => console.log('start')}>
							Start
						</Button>
					}
				/>
				<DataViewerCard
					title="Est. Home Value"
					titleSuffix="720k"
					headerAction={
						<IconButton
							noBorder
							tooltip="Edit"
							onClick={() => console.log('edit')}
						>
							<Edit />
						</IconButton>
					}
					action={
						<Button variant="outlined" onClick={() => console.log('upload')}>
							Upload Comparative Market Analysis
						</Button>
					}
				/>

				<DataViewerCard
					title="Listing Questionnaire"
					headerIcon={<Article sx={{ fontSize: 26 }} color="secondary" />}
					action={
						<Button variant="outlined" onClick={() => console.log('view')}>
							View
						</Button>
					}
				/>
				<DataViewerCard
					title="Check to make sure everything looks good on your MLS listing."
					titleIcon={<Article fontSize="large" color="secondary" />}
					action={
						<Button variant="contained" onClick={() => console.log('view')}>
							View
						</Button>
					}
				/>
				<DataViewerCard
					title="Clean up! A photographer will come when you’re ready"
					titleIcon={HouseCleaning}
					action={
						<Button variant="contained" onClick={() => console.log('view')}>
							Schedule Photos
						</Button>
					}
				/>
				<Box className="flex items-center py-3 justify-between">
					<OfferCard accepted listPrice={470} offerPrice={520} rating={5} />
					<OfferCard listPrice={470} offerPrice={470} rating={3} />
					<OfferCard listPrice={470} offerPrice={450} rating={2} />
				</Box>
				<InfoDisplay message="Offers are rated 1 - 5 stars according to their quality, by your agent." />
				{Fireworks}
				<PipelineHeader
					title="Leads"
					description="test"
					count={4}
					color={theme.palette.green.light}
				/>
				<PipelineHeader
					title="Meetings"
					description="test"
					count={4}
					color={theme.palette.blue.light}
				/>
				<PipelineHeader
					title="Contracts Waiting"
					description="test"
					count={4}
					color={theme.palette.red.light}
				/>
				<Button
					variant="contained"
					onClick={() =>
						setDialogOpen({
							content: (
								<ContentSlider
									footerContent={
										<ProgressBar
											currentStep={2}
											steps={4}
											label="of 4 steps complete"
											stepWithLabel
										/>
									}
								>
									{ClientQuestionnaireView.map((el, idx) => (
										<div key={idx}>{el()}</div>
									))}
								</ContentSlider>
							),
						})
					}
				>
					Open Dialog
				</Button>
				<TextEditor
					state={editorState}
					placeholder={
						editorState.getCurrentContent().hasText() ? '' : 'Add notes here...'
					}
					onChange={newState => {
						setEditorState(newState)
					}}
				/>
				{/* <FAQBlock
					faqIndex={0}
					name="faq1"
					control={control}
					onDelete={() => console.log('delete faq')}
				/> */}
				<Table
					dataContext="clients"
					rows={[
						{
							id: '0',
							name: 'Walter Whitaker',
							address: 'Some address',
							stage: 'Listing',
						},
						{ id: '1', name: '', address: 'Some address', stage: 'Listing' },
						{
							id: '2',
							name: 'Walter Whitaker',
							address: 'Some address',
							stage: 'Marketing',
						},
						{
							id: '3',
							name: 'Walter Whitaker',
							address: 'Some address',
							stage: 'Marketing',
						},
						{ id: '4', name: 'Walter Whitaker', address: '', stage: 'Closing' },
					]}
					rowHeight={65}
					rowCount={5}
					columns={[
						{
							field: 'id',
							headerName: '#',
							width: 50,
							renderCell: (params: any) => (
								<Typography>{parseInt(params.formattedValue) + 1}</Typography>
							),
						},
						{
							field: 'address',
							headerName: 'Address',
							flex: 1,
							minWidth: 200,
							renderCell: (params: any) => (
								<Typography>{params.formattedValue || 'No address'}</Typography>
							),
						},
						{
							field: 'name',
							headerName: 'Name',
							flex: 1,
							minWidth: 200,
							renderCell: (params: any) => (
								<Typography>{params.formattedValue || 'No name'}</Typography>
							),
						},
						{
							field: 'stage',
							headerName: 'Stage',
							width: 150,
							renderCell: (params: any) => (
								<StageLabel label={params.formattedValue} />
							),
						},
						{
							field: 'actions',
							headerAlign: 'center',
							headerName: 'Actions',
							width: 110,
							renderCell: (params: any) => {
								return (
									<Box display="flex" alignItems="center">
										<IconButton
											onClick={e => {
												console.log('message')
												e.stopPropagation()
											}}
											tooltip="Send Message"
											verticalAdjustment={1}
										>
											<Message />
										</IconButton>
										<IconButton
											className="ml-2"
											onClick={e => {
												console.log('view files')
												e.stopPropagation()
											}}
											tooltip="View Files"
										>
											<Folder />
										</IconButton>
									</Box>
								)
							},
						},
					]}
					pageSize={10}
					rowsPerPageOptions={[]}
					onRowClick={() => console.log('row clicked')}
					disableNext={false}
					disablePrevious={true}
					onNextPage={() => console.log('next')}
					onPreviousPage={() => console.log('previous')}
					disableHeaderActions
					hideFooterSelectedRowCount
					disableSelectionOnClick
				/>
				<Box marginTop={2}>
					<ColorChip label="Potential Buyer" />
					<ColorChip color="green" label="Interested Buyer" />
					<ColorChip color="red" label="Submitted Offer" />
				</Box>
				<Avatar src={DEFAULT_USER_PHOTO} />
				<TimezonePicker />
				{/* <DatePicker onDateChange={date => console.log(date)} /> */}
				{/* <TimePicker /> */}
			</div>
		</Page>
	)
}

const timeSlots = ['']
