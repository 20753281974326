import React, { FC } from 'react'
import copy from 'copy-to-clipboard'
import { Typography, useTheme } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

interface Props {
	text: string
	opaque?: boolean
	maxWidth?: number
}

export const CopyText: FC<Props> = ({ text, opaque, maxWidth = 320 }) => {
	const { enqueueSnackbar } = useSnackbar()
	const theme = useTheme()

	const handleCopy = () => {
		copy(text)
		enqueueSnackbar('Copied to clipboard!', { variant: 'success' })
	}

	return (
		<div
			className={`flex items-center justify-between rounded-md border border-solid border-gray-300 py-2 px-3 bg-white cursor-pointer`}
			onClick={handleCopy}
			style={{
				maxWidth,
				boxSizing: 'border-box',
			}}
		>
			<Typography
				className="line-clamp-1 ml-1 ml-1 font-semibold text-gray-700"
				sx={{ opacity: opaque ? 0.7 : 1 }}
			>
				{text}
			</Typography>
			<div className="flex items-center bg-white pl-2">
				<Typography
					className="font-bold mr-1"
					sx={{ color: theme.palette.icon.dark }}
				>
					Copy
				</Typography>
				<ContentCopy sx={{ color: theme.palette.icon.main }} />
			</div>
		</div>
	)
}
