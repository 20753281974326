import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { ColorDot } from './color-dot'

interface Props {
	label: 'Listing' | 'Marketing' | 'Closing'
	showStageLabel?: boolean
}

export const StageLabel = ({ label, showStageLabel }: Props) => {
	const theme = useTheme()

	let color = theme.palette.blue.light
	switch (label) {
		case 'Marketing':
			color = theme.palette.green.light
			break
		case 'Closing':
			color = theme.palette.red.light
			break
	}

	return (
		<Box display="flex" alignItems="center">
			<ColorDot color={color} />
			<Typography className="font-semibold">
				{label}
				{showStageLabel && ' Stage'}
			</Typography>
		</Box>
	)
}
