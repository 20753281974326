import React from 'react'
import uuid from 'react-uuid'
import { Box, Checkbox, Popover, Typography } from '@mui/material'
import { AddCircle, ContentCopy } from '@mui/icons-material'
import { AvailabilityBlockType, AvailabilityDateType, DayType } from 'types'
import { Button, TimeBlock, Tooltip } from 'components'
import { IconButton } from './icon-button'
import { useAvailability, useSaveChanges } from 'providers'
import { convertTime12To24 } from 'utils'
import {
	formatInTimeZone,
	toDate,
	utcToZonedTime,
	zonedTimeToUtc,
} from 'date-fns-tz'
import { format } from 'path'

interface Props {
	date: AvailabilityDateType
	dateIndex: number
}

export const TimeAvailabilityItem = ({ date, dateIndex }: Props) => {
	const { blocks, checked } = date
	const [tmpBlocks, setTmpBlocks] = React.useState(blocks)
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

	const {
		availability: { timezone },
		setAvailability,
	} = useAvailability()
	const { unsavedChanges, setUnsavedChanges } = useSaveChanges()

	const handleToggle = () => {
		const newDateObj = {
			...date,
			checked: !checked,
		}
		setAvailability(dateIndex, newDateObj)
		if (!unsavedChanges) {
			setUnsavedChanges()
		}
	}

	const handleChange = (
		index: number,
		value: AvailabilityBlockType,
		complete: boolean
	) => {
		blocks.splice(index, 1, value)
		const newDateObj = {
			...date,
			blocks,
		}
		setAvailability(dateIndex, newDateObj)
		if (!unsavedChanges && complete) {
			setUnsavedChanges()
		}
	}

	const handleDelete = (index: number) => {
		let emptyBlock = false
		if (!blocks[index].startTime && !blocks[index].endTime) {
			emptyBlock = true
		}

		blocks.splice(index, 1)
		const newBlocks = [...blocks]
		const newDateObj = {
			...date,
			blocks: newBlocks,
		}

		setAvailability(dateIndex, newDateObj)
		if (!unsavedChanges && !emptyBlock) {
			setUnsavedChanges()
		}
	}

	const handleAdd = () => {
		const newDateObj = {
			...date,
			blocks: [
				...blocks,
				{
					startTime: '',
					endTime: '',
				},
			],
		}

		setTmpBlocks(newDateObj.blocks)
	}

	const handleCopy = (toIndex: number, day: DayType) => {
		const newDateObj = {
			day,
			checked: true,
			blocks: [...date.blocks],
		}
		setAvailability(toIndex, newDateObj)
		if (!unsavedChanges) {
			setUnsavedChanges()
		}
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const checkBlocks = [...blocks]
	const lastBlock = checkBlocks.pop()

	const noBlocksAvailable = lastBlock && lastBlock.endTime === '11:30pm'

	return (
		<Box
			className={`${
				checked ? 'pt-6' : 'pt-4'
			} relative pb-4 md:grid grid-cols-12 max-w-[620px] md:w-[620px] border-b border-solid border-gray-300`}
		>
			<div className="col-span-2">
				<div className="flex items-center">
					<Tooltip
						title={checked ? `Mark as Unavailable` : `Mark as Available`}
					>
						<Checkbox checked={checked} onClick={handleToggle} />
					</Tooltip>
					<Typography className="ml-1 font-bold">{date.day}</Typography>
				</div>
			</div>
			<div className="col-span-7">
				{!checked && (
					<Typography className="mt-[9px] mb-5 ml-3 font-bold text-gray-500">
						Unavailable
					</Typography>
				)}
				<div
					className="mt-6 md:mt-0"
					style={{
						opacity: !checked ? 0.5 : 1,
						pointerEvents: !checked ? 'none' : 'all',
					}}
				>
					{tmpBlocks?.length ? (
						tmpBlocks.map((item: any, index: number) => {
							return (
								<TimeBlock
									key={uuid()}
									index={index}
									block={item}
									prevBlock={
										tmpBlocks?.length > 1 ? tmpBlocks[index - 1] : undefined
									}
									nextBlock={
										tmpBlocks?.length > 1 ? tmpBlocks[index + 1] : undefined
									}
									onChange={handleChange}
									onDelete={handleDelete}
									hideDelete={index === 0}
								/>
							)
						})
					) : (
						<TimeBlock
							index={0}
							block={{ startTime: '9:00am', endTime: '5:00pm' }}
							onChange={handleChange}
							onDelete={handleDelete}
							hideDelete
						/>
					)}
				</div>
			</div>
			{checked && (
				<div className="col-span-3 badhous-availability-item-actions">
					<div className="flex items-center justify-end">
						<IconButton
							tooltip={
								noBlocksAvailable ? 'No Slots Available' : 'Add Time Slot'
							}
							className="mr-1"
							noBorder
							onClick={noBlocksAvailable ? undefined : handleAdd}
							sx={{ opacity: noBlocksAvailable ? 0.5 : 1 }}
						>
							<AddCircle />
						</IconButton>
						<div onClick={e => setAnchorEl(e.currentTarget)}>
							<IconButton tooltip="Copy Time" noBorder>
								<ContentCopy />
							</IconButton>
						</div>
					</div>
					<Popover
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
					>
						<div className="h-[300px] max-h-[300px] w-[135px] py-2">
							<Typography
								variant="contentLabel"
								className="block pb-2 text-center mb-1 border-b border-solid border-gray-300"
							>
								Copy To
							</Typography>
							{Object.keys(DayType).map((day, index) => {
								return date.day !== day ? (
									<Button
										key={day}
										variant="flat"
										size="small"
										sx={{ padding: '8px 4px', width: '100%' }}
										onClick={() => handleCopy(index, day as DayType)}
									>
										{day}
									</Button>
								) : null
							})}
						</div>
					</Popover>
				</div>
			)}
		</Box>
	)
}
