import React from 'react'
import { AppHeader, CopyText, Page } from 'components'
import { getUser } from 'utils'
import { useNavigate } from 'react-router-dom'
import { AgentWebsite, WebsiteInternalMenu } from 'views'

export const Website = () => {
	const navigate = useNavigate()
	let data: any = getUser() || {}

	React.useEffect(() => {
		if (!data.user) {
			navigate('/sign-in')
		}
	}, [data, navigate])

	if (!data.user || data.user.type === 'client') {
		return null
	}

	let header = <AppHeader first={data.user.first} type={data.user.type} />
	let page = null
	switch (data.user.type) {
		case 'agent':
			page = (
				<Page
					header={header}
					sidebar={data.user.type}
					title="Website"
					appendToTitle={<CopyText text={data.website.publicUrl} opaque />}
					menuRight={<WebsiteInternalMenu publicUrl={data.website.publicUrl} />}
				>
					<AgentWebsite uuid={data.user.uuid} />
				</Page>
			)
			break
		case 'brokerage':
			// page = <BrokerageWebsite />
			break
	}

	return page ? page : null
}
