import { ForceGenericObjectType } from 'types'
import { getLocalStorageItem, setLocalStorageItem } from '..'

export const LOCAL_STORAGE_KEY_VIEWER_LEAD = '__viewer_lead'

export function setLead(lead: ForceGenericObjectType | null) {
	setLocalStorageItem(LOCAL_STORAGE_KEY_VIEWER_LEAD, lead)
}

export function getLead(): string | null {
	return getLocalStorageItem(LOCAL_STORAGE_KEY_VIEWER_LEAD)
}
