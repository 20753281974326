import React from 'react'
import { Typography } from '@mui/material'
import { Avatar, ContentBlock, PropertyCard, TextArea } from 'components'
import { AgentSetClientData, AgentSetWebsiteSettings } from 'services'
import { AddressType, UserClientType } from 'types'
import { SAMPLE_HOME_PHOTO, DEFAULT_USER_PHOTO } from 'lib/theme'
import { getUser, setUser, useDebounce } from 'utils'
import { useSnackbar } from 'notistack'

interface Props {
	uuid: string
}

export const AgentWebsite = ({ uuid }: Props) => {
	const profile = getUser()
	const { enqueueSnackbar } = useSnackbar()
	const [clientUpdated, setClientUpdated] = React.useState<any[]>()
	const [headline, setHeadline] = React.useState<string>(
		profile.website.settings.siteHeader
	)
	const debouncedHeadline = useDebounce(headline, 600)
	const agentHeadshot =
		profile.settings.listingProfile.photo.publicUrl || DEFAULT_USER_PHOTO

	const handleUpdateHeadline = async (newHeadline: string) => {
		const newSettings = {
			...profile,
			website: {
				...profile.website,
				settings: {
					...profile.website.settings,
					siteHeader: newHeadline,
				},
			},
		}
		const result = await AgentSetWebsiteSettings({
			settings: newSettings.website.settings,
			agentUuid: profile.user.uuid,
		})
		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			return
		}
		enqueueSnackbar('Headline updated!', { variant: 'success' })
		setUser(newSettings)
	}

	const handleChangeVisibility = async (
		clientUuid: string,
		clientIndex: number,
		addressIndex: number,
		visibility: boolean
	) => {
		const newClients = getUser().clients
		const newAddresses = newClients[clientIndex].addresses
		newAddresses.splice(addressIndex, 1, {
			...newAddresses[addressIndex],
			listingOnline: visibility,
		})
		const result = await AgentSetClientData({
			agentUuid: uuid,
			clientUuid,
			updates: [
				{
					key: 'addresses',
					value: newAddresses,
				},
			],
		})
		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			return
		}
		setClientUpdated(newClients)
	}

	React.useEffect(() => {
		if (debouncedHeadline !== profile.website.settings.siteHeader) {
			handleUpdateHeadline(debouncedHeadline)
		}
	}, [debouncedHeadline])

	React.useEffect(() => {
		if (clientUpdated) {
			setUser({
				...profile,
				clients: [...clientUpdated],
			})
			setClientUpdated(undefined)
		}
	}, [clientUpdated])

	return (
		<>
			<div className="max-w-[700px] mx-auto md:max-w-full md:mx-0 mt-6 md:grid grid-cols-12 items-center">
				<div className="col-span-5">
					<ContentBlock
						label="Site Headline (160 chars max)"
						description="Your headline is the first thing people read when they land on your website, keep it short, simple, and sweet."
						margin="6"
					/>
					<TextArea
						id={`faq-textarea-website`}
						className="!font-bold"
						style={{ backgroundColor: '#fff' }}
						defaultValue={headline}
						onChange={e => setHeadline(e.target.value)}
						maxLength={160}
					/>
				</div>
				<div className="relative col-span-7 h-full">
					<Typography className="font-semibold pl-16 mb-5 text-gray-600">
						Headline Preview
					</Typography>
					<div className="m_md:hidden flex items-center md:ml-16">
						<Avatar
							src={agentHeadshot}
							border={!Boolean(profile.settings.listingProfile.photo.publicUrl)}
						/>
						<Typography variant="h5" className="max-w-[350px] font-black ml-6">
							{headline}
						</Typography>
					</div>
				</div>
			</div>
			<div className="max-w-[700px] mx-auto md:max-w-full md:mx-0 mt-10 pt-6 border-t border-solid border-gray-300">
				<div className="mt-2 mb-4 max-w-[600px]">
					<ContentBlock
						label="Active Listings"
						description="These are your active listings (clients), they will appear on your website along with the description, photos, features etc. You can hide or edit them below."
					/>
				</div>
				<div className="flex flex-wrap justify-center sm:justify-start">
					{profile?.clients?.map(
						(client: UserClientType, clientIndex: number) => {
							return client.addresses.map(
								(
									{
										street,
										line2,
										city,
										state,
										zip,
										beds,
										bath,
										sqft,
										listingOnline,
									}: AddressType,
									addressIndex
								) => {
									return (
										<div key={`${street}-${addressIndex}`} className="mb-4">
											<PropertyCard
												uuid={client.uuid}
												listingOnline={listingOnline}
												address={`${street}, ${
													line2 ? `${line2},` : ''
												} ${city} ${state}, ${zip}`}
												description={`${beds}bed ${bath}bath ${sqft}sqft`}
												imageUrl={SAMPLE_HOME_PHOTO}
												onChangeVisibility={(uuid, visibility) =>
													handleChangeVisibility(
														uuid,
														clientIndex,
														addressIndex,
														visibility
													)
												}
												adminViewer
											/>
										</div>
									)
								}
							)
						}
					)}
				</div>
			</div>
		</>
	)
}
