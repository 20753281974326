import React from 'react'
import {
	List,
	ListItem,
	ListItemProps,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Checkbox,
	Typography,
	useTheme,
	useMediaQuery,
} from '@mui/material'
import { IconButton } from './icon-button'
import { RemoveCircleOutline } from '@mui/icons-material'

type ListItemData = {
	key: string
	value?: string
	primary?: string
	secondary?: string
	checked?: boolean
}

interface Props<T extends ListItemData> extends Omit<ListItemProps, 'onClick'> {
	type: string
	items: T[]
	strike?: boolean
	ordered?: boolean
	hideCheck?: boolean
	noItemsSpacing?: boolean
	loading?: number
	disableHoverWithSecondary?: boolean
	secondaryAction?: React.ReactNode
	secondaryActionClick?: (index: number) => void
	onDelete?: (index: number) => void
	onClick?: (
		item: {
			value: string | undefined
			index: number
		},
		checked?: boolean
	) => void
}

export function ItemList<T extends ListItemData>({
	type,
	items,
	strike = false,
	ordered = false,
	disabled = false,
	hideCheck = false,
	noItemsSpacing = false,
	onDelete,
	loading,
	disableHoverWithSecondary = false,
	secondaryAction,
	secondaryActionClick,
	onClick,
	...rest
}: Props<T>) {
	const handleToggle = (
		value: string | undefined,
		index: number,
		checked: boolean
	) => {
		if (onClick) {
			if (hideCheck) {
				onClick({ value, index })
			} else {
				onClick({ value, index }, !checked)
			}
		}
	}

	const theme = useTheme()
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
	const hasAction = secondaryAction || Boolean(onDelete)

	return items && items.length ? (
		<List sx={{ width: '100%' }}>
			{items.map((item, i) => {
				const labelId = `checkbox-list-label-${item.value}`
				const isChecked = item.checked
				return item ? (
					<ListItem
						{...rest}
						key={`${item.key || item.primary}-${i}`}
						secondaryAction={
							<>
								<div
									onClick={e => {
										if (e.detail === 1) {
											secondaryActionClick && secondaryActionClick(i)
										}
									}}
								>
									{secondaryAction}
								</div>
								{onDelete && (
									<IconButton
										noBorder
										tooltip="Remove"
										sx={{ marginLeft: 1 }}
										onClick={() => onDelete(i)}
									>
										<RemoveCircleOutline />
									</IconButton>
								)}
							</>
						}
						disablePadding
					>
						<ListItemButton
							role={undefined}
							onClick={() => handleToggle(item.value, i, Boolean(item.checked))}
							className={hasAction ? 'flex-row-reverse' : ''}
							sx={{
								alignItems: hasAction ? 'flex-start' : 'center',
								transition: '.2s',
								pointerEvents: Boolean(loading === i) ? 'none' : 'all',
								opacity: Boolean(loading === i) ? 0.7 : 1,
								cursor:
									hasAction && disableHoverWithSecondary
										? 'default'
										: Boolean(loading === i)
										? 'wait'
										: 'pointer',
								'&:hover': {
									backgroundColor:
										hasAction && disableHoverWithSecondary
											? '#fff'
											: isMediumScreen
											? '#fff'
											: '#f0f0f0',
								},
							}}
							disableRipple={Boolean(hasAction)}
							disableTouchRipple={Boolean(hasAction)}
						>
							<ListItemText
								id={labelId}
								primary={
									<Typography>
										{ordered && <span className="pr-2">{i + 1}.</span>}
										<span
											className={`pr-4 ${
												strike && isChecked ? 'badhous-list-item-checked' : ''
											}`}
											style={{
												maxWidth: hasAction ? '80%' : '100%',
											}}
										>
											{item.primary}
										</span>
									</Typography>
								}
								secondary={
									item.secondary && (
										<Typography
											className={`${ordered ? 'pl-5' : ''} text-gray-600 mt-1`}
										>
											{item.secondary}
										</Typography>
									)
								}
							/>
							{!hideCheck && (
								<ListItemIcon sx={{ marginRight: hasAction ? 1 : 0 }}>
									<Checkbox
										edge="start"
										checked={isChecked}
										tabIndex={-1}
										disableRipple
										inputProps={{ 'aria-labelledby': labelId }}
										sx={{ padding: '5px 9px' }}
									/>
								</ListItemIcon>
							)}
						</ListItemButton>
					</ListItem>
				) : null
			})}
		</List>
	) : (
		<Typography
			align="center"
			className={`${noItemsSpacing ? 'pt-5' : ''} px-4 pb-5 font-bold`}
		>
			No {type}
		</Typography>
	)
}
