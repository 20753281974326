import React, { FC } from 'react'
import {
	styled,
	LinearProgress,
	linearProgressClasses,
	Box,
	Typography,
} from '@mui/material'

interface Props {
	currentStep: number
	steps: number
	width?: number
	label?: string
	stepWithLabel?: boolean
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 12,
	borderRadius: 6,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[300],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 6,
		backgroundColor: theme.palette.primary.main,
	},
}))

export const ProgressBar: FC<Props> = ({
	currentStep = 0,
	steps,
	width = 120,
	label,
	stepWithLabel = false,
}) => {
	return (
		<Box className="flex items-center">
			<Box className={stepWithLabel ? 'mr-2' : ''} sx={{ width }}>
				<BorderLinearProgress
					variant="determinate"
					value={Math.floor((currentStep / steps) * 100)}
				/>
			</Box>
			{stepWithLabel && (
				<Box sx={{ minWidth: 35 }}>
					<Typography
						variant="body2"
						color="text.secondary"
						className="font-bold"
					>
						{stepWithLabel && currentStep} {label}
					</Typography>
				</Box>
			)}
		</Box>
	)
}
