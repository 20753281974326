import uuid from 'react-uuid'
import {
	AvailabilityBlockType,
	AvailabilityType,
	ClientListingType,
	MeetingsType,
	ReactKeyedValueType,
} from 'types'
import {
	addMinutes,
	subMinutes,
	format,
	startOfDay,
	isPast,
	differenceInMinutes,
	roundToNearestMinutes,
	differenceInHours,
	compareAsc,
	addHours,
	subHours,
	endOfDay,
} from 'date-fns'
import {
	toDate,
	format as formatTimezone,
	utcToZonedTime,
	zonedTimeToUtc,
} from 'date-fns-tz'

export const getReactKeyedValues = (list: string[]): ReactKeyedValueType[] => {
	return list.map(value => {
		return {
			key: uuid(),
			value,
		}
	})
}
export function getReactKeyedObjectValues<T>(list: T[]): T[] {
	return list.map(value => {
		return {
			key: uuid(),
			...value,
		}
	})
}

export const capitalize = (str: string) =>
	`${str.charAt(0).toUpperCase()}${str.slice(1)}`

export const reactKeyedValue = () => {
	return {
		key: uuid(),
		value: '',
	}
}

type AvailableTimesProps = {
	indexFor: string
	jump?: boolean
	limitTo?: string
}
export const getAvailableTimes = ({
	indexFor,
	jump = false,
	limitTo,
}: AvailableTimesProps): string[] => {
	const { timesOfDayArr } = getTimesOfDay()
	return timesOfDayArr.slice(
		timesOfDayArr.indexOf(indexFor) + (jump ? 1 : 0),
		limitTo ? timesOfDayArr.indexOf(limitTo) : undefined
	)
}

export const getMinutes = (mins: number): string => {
	const num = mins
	const hours = num / 60
	const rhours = Math.floor(hours)
	const minutes = (hours - rhours) * 60
	const rminutes = Math.round(minutes)
	const plural = rhours > 1 ? 's' : ''

	if (rhours && !rminutes) {
		return `${rhours} hour${plural}`
	} else if (rhours && rminutes) {
		return `${rhours}hr${plural} ${rminutes}min`
	}

	return `${rminutes} minutes`
}

const getTimesOfDay = (selectedDate?: Date) => {
	const start = startOfDay(selectedDate || new Date())
	const MINUTES_IN_DAY = 24 * 60
	const INTERVAL_COUNT = MINUTES_IN_DAY / 30

	const timesOfDayObj: { [key: string]: Date } = {}

	const timesOfDayArr = Array.from(new Array(INTERVAL_COUNT).keys()).map(i => {
		const time = addMinutes(start, i * 30)
		const index = format(time, 'h:mma').toLowerCase()
		timesOfDayObj[index] = time

		return index
	})

	return {
		timesOfDayArr,
		timesOfDayObj,
	}
}

const getAvailableTimesFromRange = (
	availability: AvailabilityType,
	selectedDate: Date,
	timeRange: AvailabilityBlockType[]
): string[] => {
	let availableTimes: string[] = []
	let timeRangeLength = timeRange.length - 1

	const { timesOfDayArr, timesOfDayObj } = getTimesOfDay(selectedDate)

	function getTimesInRange(availabilityBlock: AvailabilityBlockType) {
		const minEnd = roundToNearestMinutes(
			subMinutes(
				timesOfDayObj[availabilityBlock.endTime],
				availability.stepInMinutes + 15
			),
			{ nearestTo: 30 }
		)
		const startDiff = timesOfDayArr.indexOf(availabilityBlock.startTime)
		const endDiff = timesOfDayArr.indexOf(format(minEnd, 'h:mma').toLowerCase())

		availableTimes.unshift(...timesOfDayArr.slice(startDiff, endDiff + 1))
		timeRangeLength = timeRangeLength - 1

		if (timeRangeLength >= 0) {
			getTimesInRange(timeRange[timeRangeLength])
		}
	}

	getTimesInRange(timeRange[timeRangeLength])

	availableTimes = availableTimes.map(time => {
		return timesOfDayObj[time].toString()
	})

	return availableTimes
}

const getAvailabilityDayIndex = (day: string): number => {
	let dayIndex = 0
	switch (day) {
		case 'tue':
			dayIndex = 1
			break
		case 'wed':
			dayIndex = 2
			break
		case 'thu':
			dayIndex = 3
			break
		case 'fri':
			dayIndex = 4
			break
		case 'sat':
			dayIndex = 5
			break
		case 'sun':
			dayIndex = 6
			break
	}

	return dayIndex
}

export const convertTime12To24 = (timeStr: string) => {
	const time = timeStr.replace('am', ' am').replace('pm', ' pm')
	let hours = Number(time.match(/^(\d+)/)?.[1])
	const minutes = Number(time.match(/:(\d+)/)?.[1])
	const AMPM = time.split(/\s(.*)$/)?.[1]
	if (AMPM === 'pm' && hours < 12) hours = hours + 12
	if (AMPM === 'am' && hours === 12) hours = hours - 12
	let sHours = hours
	let sMinutes = minutes
	if (hours < 10) sHours = sHours
	if (minutes < 10) sMinutes = sMinutes
	return [sHours, sMinutes]
}

export const addToMeetings = (
	dateTime: Date,
	meetings: MeetingsType = {
		mon: {},
		tue: {},
		wed: {},
		thu: {},
		fri: {},
		sat: {},
		sun: {},
	}
): MeetingsType => {
	const start = startOfDay(dateTime)
	const startString = format(start, 'yyyy-MM-dd')
	const day = format(start, 'E').toLowerCase()
	const curDayMeetings = meetings[day]?.[startString] || []

	const newMeetings = { ...meetings }
	newMeetings[day] = {
		...meetings[day],
		[startString]: [...curDayMeetings, dateTime.toString()],
	}

	Object.keys(meetings[day]).forEach(meetingDay => {
		if (isPast(endOfDay(dateTime))) {
			delete meetings[day][meetingDay]
		}
	})

	return newMeetings
}

export const getLocalTimeFromZone = (
	dateTime: Date,
	initialZone: string,
	localZone: string
) => {
	const initialZonedTime = zonedTimeToUtc(new Date(dateTime), localZone)
	const localZonedTime = utcToZonedTime(initialZonedTime.getTime(), initialZone)

	return localZonedTime
}

export const getAvailableTimesToScheduleAppointments = (
	selectedDate: Date,
	availability: AvailabilityType,
	meetings: any,
	initialTimezone?: string
) => {
	const localTimeZone =
		initialTimezone ||
		Intl.DateTimeFormat().resolvedOptions().timeZone.replace(/ /g, '_')
	const timezone = availability.timezone.replace(/ /g, '_')
	const start = startOfDay(selectedDate)
	const day = format(selectedDate, 'E').toLowerCase()
	const availabilityByDay = availability.data[getAvailabilityDayIndex(day)]
	const availabilityByDayBlocks = availabilityByDay.checked
		? availabilityByDay.blocks
		: null

	const baseAvailableTimes = availabilityByDayBlocks?.length
		? getAvailableTimesFromRange(
				availability,
				selectedDate,
				availabilityByDayBlocks
		  )
		: []

	const formattedStart = format(start, 'yyyy-MM-dd')
	const scheduledMeetings: string[] = meetings[day][formattedStart] || []

	if (timezone !== localTimeZone) {
		const initialTime = new Date([...baseAvailableTimes].pop() as string)
		const lastAvailableTime = utcToZonedTime(initialTime, localTimeZone)
		const lastAvailableTimeInZone = zonedTimeToUtc(initialTime, timezone)

		const offsetHours = Math.abs(
			differenceInHours(lastAvailableTime, lastAvailableTimeInZone)
		)
		let addOrSub = true
		if (compareAsc(lastAvailableTime, lastAvailableTimeInZone)) {
			addOrSub = false
		}

		if (isPast(lastAvailableTimeInZone)) {
			return []
		}

		let availableTimes = [...baseAvailableTimes]
			.filter((availableTime: string) => {
				const availableDateTime = new Date(availableTime)

				const initialZonedTime = zonedTimeToUtc(
					new Date(availableDateTime),
					timezone
				)
				const localZonedTime = utcToZonedTime(initialZonedTime, localTimeZone)

				let comparePast = availableDateTime

				if (addOrSub) {
					comparePast = subHours(availableDateTime, offsetHours)
				} else {
					comparePast = addHours(availableDateTime, offsetHours)
				}

				if (
					!isPast(comparePast) &&
					!scheduledMeetings.includes(localZonedTime.toString())
				) {
					return scheduledMeetings.every(meeting => {
						const meetingDateTime = new Date(meeting)

						return (
							Math.abs(
								differenceInMinutes(availableDateTime, meetingDateTime)
							) >= availability.stepInMinutes
						)
					})
				}
			})
			.map(time => {
				const initialZonedTime = zonedTimeToUtc(new Date(time), timezone)
				const localZonedTime = utcToZonedTime(initialZonedTime, localTimeZone)
				return localZonedTime
			})

		let earlyHours: Date[] = []
		availableTimes = availableTimes.filter(dateTime => {
			const hours = dateTime.getHours() === 0
			if (hours) {
				earlyHours.push(dateTime)
				return false
			}
			return true
		})

		if (earlyHours.length) {
			availableTimes.unshift(...earlyHours)
		}

		return availableTimes
	} else {
		const lastAvailableTime = new Date([...baseAvailableTimes].pop() as string)

		if (isPast(lastAvailableTime)) {
			return []
		}

		const availableTimes = [...baseAvailableTimes]
			.filter((availableTime: string) => {
				const availableDateTime = new Date(availableTime)

				if (
					!isPast(availableDateTime) &&
					!scheduledMeetings.includes(availableTime)
				) {
					return scheduledMeetings.every(meeting => {
						const meetingDateTime = new Date(meeting)

						return (
							Math.abs(
								differenceInMinutes(availableDateTime, meetingDateTime)
							) >= availability.stepInMinutes
						)
					})
				}
			})
			.map(time => {
				return new Date(time)
			})

		return availableTimes
	}
}

export const formatPhoneNumber = (input: string) => {
	input = input.replace(/\D/g, '')
	input = input.substring(0, 10)

	const size = input.length
	if (size === 0) {
		return input
	} else if (size < 4) {
		input = '(' + input
	} else if (size < 7) {
		input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6)
	} else {
		input =
			'(' +
			input.substring(0, 3) +
			') ' +
			input.substring(3, 6) +
			'-' +
			input.substring(6, 10)
	}
	return input
}

export const getAddressAndDescription = (
	{
		street,
		line2,
		city,
		state,
		zip,
		beds,
		bath,
		sqft,
	}: ClientListingType['address'],
	addressAsObj?: boolean
) => {
	let address: any = `${street} `
	address += line2
		? `${line2} ${city} ${state} ${zip}`
		: `${city} ${state} ${zip}`

	if (addressAsObj) {
		address = {
			street,
			line2,
			location: `${city} ${state} ${zip}`,
		}
	}

	let description = `${beds}bed ${bath}bath ${sqft}sqft`

	return {
		address,
		description,
	}
}

export const getClientListing = (
	listings: ClientListingType[],
	client: string,
	index: string
): ClientListingType | false => {
	const filteredListings = listings.filter(listing => {
		const foundClient = listing.client === client
		const foundListing = listing.index === parseInt(index)

		return foundClient && foundListing
	})

	if (filteredListings.length === 1) {
		return filteredListings[0]
	}

	return false
}

export const scrollToScheduler = () => {
	const schedulerTop = document?.getElementById(
		'#badhous-footer-scheduler'
	)?.offsetTop

	window.scroll(0, schedulerTop || document.body.scrollHeight)
}
