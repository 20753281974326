import React, { FC } from 'react'
import { PoweredBy } from 'lib'
import { useTheme, useMediaQuery, Popover, Menu, MenuItem } from '@mui/material'
import { Avatar, MobileMenuIcon } from 'components'
import { ArrowDropDown } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

interface Props {
	first: string
	type: string
}

export const AppHeader: FC<Props> = ({ first, type }) => {
	const theme = useTheme()
	const navigate = useNavigate()
	const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null)
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<div
			className={`grid grid-cols-12 px-4 h-16 items-center border-solid border-b border-gray-300 badhous-header-border`}
		>
			{isMediumScreen ? (
				<div className="col-span-6">
					<MobileMenuIcon />
				</div>
			) : (
				<div className="flex items-center col-span-8">
					<Avatar text={first[0]} />
					<p className="flex items-center text-lg badhous-user-slug font-semibold">
						<div
							className="flex items-center"
							// onClick={e => setAnchorEl(e.currentTarget)}
						>
							{first}
							{/* <ArrowDropDown /> */}
						</div>
						<span className="text-base text-gray-800 badhous-user-slug capitalize">
							{type} Portal
						</span>
					</p>
				</div>
			)}
			<div
				className={`flex justify-end ${
					isMediumScreen ? 'col-span-6' : 'col-span-4'
				}`}
			>
				<PoweredBy />
			</div>
			{/* <Popover
				open={Boolean(anchorEl)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
			>
				<div onClick={() => setAnchorEl(null)} className="py-1">
					<MenuItem
						onClick={() => navigate('/sign-in')}
						className="font-semibold"
					>
						Sign Out
					</MenuItem>
				</div>
			</Popover> */}
		</div>
	)
}
