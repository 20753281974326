import React from 'react'
import { TextField as MaterialTextField, TextFieldProps } from '@mui/material'
import { Controller } from 'react-hook-form'
import { formatPhoneNumber } from 'utils'
import { TextFieldOnChangeType } from 'types'

interface Props
	extends Pick<TextFieldProps, 'className' | 'helperText' | 'InputProps'> {
	name: string
	label: string
	control: any
	caps?: boolean
	defaultValue?: string
	numbersOnly?: boolean
	maxLength?: number
	type?: 'email' | 'password'
	phoneMask?: boolean
	autoFocus?: boolean
	required?: boolean
	disabled?: boolean
	error?: boolean
	size?: 'small' | 'medium'
	onChange?: (data: TextFieldOnChangeType) => void
}

export const TextField = ({
	name,
	label,
	control,
	caps,
	defaultValue = '',
	numbersOnly,
	maxLength,
	type,
	phoneMask,
	autoFocus = false,
	required,
	disabled,
	error: initialError,
	size = 'medium',
	className,
	helperText,
	InputProps,
	onChange,
}: Props) => {
	const [error, setError] = React.useState(initialError)
	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (onChange) {
			onChange({ name, value: event.target.value })
		}
	}

	React.useEffect(() => {
		setError(initialError)
	}, [initialError])

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field: { onChange, value: curValue, ...rest } }) => {
				let showError = error ? true : false
				if (type === 'password' && curValue.length < 8 && error) {
					showError = true
				}

				let value = curValue
				if (phoneMask) {
					value = formatPhoneNumber(curValue)
				}

				return (
					<MaterialTextField
						{...rest}
						className={className}
						helperText={helperText}
						autoFocus={autoFocus}
						required={required}
						onChange={event => {
							handleChange(event)
							onChange(event)
						}}
						value={value}
						label={label}
						variant="outlined"
						type={type}
						InputLabelProps={{
							style: { fontWeight: 600 },
						}}
						inputProps={{
							maxLength,
							style: {
								textTransform: caps ? 'uppercase' : 'unset',
							},
						}}
						InputProps={InputProps}
						disabled={disabled}
						size={size}
						error={showError || Boolean(error)}
						fullWidth
						onBlur={() => (value && !error ? setError(false) : setError(true))}
					/>
				)
			}}
		/>
	)
}
