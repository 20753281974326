import React, { FC } from 'react'
import { Tab, Tabs, Typography, Box } from '@mui/material'

interface TabSetProps {
	context: string
	tabTitles: string[]
	disabled?: boolean[]
	tabIndex?: number
	onTabChanged?: (value: number) => void
	children?: React.ReactNode
}

export const TabSet: FC<TabSetProps> = ({
	tabTitles,
	context,
	disabled,
	tabIndex = 0,
	onTabChanged,
	children,
}) => {
	const [value, setValue] = React.useState(tabIndex)
	const tabs = React.Children.toArray(children)

	const handleChange = (_: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
		onTabChanged && onTabChanged(newValue)
	}

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label={`${context} tab set`}
				>
					{tabTitles.map((title, index) => {
						return (
							<Tab
								key={title}
								label={title}
								{...a11yProps(context, 0)}
								disabled={disabled ? !disabled[index] : false}
							/>
						)
					})}
				</Tabs>
			</Box>
			{React.Children.map(tabs, (child, index) => {
				return (
					<TabPanel key={index} context={context} value={value} index={index}>
						{child}
					</TabPanel>
				)
			})}
		</Box>
	)
}

interface PanelProps {
	children?: React.ReactNode
	index: number
	value: number
	context: string
}

const TabPanel = ({
	children,
	index,
	value,
	context,
	...other
}: PanelProps) => {
	return (
		<div
			role={context}
			hidden={value !== index}
			id={`${context}-tabpanel-${index}`}
			aria-labelledby={`${context}-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	)
}

const a11yProps = (context: string, index: number) => {
	return {
		id: `${context}-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}
