export * from './client'
export * from './dashboard'
export * from './settings'
export * from './user'
export * from './website'
export type NavRoutesType = {
	name: string
	path: string
	secondary?: {
		title: string
		routes: {
			name: string
			path: string
		}[]
	}
}[]
export type ReactKeyedValueType = { key: string; value: string }
export type ReactKeyedObjectType = { key: string; [key: string]: any }
export type CheckedItemListType = {
	primary: string
	checked: boolean
}
export type ErrorMessageType =
	| {
			message: string
			key: string
	  }
	| undefined
export type ForceGenericObjectType = {
	[key: string]: any
}
export type ForceGenericObjectArrayType = {
	[key: string]: [any]
}
export type EventType<T = EventTarget> = {
	target: T
}
export type OnboardingStep = {
	complete: boolean
	title: string
	subtitle: string
	path: string
}
export enum DayType {
	MON = 'MON',
	TUE = 'TUE',
	WED = 'WED',
	THU = 'THU',
	FRI = 'FRI',
	SAT = 'SAT',
	SUN = 'SUN',
}
export type MeetingsType = {
	[key: string]: any
}
export type UploadValueType = {
	key: string
	filename: string
	label: string
	publicUrl: string
	link?: string
}
export type UploadCompleteType = {
	context: string
} & UploadValueType

export type SocialMediaType = {
	facebook: string
	instagram: string
	twitter: string
	linkedin: string
	zillow: string
	pinterest: string
}

export type ChecklistType = {
	listing: string[]
	marketing: string[]
	closing: string[]
}

export type TextFieldOnChangeType = {
	name: string
	value: string
}
