import React, { FC } from 'react'
import { SaveChangesFooter } from 'components'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import { useSaveBeforeExit } from 'utils'

interface Props {
	save: boolean
	canceled: boolean
	unsavedChanges: boolean
	setSave: (save: boolean) => void
	setCanceled: () => void
	setUnsavedChanges: () => void
}

export const SaveChangesContext = React.createContext<Props>({
	save: false,
	canceled: false,
	unsavedChanges: false,
	setSave: () => {},
	setCanceled: () => {},
	setUnsavedChanges: () => {},
})

export const SaveChangesProvider: FC<{ children: React.ReactNode }> = ({
	children,
}): JSX.Element => {
	const [save, setSave] = React.useState(false)
	const [canceled, setCanceled] = React.useState(false)
	const [unsavedChanges, setUnsavedChanges] = React.useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const mount = React.useRef(0)

	const handleSave = (shouldSave: boolean) => {
		setSave(shouldSave)
	}

	const handleUnsavedChanges = () => {
		setUnsavedChanges(true)
	}

	React.useEffect(() => {
		if (mount.current && !save) {
			setUnsavedChanges(false)
			setCanceled(false)
			enqueueSnackbar('Your changes have been saved!', { variant: 'success' })
		}
		mount.current = 1
	}, [save, enqueueSnackbar])

	useSaveBeforeExit(
		unsavedChanges,
		() => {
			setSave(true)
			setCanceled(false)
		},
		() => {
			setUnsavedChanges(false)
			setCanceled(true)
		}
	)

	return (
		<SaveChangesContext.Provider
			value={{
				save,
				canceled,
				unsavedChanges,
				setSave: handleSave,
				setCanceled: () => setCanceled(false),
				setUnsavedChanges: handleUnsavedChanges,
			}}
		>
			{children}
			<SaveChangesFooter unsavedChanges={unsavedChanges} />
		</SaveChangesContext.Provider>
	)
}
