import React from 'react'
import { styled, alpha, Menu, MenuProps, MenuItem } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Button } from 'components'
import { useLeads } from 'providers'

interface Props {
	title: string
	content?: JSX.Element
	items?: React.ReactNode[]
	close?: boolean
}

export const DropdownMenuButton = ({ title, content, items, close }: Props) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	React.useEffect(() => {
		if (close) {
			setAnchorEl(null)
		}
	}, [close])

	return (
		<div>
			<Button
				id="action-menu-button"
				aria-controls={open ? 'action-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				variant="contained"
				onClick={handleClick}
				endIcon={<KeyboardArrowDown />}
			>
				{title}
			</Button>
			<StyledMenu
				id="action-menu"
				MenuListProps={{
					'aria-labelledby': 'action-menu',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				onClickCapture={e => console.log(e)}
			>
				{content}
				{items?.map((item, index) => {
					return (
						<MenuItem key={index} disableRipple>
							<div className="py-1 w-full">{item}</div>
						</MenuItem>
					)
				})}
			</StyledMenu>
		</div>
	)
}

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
			theme.palette.mode === 'light'
				? 'rgb(55, 65, 81)'
				: theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}))
