import React from 'react'
import { ClientHeader, Page } from 'components'
import { getClient } from 'utils'
import { useNavigate } from 'react-router-dom'

export const ClientPortal = () => {
	const user = getClient() as any
	const navigate = useNavigate()

	React.useEffect(() => {
		if (!user.uuid) {
			navigate('/sign-in')
		}
	}, [user, navigate])

	if (!user.uuid) {
		return null
	}

	let header = <ClientHeader first={user.first} addresses={user.addresses} />

	return (
		<Page header={header}>
			<div>client portal</div>
		</Page>
	)
}
