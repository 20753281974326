import React, { FC } from 'react'
import { Button as MuiButton, ButtonProps, Typography } from '@mui/material'

export const CardFooterButton: FC<ButtonProps> = ({
	onClick,
	children,
	...rest
}) => {
	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (onClick && event.detail === 1) {
			onClick(event)
		}
	}

	return (
		<MuiButton {...rest} onClick={handleClick} variant="flat">
			<Typography>{children}</Typography>
		</MuiButton>
	)
}
