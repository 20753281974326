import React, { FC } from 'react'
import {
	QuestionnaireType,
	QuestionnaireBlockType,
	QuestionnaireEnum,
} from 'types'
import { getUser } from 'utils'

const INITIAL_STATE = [{ freeResponse: false, question: '', answers: [] }]

interface Props {
	sellerQuestionnaire: QuestionnaireType
	buyerQuestionnaire: QuestionnaireType
	initialize: (questionnaire: QuestionnaireType[]) => void
	setQuestionnaire: (
		type: QuestionnaireEnum,
		index: number,
		questionnaireBlock?: QuestionnaireBlockType
	) => void
	resetQuestionnaire: (
		type: QuestionnaireEnum,
		questionnaire: QuestionnaireType
	) => void
	addToQuestionnaire: (
		type: QuestionnaireEnum,
		questionnaireBlock: QuestionnaireBlockType
	) => void
}

export const QuestionnaireContext = React.createContext<Props>({
	sellerQuestionnaire: INITIAL_STATE,
	buyerQuestionnaire: INITIAL_STATE,
	initialize: () => {},
	setQuestionnaire: () => {},
	resetQuestionnaire: () => {},
	addToQuestionnaire: () => {},
})

export const QuestionnaireProvider: FC<{ children: React.ReactNode }> = ({
	children,
}): JSX.Element => {
	const profile = getUser()
	const savedSellerQuestionnaire =
		profile?.[QuestionnaireEnum.SELLER_QUESTIONNAIRE]
	const savedBuyerQuestionnaire =
		profile?.[QuestionnaireEnum.BUYER_QUESTIONNAIRE]
	const [sellerQuestionnaire, setSellerQuestionnaire] = React.useState(
		savedSellerQuestionnaire?.length ? savedSellerQuestionnaire : INITIAL_STATE
	)
	const [buyerQuestionnaire, setBuyerQuestionnaire] = React.useState(
		savedBuyerQuestionnaire?.length ? savedBuyerQuestionnaire : INITIAL_STATE
	)

	const handleInitialize = (initializer: QuestionnaireType[]) => {
		setSellerQuestionnaire(initializer[0])
		setBuyerQuestionnaire(initializer[1])
	}

	const handleSetQuestionnaire = (
		type: QuestionnaireEnum,
		index: number,
		questionnaireBlock?: QuestionnaireBlockType
	) => {
		if (type === QuestionnaireEnum.SELLER_QUESTIONNAIRE) {
			if (questionnaireBlock) {
				sellerQuestionnaire.splice(index, 1, questionnaireBlock)
			} else {
				sellerQuestionnaire.splice(index, 1)
			}
			setSellerQuestionnaire([...sellerQuestionnaire])
		} else if (type === QuestionnaireEnum.BUYER_QUESTIONNAIRE) {
			if (questionnaireBlock) {
				buyerQuestionnaire.splice(index, 1, questionnaireBlock)
			} else {
				buyerQuestionnaire.splice(index, 1)
			}
			setBuyerQuestionnaire([...buyerQuestionnaire])
		}
	}

	const handleResetQuestionnaire = (
		type: QuestionnaireEnum,
		newQuestionnaire: QuestionnaireType
	) => {
		if (type === QuestionnaireEnum.SELLER_QUESTIONNAIRE) {
			setSellerQuestionnaire([...newQuestionnaire])
		} else if (type === QuestionnaireEnum.BUYER_QUESTIONNAIRE) {
			setBuyerQuestionnaire([...newQuestionnaire])
		}
	}

	const handleAddQuestionToQuestionnaire = (
		type: QuestionnaireEnum,
		questionnaireBlock: QuestionnaireBlockType
	) => {
		if (type === QuestionnaireEnum.SELLER_QUESTIONNAIRE) {
			setSellerQuestionnaire([...sellerQuestionnaire, questionnaireBlock])
		} else if (type === QuestionnaireEnum.BUYER_QUESTIONNAIRE) {
			setBuyerQuestionnaire([...buyerQuestionnaire, questionnaireBlock])
		}
	}

	return (
		<QuestionnaireContext.Provider
			value={{
				sellerQuestionnaire,
				buyerQuestionnaire,
				initialize: handleInitialize,
				setQuestionnaire: handleSetQuestionnaire,
				resetQuestionnaire: handleResetQuestionnaire,
				addToQuestionnaire: handleAddQuestionToQuestionnaire,
			}}
		>
			{children}
		</QuestionnaireContext.Provider>
	)
}
