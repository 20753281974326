import request from '../request'
import {
	AgentWebsiteSettingsType,
	CheckedItemListType,
	ForceGenericObjectArrayType,
	ForceGenericObjectType,
	LeadConversionBlockType,
	LeadMeetingLinkEmail,
	QuestionnaireEnum,
	QuestionnaireType,
} from 'types'

export const AgentGetLeads = (payload: { agentUuid: string }) => {
	return request({
		url: '/agent/leads/get-leads',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}

export const AgentSaveLead = (payload: {
	agentUuid: string
	lead: LeadConversionBlockType
}) => {
	return request({
		url: '/agent/leads/save-lead',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}

export const AgentSetLead = (payload: {
	agentUuid: string
	leads?: {
		leadUuid: string
		updates: ForceGenericObjectType[]
	}[]
	deleteUuid?: string
}) => {
	return request({
		url: '/agent/leads/set-lead',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}

export const AgentSendMeetingLinkToLead = (payload: LeadMeetingLinkEmail) => {
	return request({
		url: '/agent/leads/send-meeting-link',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}

export const AgentSetDashboardTasks = (payload: {
	data: CheckedItemListType[]
	agentUuid: string
}) => {
	return request({
		url: '/agent/dashboard/set-tasks',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}

export const AgentSetDashboardRoutine = (payload: {
	routine: CheckedItemListType[]
	agentUuid: string
}) => {
	return request({
		url: '/agent/dashboard/set-routine',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}

export const AgentSetWebsiteSettings = (payload: {
	settings: AgentWebsiteSettingsType
	agentUuid: string
}) => {
	return request({
		url: '/agent/website/set-settings',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}

export const AgentSetSettingsData = (payload: {
	agentUuid: string
	updates: ForceGenericObjectType[]
}) => {
	return request({
		url: '/agent/settings/set-settings',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}

export const AgentSetMeetingsData = (payload: {
	meetings: ForceGenericObjectArrayType
	agent: ForceGenericObjectType
	client: ForceGenericObjectType
}) => {
	return request({
		url: '/agent/meetings/set-meetings',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}

export const AgentGetMeetingsData = (payload: { agentUuid: string }) => {
	return request({
		url: '/agent/meetings/get-meetings',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}

export const AgentSetClientData = (payload: {
	agentUuid: string
	clientUuid: string
	updates: ForceGenericObjectType[]
}) => {
	return request({
		url: '/agent/client/set-client',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}

export const AgentSetQuestionnaire = (payload: {
	type: QuestionnaireEnum
	agentUuid: string
	questionnaire: QuestionnaireType
}) => {
	return request({
		url: '/agent/set-questionnaire',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}
