import React from 'react'
import { ButtonGroup, Button, Typography, useTheme } from '@mui/material'

interface Props {
	activeIndex?: number
	options: string[] | { label: string; value: number | string }[]
	onChange: (value: { value: number; label: string }) => void
}

export const ActiveButtonGroup = ({
	activeIndex = 0,
	options,
	onChange,
}: Props) => {
	const [active, setActive] = React.useState(activeIndex)
	const theme = useTheme()
	const black = `${theme.palette.primary.main} !important`
	const white = `${theme.palette.common.white} !important`

	const handleClick = (value: number, label: string) => {
		setActive(value)
		onChange({ value, label })
	}

	return (
		<ButtonGroup fullWidth variant="outlined" color="primary" className="mb-4">
			{options.map((option, i) => {
				const optionNotString = typeof option !== 'string'
				const label = optionNotString ? option.label : option
				return (
					<Button
						key={i}
						onClick={() => handleClick(i, label)}
						sx={{
							backgroundColor: i === active ? black : 'unset',
							'&:hover': {
								backgroundColor: i === active ? black : theme.palette.grey[100],
							},
						}}
					>
						<Typography
							className={`flex ${
								optionNotString ? 'justify-between' : 'justify-center'
							}`}
							sx={{
								flex: 1,
								color: i === active ? white : black,
								'& span': {
									color: i === active ? white : black,
								},
							}}
						>
							{label}
							{optionNotString && (
								<span className="font-bold">{option.value}</span>
							)}
						</Typography>
					</Button>
				)
			})}
		</ButtonGroup>
	)
}
