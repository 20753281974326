import React from 'react'
import { Typography } from '@mui/material'
import { differenceInDays } from 'date-fns'
import {
	AgentLeadActionEnum,
	LeadConversionBlockType,
	LeadConversionType,
} from 'types'
import { Button, LeadCard, PopoverButton } from 'components'
import { useLeads } from 'providers'
import { sendMeetingLink, updateLead } from '../helpers'
import { useSnackbar } from 'notistack'
import { EMOJI_COLD } from 'lib/theme'

export const ColdProspects = () => {
	const { leads, setLeads } = useLeads()
	const { enqueueSnackbar } = useSnackbar()
	const [prospects, setProspects] = React.useState<LeadConversionBlockType[]>(
		[]
	)

	const handleLeadAction = async (
		action: AgentLeadActionEnum,
		prospectIndex: number,
		leadIndex: number
	) => {
		const newLeads = { ...leads }
		const lead = prospects[prospectIndex]

		switch (action) {
			case AgentLeadActionEnum.SCHEDULE_MEETING:
				newLeads.prospects[leadIndex].schedulingAttempt = new Date()
				await scheduleMeeting(lead, newLeads)
				break
			case AgentLeadActionEnum.REMIND_ME_LATER:
				newLeads.prospects[leadIndex].schedulingAttempt = new Date()
				await remindLater(lead, newLeads)
				break
			case AgentLeadActionEnum.ARCHIVE:
				newLeads.prospects.splice(lead.index, 1)
				newLeads.archived.push({
					...lead,
				})
				await archiveLead(lead, newLeads)
				break
		}
	}

	const scheduleMeeting = async (
		lead: LeadConversionBlockType,
		newLeads: LeadConversionType
	) => {
		await sendMeetingLink(lead)

		setLeads(newLeads)
		enqueueSnackbar(`Email Sent to ${lead.name.split(' ')[0]}!`, {
			variant: 'success',
		})
	}

	const remindLater = async (
		lead: LeadConversionBlockType,
		newLeads: LeadConversionType
	) => {
		await updateLead(lead.uuid, [
			{
				key: 'schedulingAttempt',
				value: new Date(),
			},
		])

		setLeads(newLeads)
		enqueueSnackbar(`3 Day Reminder Set for ${lead.name.split(' ')[0]}!`, {
			variant: 'success',
		})
	}

	const archiveLead = async (
		lead: LeadConversionBlockType,
		newLeads: LeadConversionType
	) => {
		await updateLead(lead.uuid, [
			{
				key: 'archived',
				value: 1,
			},
		])

		setLeads(newLeads)
		enqueueSnackbar(`${lead.name.split(' ')[0]} Moved to Archive`, {
			variant: 'success',
		})
	}

	React.useEffect(() => {
		const prospectArr: LeadConversionBlockType[] = new Array()

		leads.prospects.forEach((lead, index) => {
			if (differenceInDays(new Date(), new Date(lead.schedulingAttempt)) >= 3) {
				prospectArr.push({ ...lead, index })
			}
		})

		setProspects(prospectArr)
	}, [leads])

	return (
		<div className="overflow-y-auto badhous-dialog-content-body">
			<Typography className="font-bold text-xl text-center">
				Prospects Are Cooling Off! {EMOJI_COLD}
			</Typography>
			<Typography className="sm:text-lg mt-2 font-semibold text-center">
				Let's try to get you some meetings :)
			</Typography>
			<div className="m_xmd:mt-6 mt-4 xmd:flex items-center flex-wrap max-w-[560px] mx-auto">
				{prospects.map((lead, index) => {
					return (
						<LeadCard
							key={lead.uuid}
							leadType={lead.type}
							name={lead.name}
							subtext={[`Source: ${lead.source}`]}
							className={
								'xmd:even:ml-2 w-[275px] max-w-[275px] mb-2 m_xmd:mx-auto'
							}
						>
							<Typography className="font-bold text-center text-lg mb-4">
								How did it go?
							</Typography>
							<PopoverButton
								customButton={
									<Button
										className="block bg-gray-200 hover:bg-[#daf6db] rounded-lg mb-2"
										fullWidth
									>
										Schedule Meeting
									</Button>
								}
								onPromptButtonClick={() =>
									handleLeadAction(
										AgentLeadActionEnum.SCHEDULE_MEETING,
										index,
										lead.index
									)
								}
							>
								<div className="p-4 pb-2 max-w-[280px]">
									<Typography className="font-semibold">
										We'll send a scheduling link to{' '}
										<span className="font-black">{lead.name}</span>. Once they
										schedule their meeting it will appear in your scheduled
										meetings section!
									</Typography>
								</div>
							</PopoverButton>
							<Button
								onClick={() =>
									handleLeadAction(
										AgentLeadActionEnum.REMIND_ME_LATER,
										index,
										lead.index
									)
								}
								className="block bg-gray-200 hover:bg-[#dfe9ff] rounded-lg mb-2"
								fullWidth
							>
								Remind Me Later
							</Button>
							<PopoverButton
								customButton={
									<Button
										className="block bg-gray-200 hover:bg-gray-300 rounded-lg mb-2"
										fullWidth
									>
										Archive
									</Button>
								}
								onPromptButtonClick={() =>
									handleLeadAction(
										AgentLeadActionEnum.ARCHIVE,
										index,
										lead.index
									)
								}
							>
								<div className="p-4 pb-2 max-w-[280px]">
									<Typography className="text-lg font-bold">
										Are you sure?
									</Typography>
									<Typography className="mt-2 font-semibold">
										Archiving this lead will remove it from your Prospects
										pipeline. You'll be able to find {lead.name} in your
										archives.
									</Typography>
								</div>
							</PopoverButton>
						</LeadCard>
					)
				})}
			</div>
		</div>
	)
}
