import React from 'react'
import { Button, TextField } from 'components'
import { useSnackbar } from 'notistack'
import { useDialog } from 'providers'
import { useForm } from 'react-hook-form'
import {
	LeadConversionBlockType,
	ErrorMessageType,
	BuyerOrSellerType,
} from 'types'
import { validateForm } from 'utils'
import { AgentSetLead } from 'services'

interface Props {
	lead: LeadConversionBlockType
	publicViewer?: boolean
	onSave?: (lead: LeadConversionBlockType) => void
	onSuccess?: (lead: LeadConversionBlockType) => void
}

export const DialogForm = ({
	lead,
	publicViewer,
	onSave,
	onSuccess,
}: Props) => {
	const { enqueueSnackbar } = useSnackbar()
	const [submittingForm, setSubmittingForm] = React.useState(false)
	const [error, setError] = React.useState<ErrorMessageType>()

	const { name: fullName, email, phone, source } = lead

	const { handleSubmit, control } = useForm({
		defaultValues: {
			name: fullName,
			email,
			phone,
			source,
		},
	})

	const handleSaveLead = async (data: any) => {
		setSubmittingForm(true)
		setError(undefined)

		const error = validateForm(data, {
			name: 'Full Name',
			email: 'Email',
			phone: 'Phone Number',
			source: publicViewer ? "How'd you find me?" : 'Lead Source',
		})

		if (error) {
			setError(error)
			setSubmittingForm(false)
			return
		}

		if (onSave) {
			onSave({
				...lead,
				...data,
			})
			return
		}

		const result = await AgentSetLead({
			agentUuid: lead.agentUuid,
			leads: [
				{
					leadUuid: lead.uuid,
					updates: Object.keys(data).map(key => {
						return {
							key,
							value: data[key],
						}
					}),
				},
			],
		})

		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			return
		}
		if (onSuccess) {
			onSuccess({
				...lead,
				...data,
			})
		}
		enqueueSnackbar('Lead info updated!', { variant: 'success' })
		setSubmittingForm(false)
	}

	React.useEffect(() => {
		if (error) {
			enqueueSnackbar(error.message, { variant: 'error' })
		}
	}, [error, enqueueSnackbar])

	return (
		<div>
			<form
				onSubmit={handleSubmit(handleSaveLead)}
				className="max-w-[400px] mx-auto p-5"
			>
				<TextField
					control={control}
					name="name"
					label="Full Name"
					error={error?.key === 'name'}
				/>
				<TextField
					control={control}
					type="email"
					name="email"
					label="Email"
					error={error?.key === 'email'}
				/>
				<TextField
					phoneMask
					control={control}
					name="phone"
					label="Phone (numbers only)"
					error={error?.key === 'phone'}
				/>
				<TextField
					control={control}
					name="source"
					label={publicViewer ? "How'd you find me?" : 'Lead Source'}
					error={error?.key === 'source'}
				/>
				<Button
					type="submit"
					variant="contained"
					fullWidth
					loading={submittingForm}
				>
					{publicViewer ? 'Schedule Call' : 'Save'}
				</Button>
			</form>
		</div>
	)
}
