import React, { FC } from 'react'
import { IconButton } from '../molecules'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

export const BackButton: FC = () => {
	const navigate = useNavigate()

	const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (event.detail === 1) {
			navigate(-1)
		}
	}

	return (
		<Box onClick={handleClick} className="flex items-center cursor-pointer">
			<IconButton tooltip="Go back">
				<ArrowBack />
			</IconButton>
			<Typography className="ml-3" variant="iconLabel" color="blue">
				Back
			</Typography>
		</Box>
	)
}
