import React, { FC } from 'react'
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import { NavSidebar } from './nav-sidebar'
import { useMobileMenu } from 'providers'
import { Button } from 'components'
import { KeyboardArrowDown } from '@mui/icons-material'

interface Props {
	header: React.ReactNode
	title?: string
	appendToTitle?: React.ReactNode
	menuRight?: React.ReactNode
	children: React.ReactNode
	sidebar?: 'agent' | 'brokerage'
	centered?: boolean
	hideAlert?: boolean
}

export const Page: FC<Props> = ({
	header,
	title,
	appendToTitle,
	menuRight,
	children,
	sidebar,
	centered,
}) => {
	const theme = useTheme()
	const { menuOpen } = useMobileMenu()
	const [menuActive, setMenuActive] = React.useState(false)
	const isXMediumScreen = useMediaQuery(theme.breakpoints.down('xmd'))
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<Box width="100%" className="pt-[64px]">
			<div className="fixed top-0 left-0 right-0 h-[64px] z-[9999] bg-[#f7f7f7]">
				{header}
			</div>
			<div
				className={`flex pt-4 ${
					isMediumScreen ? 'pl-5 pr-4' : 'pr-4'
				} md:pl-[225px] xl:justify-center`}
				style={{
					height: 'calc(100vh - 65px)',
					boxSizing: 'border-box',
					marginBottom: 0,
					paddingBottom: 60,
				}}
			>
				{sidebar && (
					<div
						className={
							isMediumScreen
								? `badhous-mobile-menu-container ${menuOpen ? 'active' : ''}`
								: ''
						}
					>
						<NavSidebar type={sidebar} />
					</div>
				)}
				<div
					className={`relative w-full max-w-[1280px] ${
						centered ? 'mx-auto' : ''
					}`}
				>
					{title && (
						<div className="flex relative">
							<div>
								<Typography
									variant="h5"
									className="font-black whitespace-nowrap mt-1"
									sx={{ fontSize: isMediumScreen ? 22 : 24 }}
								>
									{title}
								</Typography>
							</div>
							{isXMediumScreen && (appendToTitle || menuRight) && (
								<Button
									variant="outlined"
									onClick={() => setMenuActive(!menuActive)}
									endIcon={!menuActive && <KeyboardArrowDown />}
									className="mr-0 ml-auto"
								>
									{menuActive ? 'Close' : 'Actions'}
								</Button>
							)}
							<div
								className={`sm:flex sm:flex-wrap sm:justify-between w-full ${
									isXMediumScreen
										? `badhous-mobile-menu-actions ${
												menuActive ? 'active' : ''
										  }`
										: ''
								}`}
							>
								{appendToTitle && (
									<div className="mb-4 sm:mb-0 sm:ml-4">{appendToTitle}</div>
								)}
								{menuRight ? menuRight : null}
							</div>
						</div>
					)}
					<div className="z-[12]">{children}</div>
				</div>
			</div>
		</Box>
	)
}
