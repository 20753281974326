import React from 'react'
import { ResizableContainer } from 'components'
import { Typography, useTheme } from '@mui/material'
import { DropResult } from 'react-beautiful-dnd'
import { getUser, setUser } from 'utils'
import { LeadConversionIndexType, LeadConversionType } from 'types'
import { useDialog, useLeads } from 'providers'
import { handleMoveDirection, updateLead } from './helpers'
import { PipelineInner } from './pipeline-inner'
import { AssistantMenu, RequestMeeting } from './views'

interface Props {
	leads: LeadConversionType
	enabledTabs: boolean[]
}

export const LeadConversionPipeline = ({ leads, enabledTabs }: Props) => {
	const theme = useTheme()
	const profile = getUser()
	const { canceled, setDialogOpen } = useDialog()
	const { setLead, setLeads } = useLeads()

	const [resizeHeight, setResizeHeight] = React.useState(
		window.innerHeight * 0.35
	)
	const [isMounted, setIsMounted] = React.useState(false)
	const [isForwardMove, setIsForwardMove] = React.useState(false)
	const [pipeline, setPipeline] = React.useState<LeadConversionType>(leads)
	const [oldPipeline, setOldPipeline] =
		React.useState<LeadConversionType>(leads)

	const reorderItems = (
		sourceList: LeadConversionIndexType,
		destinationList: LeadConversionIndexType,
		startIndex: number,
		endIndex: number
	): LeadConversionType => {
		if (sourceList === destinationList) {
			const result = Array.from(pipeline[sourceList])
			const [removed] = result.splice(startIndex, 1)

			result.splice(endIndex, 0, removed)
			pipeline[sourceList] = result

			return pipeline
		}

		const resultA = Array.from(pipeline[sourceList])
		const resultB = Array.from(pipeline[destinationList])
		const [removed] = resultA.splice(startIndex, 1)

		pipeline[sourceList] = resultA

		resultB.splice(endIndex, 0, removed)
		pipeline[destinationList] = resultB

		return pipeline
	}

	const handleOnDragEnd = async (result: DropResult) => {
		if (!result.destination) {
			return
		}

		const source = result.source.droppableId as LeadConversionIndexType
		const destination = result.destination
			.droppableId as LeadConversionIndexType

		const items = reorderItems(
			source,
			destination,
			result.source.index,
			result.destination.index
		)

		const leadData = items[destination][result.destination.index]
		const leadName = leadData.name.split(' ')[0]

		if (source !== destination) {
			switch (destination) {
				case 'prospects':
					leadData.schedulingAttempt = new Date()
					break
				case 'waiting':
					leadData.schedulingAttempt = new Date()
					leadData.followUpAttempt = new Date()
					break
			}
			const notScheduled =
				destination === 'scheduled' && !leadData.meeting.scheduled
			if (notScheduled) {
				setLead(leadData)
				reorderItems(
					destination,
					source,
					result.destination.index,
					result.source.index
				)
				setIsForwardMove(handleMoveDirection(source, destination))
				setDialogOpen({
					title: leadName,
					subtitle: leadData.email,
					subtitleTransform: false,
					content: <RequestMeeting />,
					showClose: true,
					maxWidth: 'sm',
					fullWidth: true,
				})
				return
			}
		}

		items[destination][result.destination.index].label = destination

		await updateLead(leadData.uuid, [
			{
				key: 'label',
				value: destination,
			},
		])

		setUser({
			...profile,
			leads: items,
		})
		setLeads({ ...items })
	}

	React.useEffect(() => {
		if (canceled && isForwardMove) {
			setPipeline({ ...oldPipeline })
			setUser({
				...profile,
				leads: oldPipeline,
			})
			setIsForwardMove(false)
		}
	}, [canceled, isForwardMove])

	React.useEffect(() => {
		setPipeline(leads)
		setOldPipeline(leads)
	}, [leads])

	React.useEffect(() => {
		if (!isMounted && enabledTabs.indexOf(true) >= 0) {
			setDialogOpen({
				title: 'Pinktree AI Assistant',
				colorDot: theme.palette.secondary.main,
				content: <AssistantMenu />,
				showClose: true,
			})
			setIsMounted(true)
		}
	}, [leads, pipeline])

	return (
		<div className="max-w-[700px] mx-auto md:max-w-full md:mx-0">
			<Typography className="mt-2 font-semibold">
				I'll assist you in managing your leads, meetings, and potential
				contracts. <span className="font-black">You can click and drag</span> to
				move leads between stages.
			</Typography>
			<ResizableContainer
				initialHeight={resizeHeight}
				onResize={({ height }) => setResizeHeight(height)}
			>
				<div className="mt-5 md:grid grid-cols-3 gap-5">
					<PipelineInner
						timezone={profile.settings.availability.timezone.replace(/ /g, '_')}
						pipeline={pipeline}
						droppableMaxHeight={resizeHeight - 58}
						onDragEnd={handleOnDragEnd}
					/>
				</div>
			</ResizableContainer>
		</div>
	)
}
