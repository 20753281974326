import React from 'react'
import { BUYER_STAMP_PNG, SELLER_STAMP_PNG } from 'lib/theme'
import { BuyerOrSellerType } from 'types'
import { Avatar } from './avatar'
import { Typography } from '@mui/material'

interface Props {
	key: string | number
	leadType: BuyerOrSellerType
	name: string
	subtext?: string[]
	children: React.ReactNode
	className?: string
}
export const LeadCard = ({
	key,
	leadType,
	name,
	subtext,
	children,
	className,
}: Props) => {
	return (
		<div
			key={key}
			className={`py-8 px-5 border border-solid border-gray-200 rounded-lg shadow-lg ${className} bg-white box-border h-[378px]`}
		>
			<Avatar
				src={leadType === 'buyer' ? BUYER_STAMP_PNG : SELLER_STAMP_PNG}
				size={32}
				center
			/>
			<Typography className="mt-2 font-bold text-lg text-center">
				{name}
			</Typography>
			{subtext &&
				subtext.map(text => {
					return (
						<Typography className="font-semibold text-gray-600 mt-3 text-center">
							{text}
						</Typography>
					)
				})}
			<div className="mt-5">{children}</div>
		</div>
	)
}
