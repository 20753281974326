import React from 'react'
import { Button, Typography } from '@mui/material'

interface Props {
	choice: React.ReactNode
	onSelect: (selected: boolean) => void
	selected?: boolean
}

export const QuestionnaireButton = ({
	choice,
	onSelect,
	selected = false,
}: Props) => {
	const [choiceSelected, setChoiceSelected] = React.useState(selected)

	const handleSelect = (state: boolean) => {
		setChoiceSelected(state)
		onSelect(state)
	}

	return (
		<Button
			className={choiceSelected ? 'active' : ''}
			variant="questionnaire"
			onClick={() => handleSelect(!choiceSelected)}
		>
			<Typography>{choice}</Typography>
		</Button>
	)
}
