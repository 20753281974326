import React from 'react'
import { QuestionnaireButton } from 'components/atoms'

export const ClientQuestionnaireView = [
	() => (
		<div className="bg-white absolute inset-0">
			<QuestionnaireButton
				choice="House"
				onSelect={() => console.log('selected')}
			/>
			<QuestionnaireButton
				choice="Condo"
				onSelect={() => console.log('selected')}
			/>
			<QuestionnaireButton
				choice="Townhome"
				onSelect={() => console.log('selected')}
			/>
			<QuestionnaireButton
				choice="Apartment"
				onSelect={() => console.log('selected')}
			/>
		</div>
	),
	// <div className="bg-white absolute inset-0">
	// 	<QuestionnaireButton
	// 		choice="House"
	// 		onSelect={() => console.log('selected')}
	// 	/>
	// 	<QuestionnaireButton
	// 		choice="Condo"
	// 		onSelect={() => console.log('selected')}
	// 	/>
	// 	<QuestionnaireButton
	// 		choice="Townhome"
	// 		onSelect={() => console.log('selected')}
	// 	/>
	// 	<QuestionnaireButton
	// 		choice="Apartment"
	// 		onSelect={() => console.log('selected')}
	// 	/>
	// </div>,
	// <div className="bg-white absolute inset-0">
	// 	<QuestionnaireButton
	// 		choice="House"
	// 		onSelect={() => console.log('selected')}
	// 	/>
	// 	<QuestionnaireButton
	// 		choice="Condo"
	// 		onSelect={() => console.log('selected')}
	// 	/>
	// 	<QuestionnaireButton
	// 		choice="Townhome"
	// 		onSelect={() => console.log('selected')}
	// 	/>
	// 	<QuestionnaireButton
	// 		choice="Apartment"
	// 		onSelect={() => console.log('selected')}
	// 	/>
	// </div>,
	// <div className="bg-white absolute inset-0">
	// 	<QuestionnaireButton
	// 		choice="House"
	// 		onSelect={() => console.log('selected')}
	// 	/>
	// 	<QuestionnaireButton
	// 		choice="Condo"
	// 		onSelect={() => console.log('selected')}
	// 	/>
	// 	<QuestionnaireButton
	// 		choice="Townhome"
	// 		onSelect={() => console.log('selected')}
	// 	/>
	// 	<QuestionnaireButton
	// 		choice="Apartment"
	// 		onSelect={() => console.log('selected')}
	// 	/>
	// </div>,
]
