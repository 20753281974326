import React from 'react'
import { useDialog, useLeads } from 'providers'
import { TabSet } from 'components'
import { PastMeetings } from './past-meetings'
import { ColdProspects } from './cold-prospects'
import { getEnabledAssistantTabs } from '../helpers'
import { useSnackbar } from 'notistack'
import { HotContracts } from './hot-contracts'

export const AssistantMenu = () => {
	const { leads, getLeads } = useLeads()
	const { enqueueSnackbar } = useSnackbar()
	const { setDialogClosed } = useDialog()
	const enabledTabs = getEnabledAssistantTabs(leads)
	const [tabIndex, setTabIndex] = React.useState(0)

	let firstActiveTab: number | undefined = enabledTabs.indexOf(true)
	if (firstActiveTab < 0) {
		firstActiveTab = undefined
	}

	React.useEffect(() => {
		if (firstActiveTab === undefined) {
			setDialogClosed()
			enqueueSnackbar('All Done! Thanks', { variant: 'success' })
		}

		console.log('loding')

		return () => {
			getLeads()
		}
	}, [])

	return (
		<>
			<div
				className={`badhous-assistant-menu badhous-dialog-content-body sm:h-[50vh] xmd:min-w-[700px] badhous-assistant-tab-${tabIndex}`}
			>
				<TabSet
					tabIndex={firstActiveTab}
					tabTitles={['Prospects', 'Meetings', 'Contracts Waiting']}
					disabled={enabledTabs}
					context="lead-conversion"
					onTabChanged={activeTab => setTabIndex(activeTab)}
				>
					<div className="badhous-assistant-lead-container m_xmd:py-7 p-5">
						<ColdProspects />
					</div>
					<div className="badhous-assistant-lead-container m_xmd:py-7 p-5">
						<PastMeetings />
					</div>
					<div className="badhous-assistant-lead-container m_xmd:py-7 p-5">
						<HotContracts />
					</div>
				</TabSet>
			</div>
		</>
	)
}
