import React, { FC } from 'react'
import { Button } from '.'
import { useTheme } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import { MenuButtonActiveStyle, MenuButtonSlimActiveStyle } from 'lib/theme'

interface Props {
	active: boolean
	slim?: boolean
	secondary?: boolean
	checked?: boolean
	onClick?: () => void
	children: React.ReactNode
	endIcon?: React.ReactNode
}

export const MenuButton: FC<Props> = ({
	active,
	slim,
	secondary,
	checked = false,
	onClick,
	children,
	endIcon,
}) => {
	const theme = useTheme()
	const sx = !active
		? {
				paddingLeft: secondary ? 3.5 : 2.5,
		  }
		: slim
		? {
				paddingLeft: 0,
				...MenuButtonSlimActiveStyle,
		  }
		: {
				paddingLeft: secondary ? 3.5 : 2.5,
				...MenuButtonActiveStyle,
		  }

	return (
		<Button
			fullWidth
			variant={slim ? 'sidebarSlim' : 'sidebar'}
			onClick={onClick}
			sx={sx}
			endIcon={endIcon}
		>
			{checked && (
				<CheckCircle
					sx={{ fill: active ? theme.palette.secondary.main : 'initial' }}
				/>
			)}
			{children}
		</Button>
	)
}
