import { getLocalStorageItem, setLocalStorageItem } from './'

export const LOCAL_STORAGE_KEY_VIEWER_TIMEZONE = '__viewer_timezone_preference'

export function setTimezone(timezone: string) {
	setLocalStorageItem(LOCAL_STORAGE_KEY_VIEWER_TIMEZONE, timezone)
}

export function getTimezone(): string | null {
	return getLocalStorageItem(LOCAL_STORAGE_KEY_VIEWER_TIMEZONE)
}
