import React from 'react'
import {
	Box,
	InputAdornment,
	TextField as MaterialTextField,
	Typography,
} from '@mui/material'
import { IconButton } from 'components/molecules'
import { Delete } from '@mui/icons-material'
import { useSaveChanges } from 'providers'
import { FAQBlockType } from 'types'
import { TextArea } from 'components'

interface Props {
	faqIndex: number
	faqBlock: FAQBlockType
	onChange: (faqBlock: FAQBlockType) => void
	onDelete: (faqIndex: number) => void
	hideDelete?: boolean
}

export const FAQBlock = ({
	faqIndex,
	faqBlock,
	onChange,
	onDelete,
	hideDelete,
}: Props) => {
	const { unsavedChanges, setUnsavedChanges } = useSaveChanges()

	const adornmentIconClass =
		'flex items-center justify-center rounded-sm  h-8 w-10 mr-3'
	const adornmentIconTextClass = 'font-bold text-white'

	const handleChange = (value: string, key: string) => {
		onChange({
			...faqBlock,
			[key]: value,
		})

		if (!unsavedChanges) {
			setUnsavedChanges()
		}
	}

	return (
		<Box className="flex items-start mb-6">
			<Typography className="font-black mr-4 mt-4 text-gray-600 w-5">
				{faqIndex + 1}
			</Typography>
			<Box className="w-full">
				<MaterialTextField
					placeholder="Type or paste the question here"
					onChange={e => handleChange(e.target.value, 'question')}
					defaultValue={faqBlock.question}
					variant="outlined"
					InputLabelProps={{
						style: { fontWeight: 600 },
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment
								position="start"
								className={`${adornmentIconClass} bg-[#365584]`}
							>
								<span
									className={`${adornmentIconTextClass} relative top-[-1px]`}
								>
									Q
								</span>
							</InputAdornment>
						),
					}}
					fullWidth
				/>
				<Box className="pl-4 badhous-faq-block-answer">
					<TextArea
						id={`faq-textarea-${faqIndex}`}
						placeholder="Add the answer here"
						value={faqBlock.answer}
						onChange={e => handleChange(e.target.value, 'answer')}
					/>
				</Box>
			</Box>
			<Box className="ml-2 mt-2">
				<IconButton
					tooltip="Delete FAQ"
					onClick={() => onDelete(faqIndex)}
					noBorder
					className={
						hideDelete ? 'invisible opacity-0 pointer-events-none' : ''
					}
				>
					<Delete />
				</IconButton>
			</Box>
		</Box>
	)
}
