import React from 'react'
import { ClientListingType } from 'types'
import { Button, Avatar } from 'components'
import { Typography } from '@mui/material'
import { ActiveListings } from 'views'

interface Props {
	route: string
	listings: ClientListingType[]
	photo: string
}

export const AgentWebsiteListings = ({ route, listings, photo }: Props) => {
	return (
		<div className="mt-16 py-[48px] md:py-[64px] badhous-public-website-hero">
			<div className="max-w-[1350px] mx-auto px-4 md:px-10">
				<div className="md:flex m_md:justify-center mb-20 m_md:mx-auto max-w-[80%]">
					<div className="m_md:hidden">
						<Avatar size={120} src={photo} />
					</div>
					<div className="md:ml-8">
						<Typography className="max-w-[475px] font-black text-[28px] leading-[30px] mb-3">
							Homes for Sale
						</Typography>
						<Typography className="max-w-[475px] text-grey-600 text-[18px] mb-4">
							Please schedule a call with me if you'd like to tour the available
							properties, get more info, or sell your home!
						</Typography>
						<Button variant="marketing">Schedule a Call</Button>
					</div>
				</div>
				<ActiveListings route={route} listings={listings} />
			</div>
		</div>
	)
}
