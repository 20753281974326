import React, { FC } from 'react'
import {
	Accordion as MuiAccordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	AccordionProps,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

interface Props extends AccordionProps {
	title: string
	borderColor?: string
	expanded?: boolean
}

export const Accordion: FC<Props> = ({
	title,
	borderColor,
	children,
	expanded: initialExpanded,
	...rest
}) => {
	const [expanded, setExpanded] = React.useState(initialExpanded)

	return (
		<MuiAccordion
			expanded={expanded}
			className={`border border-solid w-full`}
			style={{
				borderColor: borderColor || '#e5e7eb',
			}}
			{...rest}
		>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				onClick={() => setExpanded(!expanded)}
			>
				<Typography className="font-bold">{title}</Typography>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</MuiAccordion>
	)
}
