import React from 'react'
import { Article, YouTube } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { Button } from '../atoms'
import { DeleteS3File, GetPresignedPutUrlForS3 } from 'services'
import { EventType, UploadValueType, UploadCompleteType } from 'types'
import { useSnackbar } from 'notistack'
import { useDialog } from 'providers'
import { getUser } from 'utils'

interface Props {
	addText: string
	context: string
	fileTypes: string
	onUploadComplete: (props: UploadCompleteType) => void
	value: UploadValueType
	maxSize?: number
	willDisplay?: boolean
	video?: boolean
	className?: string
}

export const UploadButton = ({
	addText,
	context,
	fileTypes,
	onUploadComplete,
	maxSize,
	willDisplay,
	video,
	value,
	className,
}: Props) => {
	const profile = getUser()
	const [file, setFile] = React.useState<File>()
	const [loading, setLoading] = React.useState(false)
	const [uploadComplete, setUploadComplete] = React.useState('')
	const { enqueueSnackbar } = useSnackbar()
	const { setDialogOpen } = useDialog()

	const handleView = () => {
		if (video) {
			setDialogOpen({
				title: context,
				showClose: true,
				video: value?.publicUrl,
			})
		} else {
			window.open(value?.publicUrl, '_blank')
		}
	}

	const handleFileChange = (event: EventType<HTMLInputElement>) => {
		if (event.target.files) {
			if (maxSize && event.target.files[0].size > maxSize * 1024 * 1024) {
				enqueueSnackbar('File is too large, please use a smaller file.', {
					variant: 'error',
				})
				setFile(undefined)
				return
			}
			setFile(event.target.files[0])
		}
	}

	const handleDeleteFile = async () => {
		const { status } = await DeleteS3File({
			key: value?.key,
		})
		if (status === 'success') {
			return true
		}
		return false
	}

	const handleUploadFile = async () => {
		if (file) {
			setLoading(true)
			const { uuid, email } = profile.user
			const filename = `${uuid}-${email}/${context}-${Date.now()}-${file.name}`
			const { uploadUrl, publicUrl, label, key } =
				await GetPresignedPutUrlForS3({
					filename,
					label: file.name,
				})

			try {
				if (uploadUrl) {
					const S3UploadResponse = await fetch(uploadUrl, {
						method: 'PUT',
						body: file,
					})

					if (S3UploadResponse?.status === 200) {
						onUploadComplete({
							filename,
							label,
							publicUrl,
							key,
							context,
						})
						setUploadComplete(file.type)
						setFile(undefined)
					} else {
						setFile(undefined)
						enqueueSnackbar('Please reselect the file and try again', {
							variant: 'error',
						})
					}
				} else {
					setFile(undefined)
					enqueueSnackbar('Please reselect the file and try again', {
						variant: 'error',
					})
				}
				setLoading(false)
			} catch (e) {
				console.log(e)
				setFile(undefined)
				enqueueSnackbar('Something went wrong. Try again.', {
					variant: 'error',
				})
				setLoading(false)
			}
		}
	}

	const fileInput = (
		<input
			type="file"
			id="badhous-upload"
			accept={fileTypes}
			className="absolute opacity-0 inset-0 z-[99] cursor-pointer"
			onChange={handleFileChange}
			value={''}
		/>
	)

	React.useEffect(() => {
		if (file) {
			if (value?.publicUrl) {
				if (value?.filename !== 'badhous-tips-to-selling-faster.pdf') {
					if (Boolean(handleDeleteFile())) {
						handleUploadFile()
					}
				} else {
					handleUploadFile()
				}
			} else {
				handleUploadFile()
			}
		}
	}, [value?.publicUrl, file])

	return (
		<div className={className}>
			{uploadComplete || value?.publicUrl ? (
				<>
					{willDisplay ? (
						<Box className="flex flex-col items-center">
							<Button
								variant="outlined"
								size="small"
								loading={loading}
								sx={{ backgroundColor: loading ? '#84909b' : 'transparent' }}
							>
								{fileInput}
								Change
							</Button>
						</Box>
					) : (
						<Box className="badhous-upload-el w-full max-w-[480px] box-border">
							<div className="flex justify-between items-center">
								<div>
									<Button
										variant="contained"
										onClick={handleView}
										size="small"
										className="mr-2"
									>
										View
									</Button>
									<Button
										variant="outlined"
										size="small"
										loading={loading}
										sx={{
											backgroundColor: loading ? '#84909b' : 'transparent',
										}}
									>
										{fileInput}
										Change
									</Button>
								</div>
								{video ? (
									<YouTube className="mr-2" sx={{ fontSize: 34 }} />
								) : (
									<Article className="mr-2" sx={{ fontSize: 32 }} />
								)}
							</div>
						</Box>
					)}

					{!willDisplay && (
						<Typography className="text-center mt-2 text-gray-600">
							{value?.label}
						</Typography>
					)}
				</>
			) : (
				<Box className="flex flex-col items-center">
					<Button
						className="px-8"
						variant="outlined"
						onClick={handleUploadFile}
						loading={loading}
						sx={{ backgroundColor: loading ? '#84909b' : 'transparent' }}
					>
						{!file && fileInput}
						<span className="cursor-pointer font-bold">{addText}</span>
					</Button>
					{maxSize && !file && (
						<Typography variant="body2" className="mt-2 text-gray-600">
							maximum file size {maxSize}mb
						</Typography>
					)}
				</Box>
			)}
		</div>
	)
}
