import React from 'react'
import { IconButton } from 'components'
import { Typography, styled, lighten } from '@mui/material'
import { ArrowRight, ArrowLeft } from '@mui/icons-material'
import { default as ReactDatePicker } from 'react-datepicker'
import { startOfDay, isSameMonth, format } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'
import { AvailabilityDateType, AvailabilityType } from 'types'

interface Props {
	availability: AvailabilityType
	onDateChange: (date: Date) => void
	selectedDate: Date
	openToDate?: Date
	excludedDates?: Date[]
	loading?: boolean
}

const DatePickerStyles = styled('div')(({ theme }) => ({
	'& .react-datepicker': {
		border: 'none',
		background: 'transparent',
		width: '100%',
		'& .react-datepicker__week': {
			display: 'flex',
			justifyContent: 'space-around',
		},
		'& .react-datepicker__month-container': {
			width: '100%',
		},
		'& .react-datepicker__month': {
			margin: 0,
		},
		'& .react-datepicker__header': {
			background: 'transparent',
			borderBottom: 'none',
			paddingTop: 0,
		},
		'& .react-datepicker__day': {
			fontSize: theme.typography.subtitle1.fontSize,
			position: 'relative',
			width: 38,
			height: 38,
			borderRadius: '50%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			paddingRight: 1,
			paddingBottom: 1,
			transitionDuration: '150ms',
			transitionTimingFunction: 'ease-in-out',
			transitionProperty: 'background, color',
			background: lighten(theme.palette.calendar.main, 0.96),
			color: theme.palette.calendar.main,
			fontWeight: theme.typography.fontWeightBold,
			'&:hover': {
				borderRadius: '50%',
				background: lighten(theme.palette.calendar.main, 0.85),
			},
			'&:focus': {
				outline: 'none',
			},
			'&::after': {
				background: theme.palette.calendar.main,
			},
			[theme.breakpoints.up('sm')]: {
				width: 44,
				height: 44,
			},
		},
		'& .react-datepicker__day--selected': {
			color: theme.palette.common.white,
			background: theme.palette.calendar.main,
			fontWeight: theme.typography.fontWeightBold,
			pointerEvents: 'none',
			'&:hover': {
				background: theme.palette.calendar.main,
			},
			'&::after': {
				background: theme.palette.common.white,
			},
		},
		'& .react-datepicker__day--disabled': {
			color: theme.palette.text.disabled,
			pointerEvents: 'none',
			background: lighten(theme.palette.grey[100], 0.5),
			'&::after': {
				background: theme.palette.text.disabled,
			},
		},
		'& .react-datepicker__day--today': {
			'&::after': {
				content: `''`,
				width: 4,
				height: 4,
				borderRadius: '50%',
				position: 'absolute',
				bottom: 7,
			},
		},
		'& .react-datepicker__day-names': {
			display: 'flex',
			'& > *': {
				flex: 1,
				textTransform: 'uppercase',
				fontSize: '0.65rem',
				fontWeight: 500,
			},
		},
	},
	'.pkapp_loading': {
		'& .react-datepicker__day': {
			color: theme.palette.text.disabled,
			background: lighten(theme.palette.text.disabled, 0.9),
			fontWeight: theme.typography.fontWeightBold,
			pointerEvents: 'none',
		},
	},
	'.pkapp_hidden': {
		visibility: 'hidden',
	},
}))

export const DatePicker = ({
	availability,
	selectedDate: propsSelectedDate,
	openToDate: propsOpenToDate,
	excludedDates,
	loading,
	onDateChange,
}: Props) => {
	const selectedDate = propsSelectedDate
		? startOfDay(propsSelectedDate)
		: undefined
	const openToDate = propsOpenToDate ? startOfDay(propsOpenToDate) : undefined

	const [displayedMonth, setDisplayedMonth] = React.useState(
		openToDate || selectedDate || new Date()
	)

	const handleMonthChange = (date: Date) => {
		setDisplayedMonth(date)
	}

	const filterDates = (date: Date) => {
		let day = date.getDay() - 1
		if (day < 0) {
			day = 6
		}
		return availability.data[day].checked
	}

	return (
		<DatePickerStyles>
			<ReactDatePicker
				inline
				data-loading={loading}
				excludeDates={excludedDates}
				disabledKeyboardNavigation
				selected={selectedDate}
				openToDate={openToDate}
				onChange={onDateChange}
				onMonthChange={handleMonthChange}
				minDate={new Date()}
				filterDate={filterDates}
				calendarClassName={loading ? 'pkapp_loading' : ''}
				formatWeekDay={nameOfDay => nameOfDay.slice(0, 3)}
				weekDayClassName={() => 'font-bold'}
				renderCustomHeader={innerProps => (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginLeft: '0.166rem',
						}}
					>
						<Typography className="font-bold">
							{format(innerProps.date, 'MMMM yyyy')}
						</Typography>
						<div>
							<IconButton
								tooltip="Previous Month"
								noBorder
								onClick={innerProps.decreaseMonth}
								size="small"
								disabled={innerProps.prevMonthButtonDisabled}
							>
								<ArrowLeft sx={{ fontSize: 30 }} />
							</IconButton>
							<IconButton
								tooltip="Next Month"
								noBorder
								onClick={innerProps.increaseMonth}
								size="small"
								disabled={innerProps.nextMonthButtonDisabled}
							>
								<ArrowRight sx={{ fontSize: 30 }} />
							</IconButton>
						</div>
					</div>
				)}
				dayClassName={date => {
					const hidden = !isSameMonth(date, displayedMonth)
					return hidden ? 'pkapp_hidden' : ''
				}}
			/>
		</DatePickerStyles>
	)
}
