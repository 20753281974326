import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'

interface Props {
	menuOpen: boolean
	publicMenuOpen: boolean
	setMenuOpen: (open: boolean) => void
	setPublicMenuOpen: (open: boolean) => void
}

export const MobileMenuContext = React.createContext<Props>({
	menuOpen: false,
	publicMenuOpen: false,
	setMenuOpen: () => {},
	setPublicMenuOpen: () => {},
})

export const MobileMenuProvider: FC<{ children: React.ReactNode }> = ({
	children,
}): JSX.Element => {
	const location = useLocation()
	const [open, setOpen] = React.useState(false)
	const [publicOpen, setPublicOpen] = React.useState(false)

	const handleMenuToggle = (open: boolean) => {
		setOpen(open)
	}

	const handlePublicMenuToggle = (open: boolean) => {
		setPublicOpen(open)
	}

	React.useEffect(() => {
		window.scroll(0, 0)
		if (publicOpen || open) {
			setOpen(false)
			setPublicOpen(false)
		}
	}, [location])

	return (
		<MobileMenuContext.Provider
			value={{
				menuOpen: open,
				publicMenuOpen: publicOpen,
				setMenuOpen: handleMenuToggle,
				setPublicMenuOpen: handlePublicMenuToggle,
			}}
		>
			{children}
		</MobileMenuContext.Provider>
	)
}
