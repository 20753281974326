import * as React from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

export const useBlocker = (blocker: any, when = true) => {
	const { navigator } = React.useContext(NavigationContext)

	React.useEffect(() => {
		if (!when) return

		const unblock = (navigator as any).block((tx: any) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					unblock()
					tx.retry()
				},
			}

			blocker(autoUnblockingTx)
		})

		return unblock
	}, [navigator, blocker, when])
}

export const useSaveBeforeExit = (
	when: boolean = true,
	onConfirm?: () => void,
	onCancel?: () => void
) => {
	const message =
		'Should we save your changes?\n • Press OK to save your changes.\n • Press CANCEL to leave without saving your changes.'

	const blocker = React.useCallback(
		(tx: any) => {
			if (window.confirm(message)) {
				if (onConfirm) {
					onConfirm()
				}
			} else {
				if (onCancel) {
					onCancel()
				}
				tx.retry()
			}
		},
		[message]
	)

	useBlocker(blocker, when)
}
