import React from 'react'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@mui/material'
import { BADHOUS_ICON, theme } from './lib/theme'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import {
	SignIn,
	SignUp,
	ForgotPassword,
	ResetPassword,
	Dashboard,
	PublicWebsite,
	Website,
	Settings,
	Components,
	LeadConversion,
	ActiveClients,
	Closed,
	ClientPortal,
	WaitingRoom,
	People,
} from './pages'
import {
	AssistantProvider,
	AvailabilityProvider,
	DialogProvider,
	FAQProvider,
	LeadDialogProvider,
	LeadsProvider,
	MobileMenuProvider,
	QuestionnaireProvider,
	RecentListingsProvider,
	SaveChangesProvider,
} from 'providers'
import { StyledEngineProvider } from '@mui/material/styles'

import '@fontsource/mulish/500.css'
import '@fontsource/mulish/600.css'
import '@fontsource/mulish/700.css'
import '@fontsource/mulish/900.css'

import { getUser } from 'utils'
import { BadHous } from 'components'

export const App = () => {
	const profile = getUser()
	const [loading, setLoading] = React.useState(true)

	let msg = '%c Hi 👋!Welcome to my site!'

	let styles = [
		'font-size: 12px',
		'font-family: monospace',
		'background: white',
		'display: inline-block',
		'color: black',
		'padding: 8px 19px',
		'border: 1px dashed;',
	].join(';')

	console.log(msg, styles)

	React.useLayoutEffect(() => {
		if (
			process.env.REACT_APP_ENVIRONMENT === 'production' &&
			window.location.protocol !== 'https:'
		) {
			const securePage = window.location.href.replace('http:', 'https:')
			window.location.replace(securePage)
		}

		if (window.location.pathname === '/') {
			if (profile?.user?.type) {
				window.location.replace('/dashboard')
			} else {
				window.location.replace('/sign-in')
			}
		}

		setTimeout(() => {
			setLoading(false)
		}, 3000)
	}, [])

	if (
		process.env.REACT_APP_ENVIRONMENT === 'production' &&
		window.location.protocol !== 'https:'
	) {
		return null
	}

	return (
		<BrowserRouter>
			<SnackbarProvider
				autoHideDuration={3000}
				maxSnack={1}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<LeadsProvider>
							<DialogProvider>
								<LeadDialogProvider>
									<AvailabilityProvider>
										<FAQProvider>
											<MobileMenuProvider>
												<QuestionnaireProvider>
													<RecentListingsProvider>
														<SaveChangesProvider>
															<div className="flex">
																<div
																	className={`badhous-page-loading ${
																		loading ? 'active' : ''
																	}`}
																>
																	<div className="badhous-pulse">{BadHous}</div>
																</div>
																<div style={{ width: '100%' }}>
																	<Routes>
																		<Route
																			path="/sign-in"
																			element={<SignIn />}
																		/>
																		<Route
																			path="/sign-up"
																			element={<SignUp />}
																		/>
																		<Route
																			path="/forgot-password"
																			element={<ForgotPassword />}
																		/>
																		<Route
																			path="/reset-password"
																			element={<ResetPassword />}
																		/>
																		<Route
																			path="/dashboard"
																			element={<Dashboard />}
																		/>
																		<Route
																			path="/important-stuff/:slug"
																			element={<Settings />}
																		/>
																		<Route
																			path="/website"
																			element={<Website />}
																		/>
																		<Route
																			path="/people"
																			element={<People />}
																		/>
																		<Route
																			path="/lead-conversion"
																			element={<LeadConversion />}
																		/>
																		<Route
																			path="/active-clients"
																			element={<ActiveClients />}
																		/>
																		<Route
																			path="/closed"
																			element={<Closed />}
																		/>
																		<Route
																			path="/components"
																			element={<Components />}
																		/>
																		<Route
																			path="/u/:hash"
																			element={<PublicWebsite />}
																		/>
																		<Route
																			path="/u/:hash/:route"
																			element={<PublicWebsite />}
																		/>
																		<Route
																			path="/u/:hash/:route/:client/:index"
																			element={<PublicWebsite />}
																		/>
																		<Route
																			path="/u/:hash/:route/listings"
																			element={<PublicWebsite />}
																		/>
																		<Route
																			path="/client/:uuid"
																			element={<ClientPortal />}
																		/>
																		<Route
																			path="/welcome/:slug"
																			element={<WaitingRoom />}
																		/>
																	</Routes>
																</div>
															</div>
														</SaveChangesProvider>
													</RecentListingsProvider>
												</QuestionnaireProvider>
											</MobileMenuProvider>
										</FAQProvider>
									</AvailabilityProvider>
								</LeadDialogProvider>
							</DialogProvider>
						</LeadsProvider>
					</ThemeProvider>
				</StyledEngineProvider>
			</SnackbarProvider>
		</BrowserRouter>
	)
}
