export const isListingProfileComplete = (profile: any) => {
	const requirements = profile.settings.listingProfile.completed
	delete requirements.social

	if (
		Object.keys(requirements).every(
			requirement => requirements[requirement] === true
		) &&
		profile.settings.onboarding.steps[1].complete === false
	) {
		return true
	}

	return false
}
