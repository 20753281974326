import { ForceGenericObjectType } from 'types'
import { getLocalStorageItem, setLocalStorageItem } from '../'

export const LOCAL_STORAGE_KEY_PUBLIC_VIEWER = '__public_viewer'

export function setPublicUser(user: ForceGenericObjectType | null) {
	setLocalStorageItem(LOCAL_STORAGE_KEY_PUBLIC_VIEWER, user)
}

export function getPublicUser(): any | null {
	return getLocalStorageItem(LOCAL_STORAGE_KEY_PUBLIC_VIEWER)
}
