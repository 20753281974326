import React from 'react'
import { Typography } from '@mui/material'
import { addMinutes, format, isPast } from 'date-fns'
import {
	AgentLeadActionEnum,
	LeadConversionBlockType,
	LeadConversionType,
} from 'types'
import { Button, LeadCard, PopoverButton } from 'components'
import { formatInTimeZone } from 'date-fns-tz'
import { getUser } from 'utils'
import { useLeads } from 'providers'
import { useSnackbar } from 'notistack'
import { sendMeetingLink, updateLead } from '../helpers'
import { EMOJI_STUD } from 'lib/theme'

export const PastMeetings = () => {
	const profile = getUser()
	const { leads, setLeads } = useLeads()
	const { enqueueSnackbar } = useSnackbar()

	const [meetings, setMeetings] = React.useState<LeadConversionBlockType[]>([])

	const handleLeadAction = (
		action: AgentLeadActionEnum,
		meetingIndex: number
	) => {
		const newLeads = { ...leads }
		const lead = meetings[meetingIndex]

		switch (action) {
			case AgentLeadActionEnum.MOVE_FORWARD:
				setToContractWaiting(lead, newLeads)
				break
			case AgentLeadActionEnum.MEET_AGAIN:
				setMeetAgain(lead, newLeads)
				break
			case AgentLeadActionEnum.ARCHIVE:
				archiveLead(lead, newLeads)
				break
		}
	}

	const setToContractWaiting = async (
		lead: LeadConversionBlockType,
		newLeads: LeadConversionType
	) => {
		lead.meeting.scheduled = false
		lead.followUpAttempt = new Date()
		newLeads.waiting.push({
			...lead,
		})
		newLeads.scheduled.splice(lead.index, 1)

		await updateLead(lead.uuid, [
			{
				key: 'label',
				value: 'waiting',
			},
			{
				key: 'meeting',
				value: lead.meeting,
			},
			{
				key: 'schedulingAttempt',
				value: new Date(),
			},
			{
				key: 'followUpAttempt',
				value: new Date(),
			},
		])

		setLeads(newLeads)
		enqueueSnackbar(`${lead.name.split(' ')[0]} Moved to Contract Waiting`, {
			variant: 'success',
		})
	}

	const setMeetAgain = async (
		lead: LeadConversionBlockType,
		newLeads: LeadConversionType
	) => {
		lead.schedulingAttempt = new Date()
		lead.meeting.scheduled = false
		newLeads.prospects.push({
			...lead,
		})
		newLeads.scheduled.splice(lead.index, 1)

		await sendMeetingLink(lead, [
			{
				key: 'label',
				value: 'prospects',
			},
			{
				key: 'meeting',
				value: lead.meeting,
			},
		])

		setLeads(newLeads)
		enqueueSnackbar(
			`Email Sent! ${lead.name.split(' ')[0]} Moved to Prospects`,
			{
				variant: 'success',
			}
		)
	}

	const archiveLead = async (
		lead: LeadConversionBlockType,
		newLeads: LeadConversionType
	) => {
		newLeads.scheduled.splice(lead.index, 1)
		newLeads.archived.push({
			...lead,
		})

		await updateLead(lead.uuid, [
			{
				key: 'archived',
				value: 1,
			},
		])

		setLeads(newLeads)
		enqueueSnackbar(`${lead.name.split(' ')[0]} Moved To Archive`, {
			variant: 'success',
		})
	}

	React.useEffect(() => {
		const meetingsArr: LeadConversionBlockType[] = []

		leads.scheduled.forEach((lead, index) => {
			if (
				isPast(
					addMinutes(
						new Date(lead.meeting.date),
						profile.settings.availability.stepInMinutes
					)
				)
			) {
				meetingsArr.push({ ...lead, index })
			}
		})

		setMeetings(meetingsArr)
	}, [leads])

	return (
		<div className="overflow-y-auto badhous-dialog-content-body">
			<Typography className="font-bold text-xl text-center">
				How did your past meetings go?
			</Typography>
			<Typography className="sm:text-lg mt-2 font-semibold text-center">
				One step closer to a contract {EMOJI_STUD}
			</Typography>
			<div className="m_xmd:mt-6 mt-4 xmd:flex items-center flex-wrap max-w-[560px] mx-auto">
				{meetings.map((lead, index) => {
					const formattedDateTime = format(
						new Date(lead.meeting.date),
						'eee, MMM do @ h:mma'
					)
					const timeZone = formatInTimeZone(
						new Date(),
						lead.meeting.timezone.replace(/ /g, '_'),
						'zzz'
					)
					return (
						<LeadCard
							key={lead.uuid}
							leadType={lead.type}
							name={lead.name}
							subtext={[`${formattedDateTime} ${timeZone}`]}
							className={
								'xmd:even:ml-2 w-[275px] max-w-[275px] mb-2 m_xmd:mx-auto'
							}
						>
							<Typography className="font-bold text-center text-lg mb-4">
								How did it go?
							</Typography>
							<Button
								onClick={() =>
									handleLeadAction(AgentLeadActionEnum.MOVE_FORWARD, index)
								}
								className="block bg-gray-200 hover:bg-[#daf6db] rounded-lg mb-2"
								fullWidth
							>
								Moving Forward!
							</Button>
							<PopoverButton
								customButton={
									<Button
										className="block bg-gray-200 hover:bg-[#dfe9ff] rounded-lg mb-2"
										fullWidth
									>
										Meet Again
									</Button>
								}
								onPromptButtonClick={() =>
									handleLeadAction(AgentLeadActionEnum.MEET_AGAIN, index)
								}
							>
								<div className="p-4 pb-2 max-w-[280px]">
									<Typography className="font-semibold">
										We'll send a scheduling link to{' '}
										<span className="font-black">{lead.name}</span>. Once they
										schedule their meeting it will appear in your scheduled
										meetings section!
									</Typography>
								</div>
							</PopoverButton>
							<PopoverButton
								customButton={
									<Button
										className="block bg-gray-200 hover:bg-gray-300 rounded-lg mb-2"
										fullWidth
									>
										Archive
									</Button>
								}
								onPromptButtonClick={() =>
									handleLeadAction(AgentLeadActionEnum.ARCHIVE, index)
								}
							>
								<div className="p-4 pb-2 max-w-[280px]">
									<Typography className="text-lg font-bold">
										Are you sure?
									</Typography>
									<Typography className="mt-2 font-semibold">
										Archiving this lead will remove it from your Meetings
										pipeline. You'll be able to find{' '}
										<span className="font-black">{lead.name}</span> in your
										archives.
									</Typography>
								</div>
							</PopoverButton>
						</LeadCard>
					)
				})}
			</div>
		</div>
	)
}
