import React from 'react'
import { useLocation } from 'react-router-dom'
import { NavRoutesType } from 'types'

export const useGetQuery = () => {
	const { search } = useLocation()

	return React.useMemo(() => new URLSearchParams(search), [search])
}

export const getRoutes = (
	type: 'agent' | 'brokerage' | 'agentSettings' | 'brokerageSettings'
): NavRoutesType => {
	const routes = {
		agent: [
			{
				name: 'Dashboard',
				path: '/dashboard',
			},
			{
				name: 'Important Stuff',
				path: '/important-stuff/onboarding',
			},
			{
				name: 'Website',
				path: '/website',
			},
			{
				name: 'Brokerage',
				path: '/brokerage',
			},
			{
				name: 'People',
				path: '/people',
			},
			{
				name: '',
				path: '',
				secondary: {
					title: 'Clients',
					routes: [
						{
							name: 'Lead Conversion',
							path: '/lead-conversion',
						},
						{
							name: 'Active Clients',
							path: '/active-clients',
						},
						{
							name: 'Closed',
							path: '/closed',
						},
					],
				},
			},
		],
		brokerage: [
			{
				name: 'Dashboard',
				path: '/dashboard',
			},
			{
				name: 'Website',
				path: '/website',
			},
			{
				name: 'Settings',
				path: '/settings',
			},
		],
		agentSettings: [
			{
				name: 'Onboarding',
				path: '/important-stuff/onboarding',
			},
			{
				name: 'My Profile',
				path: '/important-stuff/my-profile',
			},
			{
				name: 'Availability',
				path: '/important-stuff/availability',
			},
			{
				name: 'Daily Routine',
				path: '/important-stuff/daily-routine',
			},
			{
				name: 'Checklists',
				path: '/important-stuff/checklists',
			},
			{
				name: 'Account Settings',
				path: '/important-stuff/account',
			},
		],
		brokerageSettings: [
			{
				name: 'Account',
				path: '/settings/account',
			},
			{
				name: 'Brokerage',
				path: '/settings/brokerage',
			},
			{
				name: 'Billing',
				path: '/settings/billing',
			},
			{
				name: 'Onboarding',
				path: '/settings/onboarding',
			},
		],
	}

	return routes[type]
}
