import React, { FC } from 'react'
import { useDialog, useLeads } from './store'
import { useSearchParams } from 'react-router-dom'
import { LeadDialog } from 'views/agent/lead-conversion/views'
import { DropdownMenuButton } from 'components'
import { MoveDirections } from 'views/agent/lead-conversion/components/move-directions'

interface Props {
	dialogOpen: boolean
}

export const LeadDialogContext = React.createContext<Props>({
	dialogOpen: false,
})

export const LeadDialogProvider: FC<{ children: React.ReactNode }> = ({
	children,
}): JSX.Element => {
	const [searchParams, setSearchParams] = useSearchParams()
	const leadUuid = searchParams.get('lead')
	const { lead } = useLeads()
	const { canceled, dialogOpen, setDialogOpen, setDialogClosed } = useDialog()
	const [dropdownClosed, setDropdownClosed] = React.useState(false)

	React.useEffect(() => {
		if (lead && leadUuid && lead.index !== undefined && lead.index >= 0) {
			setDialogOpen({
				title: lead.name.split(' ')[0],
				subtitle: `${lead.type} Lead`,
				showClose: true,
				fullWidth: true,
				maxWidth: 'lg',
				content: (
					<LeadDialog lead={lead} onSuccess={() => console.log('something')} />
				),
				actions: (
					<DropdownMenuButton
						title="Lead Actions"
						content={
							<MoveDirections onConfirm={() => setDropdownClosed(true)} />
						}
						close={dropdownClosed}
					/>
				),
			})
		} else if (lead === undefined && dialogOpen) {
			setDialogClosed()
		}
	}, [lead, leadUuid])

	React.useEffect(() => {
		if (canceled) {
			setSearchParams({})
		}
	}, [canceled])

	return (
		<LeadDialogContext.Provider
			value={{
				dialogOpen,
			}}
		>
			{children}
		</LeadDialogContext.Provider>
	)
}
