import React from 'react'
import {
	CircularProgress,
	ListItemButton,
	Typography,
	useTheme,
} from '@mui/material'
import { LeadConversionBlockType, LeadConversionType } from 'types'
import { Card, ColorChip, DropdownMenuButton } from 'components'
import { Delete, Inventory2, Unarchive } from '@mui/icons-material'
import { useDialog, useLeads } from 'providers'
import { LeadDialog } from './views'
import { updateLead } from './helpers'
import { getUser, setUser } from 'utils'
import { useSearchParams } from 'react-router-dom'
import { MoveDirections } from './components/move-directions'
import { EMOJI_SMILE } from 'lib/theme'

interface Props {
	leads: LeadConversionType
}

export const LeadArchive = ({ leads }: Props) => {
	const { setLead } = useLeads()
	const [_, setSearchParams] = useSearchParams()
	const [loading, setLoading] = React.useState(true)
	const [archived, setArchived] = React.useState<LeadConversionBlockType[]>()

	// const handleUpdateLead = async (
	// 	update: string,
	// 	lead: LeadConversionBlockType,
	// 	leadIndex: number
	// ) => {
	// 	const newLeads = { ...leads }

	// 	switch (update) {
	// 		case 'unarchive':
	// 			newLeads[lead.label].push({
	// 				...newLeads.archived[leadIndex],
	// 			})
	// 			newLeads.archived.splice(leadIndex, 1)
	// 			await updateLead(lead.uuid, [
	// 				{
	// 					key: 'archived',
	// 					value: 0,
	// 				},
	// 			])
	// 			setUser({
	// 				...profile,
	// 				leads: newLeads,
	// 			})
	// 			setLeads({ ...newLeads })
	// 			setTimeout(() => {
	// 				setDialogClosed()
	// 			}, 400)
	// 			break
	// 		case 'delete':
	// 			break
	// 	}
	// }

	React.useEffect(() => {
		setArchived([...leads.archived])
	}, [leads])

	React.useEffect(() => {
		if (archived && loading) {
			setTimeout(() => {
				setLoading(false)
			}, 200)
		}
	}, [archived, loading])

	return (
		<Card title="Lead Archive" underline thin flatSides>
			{loading ? (
				<div className="flex items-center justify-center">
					<CircularProgress size={28} />
				</div>
			) : (
				<>
					{!archived?.length ? (
						<div className="pt-3 px-5">
							<Typography className="flex justify-center items-center font-bold text-gray-600">
								Archive Empty <span className="pl-2">{EMOJI_SMILE}</span>
							</Typography>
						</div>
					) : (
						<>
							<div className="grid grid-cols-4 mb-2 m_sm:px-4 sm:px-8">
								<div className="col-span-1">
									<Typography className="font-bold text-gray-500">
										Name
									</Typography>
								</div>
								<div className="col-span-1">
									<Typography className="font-bold text-gray-500">
										Email
									</Typography>
								</div>
								<div className="col-span-1">
									<Typography className="font-bold text-gray-500">
										Phone
									</Typography>
								</div>
								<div className="col-span-1">
									<Typography className="font-bold text-gray-500">
										Last Status
									</Typography>
								</div>
							</div>
							<div>
								{archived?.map(lead => {
									let status = 'Prospect'
									let color: 'blue' | 'green' | 'red' = 'blue'
									if (lead.label === 'scheduled') {
										status = 'Scheduled Meeting'
										color = 'green'
									} else if (lead.label === 'waiting') {
										status = 'Contract Waiting'
										color = 'red'
									}

									return (
										<div className="relative" key={lead.uuid}>
											{/* <CheckBox
												sx={{
													position: 'absolute',
													top: '21px',
													left: '32px',
													cursor: 'pointer',
													zIndex: 12,
												}}
											/> */}
											<ListItemButton
												className="grid grid-cols-4 border-b border-solid border-gray-200 m_sm:!px-4 sm:!px-8"
												onClick={() => {
													setLead(lead)
													setSearchParams({ lead: lead.uuid })
												}}
											>
												<div className="relative col-span-1 self-center">
													{/* pl-10 */}
													<Typography className="font-semibold">
														{lead.name}
													</Typography>
												</div>
												<div className="col-span-1 self-center">
													<Typography className="font-semibold">
														{lead.email}
													</Typography>
												</div>
												<div className="col-span-1 self-center">
													<Typography className="font-semibold">
														{lead.phone}
													</Typography>
												</div>
												<div className="col-span-1 self-center">
													<ColorChip label={status} color={color} />
												</div>
											</ListItemButton>
										</div>
									)
								})}
							</div>
						</>
					)}
				</>
			)}
		</Card>
	)
}
