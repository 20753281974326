import React from 'react'
import { AppHeader, ClientHeader, Page } from 'components'
import { getUser } from 'utils'
import { useNavigate } from 'react-router-dom'
import { ClientDashboard, AgentDashboard } from 'views'
import { BrokerageDashboard } from 'views/brokerage'

export const Dashboard = () => {
	const navigate = useNavigate()
	let data: any = getUser() || {}

	React.useEffect(() => {
		if (!data.user) {
			navigate('/sign-in')
		}
	}, [data, navigate])

	if (!data.user) {
		return null
	}

	let header = <AppHeader first={data.user.first} type={data.user.type} />
	let page = null
	switch (data.user.type) {
		case 'agent':
			page = <AgentDashboard clientActivity={data.clientActivity} />
			break
		case 'brokerage':
			page = <BrokerageDashboard />
			break
		case 'client':
			header = (
				<ClientHeader first={data.user.first} addresses={data.user.addresses} />
			)
			page = <ClientDashboard />
			break
	}

	return page ? (
		<Page
			header={header}
			sidebar={data.user.type}
			title={data.user.type !== 'client' ? `Welcome ${data.user.first}!` : ''}
		>
			{page}
		</Page>
	) : null
}
