import request from '../request'

export const GetPresignedPutUrlForS3 = (payload: {
	filename: string
	label: string
}) => {
	return request({
		url: '/s3/generate-presigned-put-url',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}

export const DeleteS3File = (payload: { key: string }) => {
	return request({
		url: '/s3/delete-file',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}
