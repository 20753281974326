import React from 'react'
import { Typography } from '@mui/material'
import { Warning } from '@mui/icons-material'
interface Props {
	icon: 'Warning'
	text: string
	color: string
}

export const IconTag = ({ icon: iconKey, text, color }: Props) => {
	let icon = <div>icon key required</div>
	switch (iconKey) {
		case 'Warning':
			icon = <Warning sx={{ color }} />
			break
	}

	return (
		<div
			className="flex items-center py-2 px-4 rounded-md"
			style={{ border: 'solid 1px #424242' }}
		>
			{icon}
			<Typography className="ml-2 font-semibold">{text}</Typography>
		</div>
	)
}
