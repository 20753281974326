import { PropertyCard } from 'components'
import { SAMPLE_HOME_PHOTO } from 'lib/theme'
import { useNavigate } from 'react-router-dom'
import { ClientListingType } from 'types'
import { getAddressAndDescription } from 'utils'

interface Props {
	route: string
	listings: ClientListingType[]
}

export const ActiveListings = ({ route: baseRoute, listings }: Props) => {
	const navigate = useNavigate()

	return listings ? (
		<div className="flex flex-wrap m_md:justify-center">
			{listings.map((listing, i) => {
				const { address, client, index } = listing
				const { address: formattedAddress, description: formattedDescription } =
					getAddressAndDescription(address)

				const route = `${baseRoute}/listing/${client}/${index}`

				return (
					<div key={i} className="mb-4">
						<PropertyCard
							uuid={client}
							address={formattedAddress}
							description={formattedDescription}
							imageUrl={SAMPLE_HOME_PHOTO}
							listingOnline
							onClick={() => navigate(route, { state: listing })}
						/>
					</div>
				)
			})}
		</div>
	) : null
}
