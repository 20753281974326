import React from 'react'
import { useMobileMenu } from 'providers'

interface Props {
	publicWebsite?: boolean
}
export function MobileMenuIcon({ publicWebsite }: Props) {
	const { menuOpen, setMenuOpen, setPublicMenuOpen, publicMenuOpen } =
		useMobileMenu()

	const handleToggle = () => {
		if (!publicWebsite) {
			setMenuOpen(!menuOpen)
			return
		}
		setPublicMenuOpen(!publicMenuOpen)
	}

	return (
		<div
			id="badhous-mobile-menu"
			className={publicMenuOpen || menuOpen ? 'active' : ''}
			onClick={handleToggle}
		>
			<span className="badhous-linea"></span>
			<span className="badhous-lineb"></span>
			<span className="badhous-linec"></span>
		</div>
	)
}
