import React from 'react'
import { Typography } from '@mui/material'
import { Button } from 'components'
import { sendMeetingLink } from '../helpers'
import { formatDistanceToNow } from 'date-fns'
import { useLeads, useDialog } from 'providers'

export const RequestMeeting = () => {
	const { lead, setLead } = useLeads()
	const { setDialogClosed } = useDialog()
	const [requestSent, setRequestSent] = React.useState(false)

	if (!lead) {
		return null
	}

	const name = lead.name.split(' ')[0]

	const title = !requestSent
		? `${name} hasn't confirmed a meeting time!`
		: 'Meeting Request Sent :)'

	const text = !requestSent
		? `Request a meeting with ${name} by clicking the button below.`
		: `Once they choose a time it will appear under the meetings column.`

	const action = !requestSent ? `Request A Meeting` : `Okay!`

	const handleActionClick = async () => {
		if (requestSent) {
			setDialogClosed()
			return
		}

		const result = await sendMeetingLink(lead)

		if (result.status === 'success') {
			lead.schedulingAttempt = new Date()
			setRequestSent(true)
			setLead({ ...lead })
		}
	}

	const lastAttempt = formatDistanceToNow(new Date(lead.schedulingAttempt))

	return (
		<div className="p-5 md:py-10 md:px-8 max-w-[600px] sm:min-h-[250px] w-full box-border">
			<Typography className="font-bold text-xl text-center">{title}</Typography>
			<Typography className="md:text-lg mt-2 text-center mx-auto max-w-[320px] font-semibold">
				{text}
			</Typography>
			<Button
				variant="contained"
				className="block max-w-[250px] mx-auto mt-4"
				onClick={handleActionClick}
			>
				{action}
			</Button>
			{!requestSent && (
				<>
					<Typography className="mt-7 text-center text-gray-600 mx-auto max-w-[380px] font-bold">
						Last Request Attempt:
					</Typography>
					<Typography className="mt-2 text-center text-gray-600 mx-auto max-w-[380px] font-black">
						{lastAttempt} ago
					</Typography>
				</>
			)}
		</div>
	)
}
