import React from 'react'
import { AgentSettingsType } from 'types'
import { Button, OnboardingCard, ProgressBar } from 'components'
import { Typography } from '@mui/material'
import { getUser, setUser } from 'utils'
import { AgentSetSettingsData } from 'services'
import { useSnackbar } from 'notistack'

interface Props {
	onboarding: AgentSettingsType['onboarding']
}

export const AgentOnboarding = ({ onboarding }: Props) => {
	let completedSteps = 0

	const { enqueueSnackbar } = useSnackbar()

	const handleStart = (step: number) => {
		if (step === 3) {
			handleSetRoutineStepComplete()
		}
	}

	const handleViewClientOnboarding = () => {
		console.log('go to onboarding')
	}

	const handleSetRoutineStepComplete = async () => {
		const profile = getUser()
		const newOnboarding = profile.settings.onboarding
		newOnboarding.steps[2].complete = true

		const result = await AgentSetSettingsData({
			agentUuid: profile.user.uuid,
			updates: [
				{
					key: 'onboarding',
					value: newOnboarding,
				},
			],
		})

		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			return
		}

		if (result?.status === 'success') {
			setUser({
				...profile,
				settings: {
					...profile.settings,
					onboarding: newOnboarding,
				},
			})
		}
	}

	const onboardingItems = onboarding?.steps.map((step, idx) => {
		if (step.complete) {
			completedSteps += 1
		}

		return (
			<OnboardingCard
				key={idx}
				number={idx + 1}
				complete={step.complete}
				title={step.title}
				subtitle={step.subtitle}
				path={step.path}
				onClick={() => handleStart(idx + 1)}
			/>
		)
	})

	return (
		<div className="w-full mx-auto max-w-[750px]">
			<div className="mb-10">
				<Typography variant="h5" className="font-black text-center mr-3">
					Onboarding {onboarding.complete ? 'Complete' : 'Checklist'}
				</Typography>

				<div className="mx-auto mt-2">
					{onboarding.complete ? (
						<Button
							className="block mx-auto mt-4"
							variant="contained"
							color="secondary"
							onClick={handleViewClientOnboarding}
						>
							View Client Onboarding Process
						</Button>
					) : (
						<>
							<Typography className="font-bold text-center mt-4">
								{completedSteps} out of {onboarding?.steps?.length} steps
								complete
							</Typography>
							<div className="max-w-[200px] mx-auto mt-2">
								<ProgressBar
									width={200}
									currentStep={completedSteps}
									steps={onboarding?.steps?.length}
								/>
							</div>
						</>
					)}
				</div>
			</div>
			{onboardingItems}
		</div>
	)
}
