import React, { FC } from 'react'
import { RecentListingCardType, RecentListingType } from 'types'
import { getUser } from 'utils'

const INITIAL_STATE = [
	{
		line1: '',
		line2: '',
		city: '',
		state: '',
		zip: '',
		closePrice: '',
		timeOnMarket: '',
	},
]

interface Props {
	recentListings: RecentListingType
	initialize: (recentListings: RecentListingType) => void
	setRecentListings: (
		index: number,
		recentListingCard?: RecentListingCardType
	) => void
}

export const RecentListingsContext = React.createContext<Props>({
	recentListings: INITIAL_STATE,
	initialize: () => {},
	setRecentListings: () => {},
})

export const RecentListingsProvider: FC<{ children: React.ReactNode }> = ({
	children,
}): JSX.Element => {
	const profile = getUser()
	const savedRecentListings = profile?.settings?.listingProfile?.recentListings
	const [recentListings, setRecentListings] = React.useState(
		savedRecentListings?.length ? savedRecentListings : INITIAL_STATE
	)

	const handleInitialize = (intializer: RecentListingType) => {
		setRecentListings(intializer)
	}

	const handleSetRecentListings = (
		index: number,
		recentListingCard?: RecentListingCardType
	) => {
		if (recentListingCard) {
			recentListings.splice(index, 1, recentListingCard)
		} else {
			recentListings.splice(index, 1)
		}
		setRecentListings([...recentListings])
	}

	return (
		<RecentListingsContext.Provider
			value={{
				recentListings,
				initialize: handleInitialize,
				setRecentListings: handleSetRecentListings,
			}}
		>
			{children}
		</RecentListingsContext.Provider>
	)
}
