import React from 'react'
import { Box, Typography } from '@mui/material'
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	TwitterIcon,
	TwitterShareButton,
	LinkedinIcon,
	LinkedinShareButton,
} from 'react-share'
import { CopyText } from './copy-text'

interface Props {
	url: string
}

export const SocialSharing = ({ url }: Props) => {
	return (
		<Box className="block relative z-[2]">
			<Box className="max-w-[475px] mx-auto sm:max-w-full sm:mx-0 pt-4 pb-6 px-8">
				<Typography className="mb-4 font-semibold">
					Share this on your favorite networks
				</Typography>
				<Box className="mb-4">
					<CopyText text={url} maxWidth={475} />
				</Box>
				<Box className="flex justify-between sm:justify-center flex-wrap sm:flex-nowrap">
					<FacebookShareButton
						className="mr-0 sm:mr-[6px] badhous-share-button"
						url={url}
					>
						<FacebookIcon size={48} round />
						<Typography>Share</Typography>
					</FacebookShareButton>
					<TwitterShareButton
						className="mr-0 sm:mr-[6px] badhous-share-button"
						url={url}
					>
						<TwitterIcon size={48} round />
						<Typography>Tweet</Typography>
					</TwitterShareButton>
					<LinkedinShareButton
						className="mr-0 sm:mr-[6px] badhous-share-button"
						url={url}
					>
						<LinkedinIcon size={48} round />
						<Typography>Post</Typography>
					</LinkedinShareButton>
					<EmailShareButton className="badhous-share-button" url={url}>
						<EmailIcon size={48} round />
						<Typography>Email</Typography>
					</EmailShareButton>
				</Box>
			</Box>
		</Box>
	)
}
