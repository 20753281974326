import React from 'react'
import {
	Tooltip as MuiTooltip,
	TooltipProps,
	Typography,
	useTheme,
} from '@mui/material'

export const Tooltip = ({ title, children, ...rest }: TooltipProps) => {
	const theme = useTheme()
	return (
		<MuiTooltip
			{...rest}
			title={
				<Typography
					sx={{
						color: theme.palette.common.white,
						fontSize: 14,
						fontWeight: theme.typography.fontWeightMedium,
					}}
				>
					{title}
				</Typography>
			}
		>
			{children}
		</MuiTooltip>
	)
}
