import React, { FC } from 'react'
import { PoweredBy } from 'lib'

interface Props {
	title: string
}

export const AuthHeader: FC<Props> = ({ title }) => {
	return (
		<div className="grid grid-cols-12 px-4 h-16 items-center border-solid border-b border-gray-300 badhous-header-border">
			<p className="flex items-center text-lg badhous-user-slug font-semibold col-span-8">
				{title}
			</p>
			<div className="flex justify-end col-span-4">
				<PoweredBy />
			</div>
		</div>
	)
}
