import React from 'react'
import { Button, TextField, ContentBlock, IconTag } from 'components'
import { ErrorMessageType } from 'types'
import { setUser, getUser, validateForm } from 'utils'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { AgentSetSettingsData } from 'services'
import { isListingProfileComplete } from '../helpers'

type PhotographerContactType = {
	name: string
	email: string
}

export const PhotographerContact = () => {
	const profile = getUser()
	const { enqueueSnackbar } = useSnackbar()
	const [submittingForm, setSubmittingForm] = React.useState(false)
	const [error, setError] = React.useState<ErrorMessageType>()

	const { handleSubmit, control } = useForm<PhotographerContactType>({
		defaultValues: {
			name: profile.settings.listingProfile.photographer.name,
			email: profile.settings.listingProfile.photographer.email,
		},
	})

	const handleContactInfoUpdate = async (data: PhotographerContactType) => {
		setSubmittingForm(true)
		setError(undefined)
		const formData = {
			type: 'agent',
			...data,
		}
		const error = validateForm(formData, {
			name: 'Name',
			email: 'Email',
		})

		if (error) {
			setError(error)
			setSubmittingForm(false)
			return
		}

		const newProfile = getUser()
		newProfile.settings.listingProfile.photographer = {
			name: formData.name,
			email: formData.email,
		}
		newProfile.settings.listingProfile.completed.photographer = true

		const updates = [
			{
				key: 'listingProfile',
				value: newProfile.settings.listingProfile,
			},
		]

		if (isListingProfileComplete(newProfile)) {
			newProfile.settings.onboarding.steps[1].complete = true

			updates.push({
				key: 'onboarding',
				value: newProfile.settings.onboarding,
			})
		}

		const result = await AgentSetSettingsData({
			agentUuid: profile.user.uuid,
			updates,
		})

		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			setSubmittingForm(false)
			return
		}
		setUser(newProfile)
		setSubmittingForm(false)
		enqueueSnackbar('Photographer Info Updated!', { variant: 'success' })
	}

	React.useEffect(() => {
		if (error) {
			enqueueSnackbar(error.message, { variant: 'error' })
		}
	}, [error, enqueueSnackbar])

	return (
		<div className="md:grid md:grid-cols-2 md:gap-4">
			<div className="md:mr-5 max-w-[400px] mx-auto">
				<ContentBlock
					label="Photographer Information"
					description="We send a custom link to your photographer to upload photos for a specific property."
					margin="6"
					tag={
						!profile.settings.listingProfile.completed.photographer && (
							<IconTag
								icon="Warning"
								text="Update photographer info!"
								color="#f48225"
							/>
						)
					}
				/>
			</div>
			<form
				onSubmit={handleSubmit(handleContactInfoUpdate)}
				className="max-w-[400px] mx-auto"
			>
				<TextField
					control={control}
					name="name"
					label="Name"
					error={error?.key === 'name'}
				/>
				<TextField
					type="email"
					control={control}
					name="email"
					label="Email Address"
					error={error?.key === 'email'}
				/>
				<Button
					type="submit"
					variant="contained"
					fullWidth
					loading={submittingForm}
				>
					Update
				</Button>
			</form>
		</div>
	)
}
