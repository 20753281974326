import React, { FC } from 'react'
import { Box, Button as MuiButton, Typography, useTheme } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

interface Props {
	complete: boolean
	path: string
	onClick?: () => void
}

export const StartCompleteButton: FC<Props> = ({ complete, path, onClick }) => {
	const navigate = useNavigate()
	const theme = useTheme()

	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (event.detail === 1) {
			navigate(path)
			onClick && onClick()
		}
	}

	return (
		<>
			{complete ? (
				<Box width={88} className="text-center">
					<CheckCircle sx={{ fontSize: 24, fill: theme.palette.green.main }} />
					<Typography
						sx={{
							fontSize: 14,
							color: theme.palette.green.main,
							fontWeight: theme.typography.fontWeightMedium,
						}}
					>
						Complete
					</Typography>
				</Box>
			) : (
				<div>
					<MuiButton
						variant="contained"
						onClick={handleClick}
						sx={{
							paddingLeft: theme.spacing(4),
							paddingRight: theme.spacing(4),
						}}
					>
						<Typography>Start</Typography>
					</MuiButton>
					<Typography
						className="block text-center italic text-gray-600 pt-1 font-semibold"
						sx={{ fontSize: 14 }}
					>
						Incomplete
					</Typography>
				</div>
			)}
		</>
	)
}
