export const getLocalStorageItem = (key: string) => {
	const result = localStorage.getItem(key)
	if (!result) {
		return null
	}

	try {
		return JSON.parse(result)
	} catch (e) {
		console.error('Failed to parse result.', e)
		return null
	}
}

export const setLocalStorageItem = (key: string, value: any) => {
	if (!value) {
		localStorage.removeItem(key)
		return
	}
	localStorage.setItem(key, JSON.stringify(value))
}
