import React from 'react'
import { Box, Typography } from '@mui/material'
import { Button } from './button'
import { useSaveChanges } from 'providers'

interface Props {
	unsavedChanges: boolean
}

export const SaveChangesFooter = ({ unsavedChanges }: Props) => {
	const { setSave } = useSaveChanges()
	return (
		<Box
			className={`${
				unsavedChanges ? 'badhous-show-save' : 'badhous-hide-save'
			} fixed duration-[400ms] left-[0] right-[0] bottom-[0] flex items-center justify-between shadow-lg bg-[#212020] py-4 pl-4 md:pl-8 pr-6 z-[99]`}
		>
			<Typography className="font-bold text-white md:text-[18px]">
				You have unsaved changes
			</Typography>
			<Button variant="white" onClick={() => setSave(true)}>
				Save
			</Button>
		</Box>
	)
}
