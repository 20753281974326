import React from 'react'
import { Share } from '@mui/icons-material'
import { Button, SocialSharing } from 'components'
import { useDialog } from 'providers'
import { getUser, setUser } from 'utils'
import { AgentSetSettingsData, AgentSetWebsiteSettings } from 'services'
import { useSnackbar } from 'notistack'

interface Props {
	publicUrl: string
}

export const WebsiteInternalMenu = ({ publicUrl }: Props) => {
	const profile = getUser()
	const { setDialogOpen } = useDialog()
	const { enqueueSnackbar } = useSnackbar()

	const [published, setPublished] = React.useState<boolean | undefined>(
		profile.website.settings.published
	)

	const handleShareWebsite = () => {
		setDialogOpen({
			title: 'Sharing is Caring',
			showClose: true,
			content: <SocialSharing url={publicUrl} />,
		})
	}

	const handlePublishUnpublishWebsite = async () => {
		const newSettings = {
			...profile.website.settings,
			published: !published,
		}

		const result = await AgentSetWebsiteSettings({
			settings: newSettings,
			agentUuid: profile.user.uuid,
		})

		if (published === false && !profile.settings.onboarding.steps[3].complete) {
			profile.settings.onboarding.steps[3].complete = true

			const result2 = await AgentSetSettingsData({
				agentUuid: profile.user.uuid,
				updates: [
					{
						key: 'onboarding',
						value: profile.settings.onboarding,
					},
				],
			})

			if (result2.error) {
				enqueueSnackbar(result2.error, { variant: 'error' })
				return
			}

			setUser(profile)
		}

		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			return
		}
		setPublished(!published)
	}

	React.useEffect(() => {
		if (profile.website.settings.published !== published) {
			const newProfile = getUser()
			setUser({
				...newProfile,
				website: {
					...newProfile.website,
					settings: {
						...newProfile.website.settings,
						published,
					},
				},
			})
			enqueueSnackbar(`Website ${published ? 'Published!' : 'Unpublished'}`, {
				variant: 'success',
			})
		}
	}, [published, enqueueSnackbar, profile])

	return (
		<div className="flex items-center md:justify-end">
			<Button
				variant="outlined"
				startIcon={<Share />}
				onClick={handleShareWebsite}
			>
				Share Site
			</Button>

			<Button
				tooltip={
					published
						? 'Hide website from public view'
						: 'Make website visible to the public'
				}
				variant="outlined"
				className="ml-2"
				onClick={handlePublishUnpublishWebsite}
			>
				{published ? 'Unpublish Site' : 'Publish Site'}
			</Button>
			<Button
				variant="contained"
				className="ml-3"
				onClick={() => window.open(publicUrl, '_blank')}
			>
				Site Preview
			</Button>
		</div>
	)
}
