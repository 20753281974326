import React, { FC } from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { Info } from '@mui/icons-material'

interface Props {
	message: string
}

export const InfoDisplay: FC<Props> = ({ message }) => {
	const theme = useTheme()
	return (
		<Box className="flex items-center justify-center px-10 py-4 rounded-md bg-gray-200">
			<Info sx={{ fill: theme.palette.icon.main }} />
			<Typography className="ml-4 font-bold">{message}</Typography>
		</Box>
	)
}
