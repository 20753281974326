import React from 'react'
import FittedImage from 'react-fitted-image'
import { Box, CircularProgress } from '@mui/material'
import { DEFAULT_USER_PHOTO } from 'lib/theme'

interface Props {
	display?: boolean
	fit?: 'cover' | 'contain' | 'auto'
	src?: string
	height?: number | string
	width?: number | string
	center?: boolean
}

export const Image = ({
	display,
	fit = 'cover',
	src = DEFAULT_USER_PHOTO,
	height = 'auto',
	width = 'auto',
	center = false,
}: Props) => {
	return (
		<Box
			sx={{ height, width }}
			className={`block relative${
				display ? ' rounded-md  overflow-hidden shadow-md' : ''
			} ${center ? ' mx-auto' : ''}
      `}
		>
			<FittedImage
				className={!display ? 'rounded-[4px]' : ''}
				fit={fit}
				src={src}
				loader={
					<div className="absolute top-0 bottom-0 left-0 right-0">
						<CircularProgress
							color="inherit"
							className="absolute"
							sx={{
								top: 'calc(50% - 20px)',
								left: 'calc(50% - 20px)',
							}}
						/>
					</div>
				}
			/>
		</Box>
	)
}
