import React from 'react'
import { List } from '@mui/icons-material'
import { Button, NavSidebarSlim } from 'components'
import { useLocation } from 'react-router-dom'
import { AgentSettingsType, UserAgentType } from 'types'
import { AgentAccount } from './account'
import { AgentAvailability } from './availability'
import { AgentChecklists } from './checklists'
import { AgentDailyRoutine } from './daily-routine'
import { MyProfile } from './my-profile'
import { AgentOnboarding } from './onboarding'

interface Props {
	slug: AgentSettingsRootProps
	settings: AgentSettingsType
	user: UserAgentType
}

export type AgentSettingsRootProps =
	| 'onboarding'
	| 'availability'
	| 'my-profile'
	| 'checklists'
	| 'daily-routine'
	| 'account'
	| undefined

export const AgentSettings = ({ slug, settings, user }: Props) => {
	const [menuOpen, setMenuOpen] = React.useState(false)

	let location = useLocation().pathname.split('/')[2]
	location = location.replace(/-/g, ' ')

	let page = null
	switch (slug) {
		case 'onboarding':
			page = <AgentOnboarding onboarding={settings.onboarding} />
			break
		case 'availability':
			page = <AgentAvailability />
			break
		case 'my-profile':
			page = <MyProfile />
			break
		case 'checklists':
			page = <AgentChecklists checklists={settings.checklists} />
			break
		case 'daily-routine':
			page = <AgentDailyRoutine routine={settings.routine} />
			break
		case 'account':
			page = <AgentAccount account={user} />
			break
	}
	return (
		<div className="sm:flex pt-10 md:pt-3">
			<Button
				className="md:hidden badhous-settings-menu-button"
				variant="outlined"
				startIcon={<List sx={{ color: '#444' }} />}
				onClick={() => setMenuOpen(!menuOpen)}
			>
				<span className="capitalize font-bold">
					{menuOpen ? 'Close' : location}
				</span>
			</Button>
			<div
				className={`w-[135px] mr-8 badhous-settings-sidebar-slim-container ${
					menuOpen ? 'active' : ''
				}`}
			>
				<NavSidebarSlim
					type="agentSettings"
					onChange={() => setMenuOpen(false)}
				/>
			</div>
			{page}
		</div>
	)
}
