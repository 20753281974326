import React from 'react'
import { Image } from './image'

interface Props {
	size?: number
	text?: string
	gray?: boolean
	src?: string
	border?: boolean
	center?: boolean
}

export const Avatar = ({
	size = 112,
	text,
	gray,
	src,
	border,
	center,
}: Props) => {
	return src ? (
		<div
			className={`shadow-lg rounded-full overflow-hidden ${
				center ? 'mx-auto' : ''
			}`}
			style={{
				border: border ? `solid 3px #bebebe` : 'none',
				height: size,
				width: size,
				minHeight: size,
				minWidth: size,
			}}
		>
			<Image height={size} width={size} src={src} />
		</div>
	) : text ? (
		<div className="relative">
			<span className={`${!gray ? 'badhous-user-avatar-design' : ''}`} />
			<span
				className={`badhous-user-avatar block ${
					gray ? 'bg-gray-200 w-10 h-10 leading-10 text-center' : 'bg-white'
				}`}
			>
				{text.toUpperCase()}
			</span>{' '}
		</div>
	) : null
}
