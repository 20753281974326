import React from 'react'
import { Button, TextField } from 'components'
import { useForm } from 'react-hook-form'
import { ErrorMessageType, CheckedItemListType } from 'types'
import { getUser, setUser, validateForm } from 'utils'
import { useSnackbar } from 'notistack'
import { AgentSetDashboardTasks } from 'services'

const defaultValues = {
	primary: '',
}

interface Props {
	tasks: CheckedItemListType[]
	onSuccess: (tasks: CheckedItemListType[]) => void
}

export const AddTask = ({ tasks, onSuccess }: Props) => {
	const profile = getUser()
	const { enqueueSnackbar } = useSnackbar()
	const [submittingForm, setSubmittingForm] = React.useState(false)
	const [error, setError] = React.useState<ErrorMessageType>()

	const { handleSubmit, control } = useForm<
		Omit<CheckedItemListType, 'checked'>
	>({
		defaultValues,
	})

	const handleAddTask = async (data: Omit<CheckedItemListType, 'checked'>) => {
		setSubmittingForm(true)
		setError(undefined)
		const error = validateForm(data, {
			primary: 'Task Title',
		})
		if (error) {
			setError(error)
			setSubmittingForm(false)
			return
		}

		const newTasks = [
			...tasks,
			{
				primary: data.primary,
				checked: false,
			},
		]

		const result = await AgentSetDashboardTasks({
			data: newTasks,
			agentUuid: profile.user.uuid,
		})
		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			setSubmittingForm(false)
			return
		}
		setSubmittingForm(false)
		setUser({
			...profile,
			tasks: newTasks,
		})
		onSuccess(newTasks)
	}

	return (
		<div className="px-4 py-6 mx-auto" style={{ maxWidth: 400 }}>
			<form onSubmit={handleSubmit(handleAddTask)}>
				<TextField
					autoFocus
					control={control}
					name="primary"
					label="Task Title"
					error={error?.key === 'primary'}
				/>
				<Button
					type="submit"
					variant="contained"
					fullWidth
					loading={submittingForm}
				>
					Add Task
				</Button>
			</form>
		</div>
	)
}
