import React, { FC } from 'react'
import pluralize from 'pluralize'
import { Box } from '@mui/material'
import { Star, StarOutline } from '@mui/icons-material'
import { Tooltip } from '../atoms'

interface Props {
	size?: number
	rating: number
	count: number
	presentational?: boolean
	onChange?: (rating: number) => void
}

export const StarRating: FC<Props> = ({
	size = 32,
	rating = 1,
	count,
	presentational,
	onChange,
}) => {
	const [currentRating, setCurrentRating] = React.useState(rating)

	const handleClick = (value: number) => {
		if (currentRating >= 0) {
			setCurrentRating(value)
			if (onChange) {
				onChange(value)
			}
		}
	}

	const starClass = presentational ? 'pointer-events-none' : 'cursor-pointer'
	const sx = { fill: '#fabb05', fontSize: size }
	return (
		<Box>
			{Array.apply(null, Array(count)).map((_, i) => {
				const isFilled = currentRating >= i + 1
				const star = isFilled ? (
					<Star
						className={starClass}
						onClick={() => handleClick(i + 1)}
						sx={sx}
					/>
				) : (
					<StarOutline
						className={starClass}
						onClick={() => handleClick(i + 1)}
						sx={sx}
					/>
				)

				return (
					<Tooltip key={i} title={`Rate ${pluralize('Star', i + 1, true)}`}>
						{star}
					</Tooltip>
				)
			})}
		</Box>
	)
}
