import React, { FC } from 'react'
import { IconButton as MuiIconButton, IconButtonProps } from '@mui/material'
import { Tooltip } from '../atoms'

interface Props extends IconButtonProps {
	tooltip: string
	badge?: boolean
	noBorder?: boolean
	verticalAdjustment?: number
}

export const IconButton: FC<Props> = ({
	tooltip,
	disabled,
	badge = false,
	noBorder = false,
	verticalAdjustment = 0,
	onClick,
	children,
	className,
	...rest
}) => {
	const [showBadge, setShowBadge] = React.useState<boolean>()

	React.useEffect(() => {
		if (badge) {
			setShowBadge(badge)
		}
	}, [badge])

	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (onClick && event.detail === 1) {
			setShowBadge(false)
			onClick(event)
		}
	}

	return (
		<Tooltip title={tooltip} disableHoverListener={disabled}>
			<span>
				<MuiIconButton
					{...rest}
					disabled={disabled}
					onClick={handleClick}
					className={`${showBadge ? 'badge' : ''}${
						noBorder ? ' noBorder' : ''
					} ${className}`}
					sx={{
						opacity: disabled ? 0.5 : 1,
						...rest.sx,
					}}
				>
					<span
						className="relative inline-flex"
						style={{ top: verticalAdjustment }}
					>
						{children}
					</span>
				</MuiIconButton>
			</span>
		</Tooltip>
	)
}
