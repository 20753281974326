import React, { FC } from 'react'
import { AvailabilityDateType, AvailabilityType } from 'types'
import { getUser } from 'utils'

const INITIAL_STATE = {
	stepInMinutes: 0,
	timezone: '',
	data: [],
} as AvailabilityType

interface Props {
	availability: AvailabilityType
	initialize: (availability: AvailabilityType) => void
	setAvailability: (
		index: number,
		availabilityDate: AvailabilityDateType
	) => void
	setTimezone: (timezone: string) => void
	setStepInMinutes: (minutes: number) => void
}

export const AvailabilityContext = React.createContext<Props>({
	availability: INITIAL_STATE,
	initialize: () => {},
	setAvailability: () => {},
	setTimezone: () => {},
	setStepInMinutes: () => {},
})

export const AvailabilityProvider: FC<{ children: React.ReactNode }> = ({
	children,
}): JSX.Element => {
	const profile = getUser()
	const savedAvailability = profile?.settings?.availability
	const [availability, setAvailability] = React.useState(
		savedAvailability || INITIAL_STATE
	)

	const handleInitialize = (intializer: AvailabilityType) => {
		setAvailability(intializer)
	}

	const handleSetTimezone = (timezone: string) => {
		const newAvailability = {
			...availability,
			timezone,
		}
		setAvailability(newAvailability)
	}

	const handleSetStepInMinutes = (stepInMinutes: number) => {
		const newAvailability = {
			...availability,
			stepInMinutes,
		}
		setAvailability(newAvailability)
	}

	const handleSetAvailability = (index: number, date: AvailabilityDateType) => {
		availability.data.splice(index, 1, date)
		const newAvailability = {
			...availability,
			data: [...availability.data],
		}
		setAvailability(newAvailability)
	}

	return (
		<AvailabilityContext.Provider
			value={{
				availability,
				initialize: handleInitialize,
				setAvailability: handleSetAvailability,
				setTimezone: handleSetTimezone,
				setStepInMinutes: handleSetStepInMinutes,
			}}
		>
			{children}
		</AvailabilityContext.Provider>
	)
}
