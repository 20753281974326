import React from 'react'
import { MenuItem, Typography, useTheme } from '@mui/material'
import { getUser, setUser } from 'utils'
import { Archive, Delete, East, Unarchive, West } from '@mui/icons-material'
import {
	ForceGenericObjectType,
	LeadConversionBlockType,
	LeadConversionIndexType,
	LeadConversionLabelEnum,
} from 'types'
import { useLeads } from 'providers'
import { useSnackbar } from 'notistack'
import { deleteLeadByUuid, updateLead } from '../helpers'
import { PopoverButton } from 'components'

interface Props {
	onConfirm?: () => void
}

export const MoveDirections = ({ onConfirm }: Props) => {
	const theme = useTheme()
	const { enqueueSnackbar } = useSnackbar()
	const { lead, setLead, deleteLead } = useLeads()

	if (!lead) {
		return null
	}

	type MoveLeadType = {
		lead: LeadConversionBlockType
		destination?: LeadConversionIndexType
		archive?: number
	}

	const handleMoveLead = async ({
		lead,
		destination,
		archive,
	}: MoveLeadType) => {
		let updates: ForceGenericObjectType[] = [
			{
				key: 'label',
				value: destination,
			},
		]
		if (archive !== undefined) {
			updates = [
				{
					key: 'archived',
					value: archive,
				},
			]
		}

		await updateLead(lead.uuid, updates)
		setLead(lead, destination, archive)

		let message = `${lead.name.split(' ')[0]} ${
			archive === 1
				? 'Moved to Archive'
				: `Moved to ${LeadConversionLabelEnum[lead.label]}`
		}`

		if (destination !== undefined) {
			message = `${lead.name.split(' ')[0]} Moved to ${
				LeadConversionLabelEnum[destination]
			}`
		}

		enqueueSnackbar(message, { variant: 'success' })
	}

	const handleDeleteLead = async (lead: LeadConversionBlockType) => {
		await deleteLeadByUuid(lead.uuid)
		deleteLead(lead)

		enqueueSnackbar(`${lead.name.split(' ')[0]} has been deleted`, {
			variant: 'success',
		})
	}

	let moveDirections: React.ReactNode[] | null = null

	if (!lead.archived) {
		moveDirections = [
			<div
				className="flex items-center w-full"
				onClick={() => handleMoveLead({ lead, destination: 'scheduled' })}
			>
				<East sx={{ fill: theme.palette.primary.main }} />
				<Typography className="font-bold">Move to Scheduled</Typography>
			</div>,
			<div
				className="flex items-center"
				onClick={() => handleMoveLead({ lead, destination: 'waiting' })}
			>
				<East sx={{ fill: theme.palette.primary.main }} />
				<Typography className="font-bold">Move to Contract Waiting</Typography>
			</div>,
		]

		if (lead.label === 'scheduled') {
			moveDirections = [
				<div
					className="flex items-center  w-full"
					onClick={() => handleMoveLead({ lead, destination: 'prospects' })}
				>
					<West sx={{ fill: theme.palette.primary.main }} />
					<Typography className="font-bold">Move to Prospects</Typography>
				</div>,
				<div
					className="flex items-center  w-full"
					onClick={() => handleMoveLead({ lead, destination: 'waiting' })}
				>
					<East sx={{ fill: theme.palette.primary.main }} />
					<Typography className="font-bold">
						Move to Contract Waiting
					</Typography>
				</div>,
			]
		} else if (lead.label === 'waiting') {
			moveDirections = [
				<div
					className="flex items-center  w-full"
					onClick={() => handleMoveLead({ lead, destination: 'scheduled' })}
				>
					<West sx={{ fill: theme.palette.primary.main }} />
					<Typography className="font-bold">Move to Scheduled</Typography>
				</div>,
				<div
					className="flex items-center  w-full"
					onClick={() => handleMoveLead({ lead, destination: 'prospects' })}
				>
					<West sx={{ fill: theme.palette.primary.main }} />
					<Typography className="font-bold">Move to Prospects</Typography>
				</div>,
			]
		}
	}

	return (
		<>
			{moveDirections?.map((direction, index) => {
				return (
					<MenuItem key={index} disableRipple>
						{direction}
					</MenuItem>
				)
			})}
			<MenuItem disableRipple>
				<div
					className="flex items-center  w-full"
					onClick={() =>
						handleMoveLead({ lead, archive: lead.archived ? 0 : 1 })
					}
				>
					{lead.archived ? (
						<Unarchive sx={{ fill: theme.palette.blue.main }} />
					) : (
						<Archive sx={{ fill: theme.palette.blue.main }} />
					)}
					<Typography className="font-bold">
						{lead.archived ? 'Unarchive' : 'Archive'}
					</Typography>
				</div>
			</MenuItem>
			<PopoverButton
				customButton={
					<MenuItem disableRipple>
						<div className="flex items-center  w-full">
							<Delete sx={{ fill: theme.palette.red.dark }} />
							<Typography className="font-bold">Delete</Typography>
						</div>
					</MenuItem>
				}
				onPromptButtonClick={() => {
					handleDeleteLead(lead)
					if (onConfirm) {
						onConfirm()
					}
				}}
			>
				<div className="p-4 pb-2 max-w-[280px]">
					<Typography className="text-lg font-bold">Are you sure?</Typography>
					<Typography className="mt-2 font-semibold">
						Deleting this lead is permanent and cannot be undone.
					</Typography>
				</div>
			</PopoverButton>
		</>
	)
}
