import React from 'react'
import { AppHeader, Page } from 'components'
import { getUser } from 'utils'
import { useNavigate, useParams } from 'react-router-dom'
import { AgentSettings, AgentSettingsRootProps } from 'views'

export const Settings = () => {
	const slug = useParams().slug as AgentSettingsRootProps
	const navigate = useNavigate()
	let data = getUser() || {}

	let agentPages = [
		'onboarding',
		'availability',
		'my-profile',
		'checklists',
		'daily-routine',
		'account',
	]
	let header = <AppHeader first={data.user.first} type={data.user.type} />
	let page = null

	switch (data.user.type) {
		case 'agent':
			page = (
				<AgentSettings slug={slug} settings={data.settings} user={data.user} />
			)
			break
		case 'brokerage':
			break
	}

	React.useEffect(() => {
		if (!data.user) {
			navigate('/sign-in')
		}
		if (slug && !agentPages.includes(slug)) {
			navigate('/important-stuff/onboarding')
		}
	}, [data, navigate])

	if (!data.user || !slug) {
		return null
	}

	return page ? (
		<Page
			header={header}
			sidebar={data.user.type}
			title="Important Stuff"
			hideAlert={slug === 'onboarding'}
		>
			{page}
		</Page>
	) : null
}
