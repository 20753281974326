import React from 'react'
import uuid from 'react-uuid'
import { Box, ListItem, ListItemButton, Typography } from '@mui/material'
import {
	ContentBlock,
	PopoverButton,
	TimeAvailabilityItem,
	TimezonePicker,
} from 'components'
import { useAvailability, useSaveChanges } from 'providers'
import { AgentSetSettingsData, AgentSetWebsiteSettings } from 'services'
import { getMinutes, getUser, setUser } from 'utils'
import { ArrowDropDownCircleOutlined } from '@mui/icons-material'

export const AgentAvailability = () => {
	const { availability, setTimezone, setStepInMinutes, initialize } =
		useAvailability()
	const {
		save,
		canceled,
		setCanceled,
		setSave,
		unsavedChanges,
		setUnsavedChanges,
	} = useSaveChanges()
	const profile = getUser()

	const saveAvailability = React.useCallback(async () => {
		profile.website.settings.availability = availability

		const result = await AgentSetSettingsData({
			agentUuid: profile.user.uuid,
			updates: [
				{
					key: 'availability',
					value: availability,
				},
			],
		})

		const result2 = await AgentSetWebsiteSettings({
			agentUuid: profile.user.uuid,
			settings: profile.website.settings,
		})

		if (result?.status === 'success' && result2?.status === 'success') {
			setSave(false)
			setUser({
				...profile,
				settings: {
					...profile.settings,
					availability,
				},
			})
		}
	}, [profile, availability, setSave])

	const handleTimezoneChange = (timezone: string) => {
		setTimezone(timezone)
		if (!unsavedChanges) {
			setUnsavedChanges()
		}
	}

	const handleSelectTime = (time: number) => {
		setStepInMinutes(time)
		if (!unsavedChanges) {
			setUnsavedChanges()
		}
	}

	React.useEffect(() => {
		if (save) {
			saveAvailability()
		}
	}, [save, saveAvailability])

	React.useEffect(() => {
		if (canceled) {
			initialize(getUser().settings.availability)
			setCanceled()
		}
	}, [canceled])

	return (
		<Box className="pt-[10px] mx-auto max-w-[480px] md:mx-0 md:max-w-full">
			<div className="mb-8">
				<div className="mb-8">
					<div className="mb-4 max-w-[480px]">
						<ContentBlock
							label="Call Duration"
							description="When clients schedule phone meetings with you from your website they will see this call duration."
						/>
					</div>
					<div className="max-w-[480px]">
						<PopoverButton
							title={getMinutes(availability.stepInMinutes)}
							endIcon={<ArrowDropDownCircleOutlined />}
						>
							<div className="w-[220px] max-h-[220px] overflow-x-hidden overflow-y-auto">
								{[15, 30, 45, 60, 75, 90, 105, 120, 150, 180].map(time => {
									return (
										<ListItem sx={{ padding: 0 }} key={time}>
											<ListItemButton
												onClick={() => handleSelectTime(time)}
												sx={{ padding: '8px 16px' }}
											>
												<Typography className="font-semibold">
													{getMinutes(time)}
												</Typography>
											</ListItemButton>
										</ListItem>
									)
								})}
							</div>
						</PopoverButton>
					</div>
				</div>
				<div className="mb-6">
					<Typography variant="contentLabel">Set your timezone</Typography>
				</div>
				<div className="max-w-[480px]">
					<TimezonePicker
						timezone={availability.timezone}
						labelColor="#f7f7f7"
						onChange={handleTimezoneChange}
					/>
				</div>
			</div>

			<div className="mb-6 max-w-[480px]">
				<ContentBlock
					label="Set your weekly hours"
					description="This schedule will sync with the calendar on your website so that your visitors can schedule calls with you."
				/>
			</div>
			{availability?.data?.map((date, index) => {
				return (
					<TimeAvailabilityItem key={uuid()} date={date} dateIndex={index} />
				)
			})}
		</Box>
	)
}
