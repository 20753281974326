import React from 'react'
import ReactPlayer from 'react-player/file'

interface Props {
	src: string
	id?: string
	autoFit?: boolean
}

export const VideoPlayer = ({ src, id, autoFit }: Props) => {
	const videoId = `react-player-id-${id}`
	const [overlay, setOverlay] = React.useState(true)
	const [video, setVideo] = React.useState<HTMLVideoElement | null>(null)

	React.useEffect(() => {
		if (id) {
			const video = document.querySelector(
				`#${videoId} video`
			) as HTMLVideoElement | null
			setVideo(video)
		}
	}, [])

	return (
		<div
			id={videoId}
			onClick={() => video?.play()}
			className={`${overlay ? 'badhous-welcome-video-overlay' : ''} ${
				!autoFit ? 'badhous-player-wrapper sm:min-w-[70vw]' : ''
			}`}
		>
			<ReactPlayer
				className={`${!autoFit ? 'badhous-react-player' : ''}`}
				url={src}
				controls
				width="100%"
				height="100%"
				onPlay={() => setOverlay(false)}
				onPause={() => setOverlay(true)}
			/>
		</div>
	)
}
