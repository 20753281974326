import React from 'react'
import { Settings } from '@mui/icons-material'
import { Button, DashboardCard, IconButton, ItemList } from 'components'
import { useSnackbar } from 'notistack'
import { getUser, setUser } from 'utils'
import { CheckedItemListType } from 'types'
import { AgentSetDashboardRoutine } from 'services'
import { useNavigate } from 'react-router-dom'

export const DailyRoutine = () => {
	const profile = getUser()
	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const [loading, setLoading] = React.useState<number | undefined>()
	const [routine, setRoutine] = React.useState(profile.settings.routine)

	const handleItemChange = (
		value: {
			value: string | undefined
			index: number
		},
		checked: boolean | undefined
	) => {
		setLoading(value.index)
		routine[value.index].checked = checked
		saveRoutine([...routine])
	}

	const saveRoutine = async (newRoutine: CheckedItemListType[]) => {
		const result = await AgentSetDashboardRoutine({
			routine: newRoutine,
			agentUuid: profile.user.uuid,
		})
		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			return
		}
		setRoutine(newRoutine)
		setLoading(undefined)
	}

	const handleReset = () => {
		saveRoutine(
			routine.map((item: CheckedItemListType) => {
				return {
					...item,
					checked: false,
				}
			})
		)
	}

	React.useEffect(() => {
		setUser({
			...profile,
			settings: {
				...profile.settings,
				routine,
			},
		})
	}, [profile, routine])

	return (
		<DashboardCard
			title="Daily Routine"
			subtitle="Use the daily routine to help increase productivity, stay motivated, and make progress towards your long term goals."
			action={
				<div className="flex items-center">
					<Button onClick={handleReset}>Reset</Button>
					<IconButton
						tooltip="Routine Settings"
						noBorder
						sx={{ marginLeft: 1 }}
						onClick={() => navigate('/important-stuff/daily-routine')}
					>
						<Settings />
					</IconButton>
				</div>
			}
		>
			<ItemList
				type="Routine"
				strike
				ordered
				onClick={(value, checked) => handleItemChange(value, checked)}
				items={routine}
				loading={loading}
				noItemsSpacing
			/>
		</DashboardCard>
	)
}
