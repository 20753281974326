import React, { FC } from 'react'
import { Box, Typography, useTheme } from '@mui/material'

interface Props {
	title: string
	titleSuffix?: string
	titleIcon?: React.ReactNode
	headerIcon?: React.ReactNode
	headerAction?: React.ReactNode
	highlight?: boolean
	disabled?: boolean
	action: React.ReactNode
}

export const DataViewerCard: FC<Props> = ({
	title,
	titleSuffix,
	titleIcon,
	headerIcon,
	headerAction,
	highlight,
	disabled,
	action,
}) => {
	const theme = useTheme()
	return (
		<Box
			className="border border-solid border-gray-300 rounded-lg shadow-md"
			sx={{
				paddingTop: titleIcon ? theme.spacing(4) : 0,
				pointerEvents: disabled ? 'none' : 'all',
				opacity: disabled ? 0.6 : 1,
				borderColor: highlight
					? `${theme.palette.secondary.main} !important`
					: 'initial',
			}}
		>
			{(headerIcon || headerAction) && (
				<Box
					className="p-2 flex justify-between items-center"
					sx={{ justifyContent: !headerIcon ? 'flex-end' : 'space-between' }}
				>
					<span style={{ opacity: disabled ? 0.6 : 1 }}>{headerIcon}</span>
					{headerAction}
				</Box>
			)}
			<Typography className="text-center">
				{titleIcon && <span className="block mb-3">{titleIcon}</span>}
				<span className="block flex items-center justify-center">
					<span
						className="block font-bold"
						style={{
							fontSize: titleIcon ? 16 : 18,
							maxWidth: titleIcon ? 250 : '100%',
							lineHeight: titleIcon ? 1.4 : 'inherit',
						}}
					>
						{title}
					</span>
					{titleSuffix && (
						<span
							style={{ fontSize: 24, color: theme.palette.secondary.main }}
							className="ml-2 font-black"
						>
							{titleSuffix}
						</span>
					)}
				</span>
			</Typography>
			{titleIcon && (
				<span
					style={{
						width: 80,
						height: 1,
						backgroundColor: theme.palette.secondary.main,
					}}
					className="block mx-auto mt-4"
				></span>
			)}
			<Box className="mt-6 mb-6 text-center">{action}</Box>
		</Box>
	)
}
