import React from 'react'
import { Height } from '@mui/icons-material'
import { Resizable } from 'react-resizable'

interface Props {
	initialHeight?: number
	initialWidth?: number
	minHeight?: number
	children: React.ReactNode
	onResize?: (dimensions: ResizeDimensionsType) => void
}

type ResizeDimensionsType = {
	height: number
	width: number
}

export const ResizableContainer = ({
	initialHeight = 200,
	initialWidth = 200,
	minHeight = 120,
	children,
	onResize,
}: Props) => {
	const [dimensions, setDimensions] = React.useState<ResizeDimensionsType>({
		height: initialHeight,
		width: initialWidth,
	})

	const handleResize = (_: any, { size }: any) => {
		setDimensions({ width: size.width, height: size.height })
		if (onResize) {
			onResize({ width: size.width, height: size.height })
		}
	}

	return (
		<Resizable
			className="relative border-b-2 border-solid border-[#e5e5e5] badhous-resizable"
			height={dimensions.height}
			minConstraints={[dimensions.width, minHeight]}
			onResize={handleResize}
			handle={
				<div className="badhous-resizable-handle">
					<Height />
				</div>
			}
			axis="y"
		>
			<div style={{ height: dimensions.height }}>{children}</div>
		</Resizable>
	)
}
