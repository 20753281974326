import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { MenuButton } from '../atoms'
import { getRoutes } from 'utils'
import { ChevronRight } from '@mui/icons-material'

interface Props {
	type: 'agentSettings' | 'brokerageSettings'
	onChange?: () => void
}

export const NavSidebarSlim = ({ type, onChange }: Props) => {
	const routes = getRoutes(type)
	const navigate = useNavigate()
	const location = useLocation()

	return (
		<Box width="100%" className="badhous-settings-sidebar-slim">
			{routes.map(route => {
				if (route.name && route.path) {
					const isActive = location.pathname === route.path
					return (
						<MenuButton
							slim
							key={route.path}
							active={isActive}
							onClick={() => {
								navigate(route.path)
								if (onChange) {
									onChange()
								}
							}}
							endIcon={
								isActive ? (
									<ChevronRight
										sx={{ fill: '#09401e', position: 'relative', top: '1px' }}
									/>
								) : undefined
							}
						>
							{route.name}
						</MenuButton>
					)
				}
				return null
			})}
		</Box>
	)
}
