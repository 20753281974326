import React from 'react'
import uuid from 'react-uuid'
import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { setUser, getUser, useDebounce, isArrEqual } from 'utils'
import { AddNewButton, ContentBlock, IconButton, TextField } from 'components'
import { AgentSetSettingsData } from 'services'
import { useSnackbar } from 'notistack'
import { Delete } from '@mui/icons-material'
import { AgentSettingsType, CheckedItemListType } from 'types'

interface Props {
	routine: AgentSettingsType['routine']
}

type CheckedItemListTypeWithKey = CheckedItemListType & { key: string }

export const AgentDailyRoutine = ({ routine: savedRoutine }: Props) => {
	const profile = getUser()
	const { control } = useForm()
	const { enqueueSnackbar } = useSnackbar()

	const [routineItems, setRoutineItems] = React.useState<
		CheckedItemListTypeWithKey[]
	>(
		savedRoutine.map(item => {
			return {
				...item,
				key: uuid(),
			}
		})
	)

	const debouncedItems = useDebounce(routineItems, 1000)

	const handleAddItem = () => {
		const newItems = [
			...routineItems,
			{ primary: '', checked: false, key: uuid() },
		]
		setRoutineItems(newItems)
	}

	const handleDeleteItem = (index: number) => {
		const newItems = [...routineItems]
		newItems.splice(index, 1)
		setRoutineItems(newItems)
	}

	const handleUpdateChecklistBlock = async (value: string, index: number) => {
		const newItems = [...routineItems]
		newItems.splice(index, 1, {
			...newItems[index],
			primary: value,
		})
		setRoutineItems(newItems)
	}

	const handleSave = async (newDailyRoutine: CheckedItemListTypeWithKey[]) => {
		const result = await AgentSetSettingsData({
			agentUuid: profile.user.uuid,
			updates: [
				{
					key: 'routine',
					value: newDailyRoutine.map(item => {
						return {
							primary: item.primary,
							checked: item.checked,
						}
					}),
				},
			],
		})

		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			return
		}

		if (result?.status === 'success') {
			enqueueSnackbar('Daily routine updated!', {
				variant: 'success',
			})

			setUser({
				...profile,
				settings: {
					...profile.settings,
					routine: newDailyRoutine,
				},
			})
		}
	}

	React.useEffect(() => {
		if (
			!isArrEqual(
				debouncedItems.map((item: CheckedItemListType) => item.primary),
				profile.settings.routine.map(
					(item: CheckedItemListType) => item.primary
				)
			)
		) {
			handleSave(debouncedItems)
		}
	}, [debouncedItems])

	return (
		<Box className="pt-[10px] w-full mx-auto max-w-[640px] md:mx-0 mb-20">
			<div className="mb-8 max-w-[600px]">
				<ContentBlock
					label="Set your daily routine"
					description="This is what will appear in the daily routine section on your dashboard"
				/>
			</div>
			<form className="w-full max-w-[640px]">
				{routineItems.map((listItem, index) => {
					return (
						<div className="flex items-center" key={listItem.key}>
							<Typography className="font-black mb-3 min-w-[20px]">
								{index + 1}
							</Typography>
							<TextField
								control={control}
								name={listItem.key}
								label={!listItem.primary.length ? 'Add an item' : ''}
								defaultValue={listItem.primary}
								className="ml-5"
								onChange={({ value }) =>
									handleUpdateChecklistBlock(value, index)
								}
								size="small"
							/>
							<IconButton
								noBorder
								tooltip="Remove Item"
								onClick={() => handleDeleteItem(index)}
								className="ml-2 mb-3"
							>
								<Delete />
							</IconButton>
						</div>
					)
				})}
				<div className={`${routineItems.length ? 'pl-10 pr-12' : ''}`}>
					<AddNewButton
						text={`Add ${routineItems.length ? 'another' : 'an'} Item`}
						onClick={handleAddItem}
					/>
				</div>
			</form>
		</Box>
	)
}
