import React, { FC } from 'react'
import { lighten, Typography, styled } from '@mui/material'
import { Button } from '../atoms'
import { format, formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'

interface Props {
	availableTimes: Date[]
	selectedDate: Date
	onSelect: (dateTime: Date) => void
}

const TimePickerContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	height: '100%',
	flexDirection: 'column',
	'& .pkapp_timezonepicker_inner': {
		overflowX: 'hidden',
		overflowY: 'auto',
		paddingLeft: 28,
		paddingRight: 28,
		[theme.breakpoints.up('sm')]: {
			maxHeight: '500px',
		},
	},
	'& .pkapp_timezonepicker_buttonContainer': {
		whiteSpace: 'nowrap',
		marginBottom: theme.spacing(1),
		'& > *:last-child': {
			marginLeft: '3%',
		},
	},
	'& .pkapp_timezonepicker_button': {
		width: '48.5%',
		transition: 'all 200ms ease-in-out',
		border: '1px solid transparent',
	},
	'& .pkapp_timezonepicker_inactive': {
		width: '100%',
		borderColor: lighten(theme.palette.calendar.main, 0.5),
	},
	'& .pkapp_timezonepicker_error': {
		textAlign: 'center',
		fontWeight: 600,
		padding: 16,
		borderRadius: 4,
		backgroundColor: '#f7f7f7',
		border: 'solid 1px #e5e5e5',
		[theme.breakpoints.up('sm')]: {
			maxWidth: '200px',
		},
	},
}))

export const TimePicker: FC<Props> = ({
	availableTimes,
	selectedDate,
	onSelect,
}) => {
	const [selected, setSelected] = React.useState<Date>()

	const handleSelectTime = (time: Date) => {
		setSelected(time)
	}

	React.useEffect(() => {
		if (selected) {
			setSelected(undefined)
		}
	}, [selectedDate])

	return (
		<TimePickerContainer>
			{selectedDate && (
				<Typography
					gutterBottom
					className="font-semibold text-center"
					style={{ marginTop: 8, height: '40px' }}
				>
					{format(selectedDate, 'eeee, MMMM do')}
				</Typography>
			)}
			<div className="pkapp_timezonepicker_inner">
				{availableTimes.map((time, i) => {
					const formattedTime = format(time, 'h:mma').toLowerCase()
					const active =
						selected &&
						format(selected, 'h:mma').toLowerCase() === formattedTime

					return (
						<div key={i} className="pkapp_timezonepicker_buttonContainer">
							<Button
								variant={active ? 'calendar' : 'calendarOutlined'}
								size="large"
								onClick={() => handleSelectTime(time)}
								color={active ? 'primary' : 'secondary'}
								disabled={Boolean(active)}
								className={`pkapp_timezonepicker_button ${
									!active ? 'pkapp_timezonepicker_inactive' : ''
								}`}
							>
								{formattedTime}
							</Button>
							<Button
								variant="calendar"
								className="pkapp_timezonepicker_button"
								onClick={() => onSelect && onSelect(time)}
								sx={{
									opacity: !active ? 0 : 1,
									pointerEvents: !active ? 'none' : 'all',
								}}
							>
								Confirm
							</Button>
						</div>
					)
				})}
				{!availableTimes.length ? (
					<Typography className="pkapp_timezonepicker_error">
						No availability. Please select a different date{' '}
						<span role="img">😄</span>
					</Typography>
				) : null}
			</div>
		</TimePickerContainer>
	)
}
