import React, { FC } from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

export const Link: FC<LinkProps> = ({ to, ...rest }) => {
	return (
		<RouterLink {...rest} to={to} className="hover:underline block">
			Forgot Password
		</RouterLink>
	)
}
