import { Accordion, ColorChip, ContentBlock, TabSet } from 'components'
import { useLeads } from 'providers'
import { LeadConversionBlockType, LeadConversionLabelEnum } from 'types'
import { DialogForm } from '../dialog-form'
import { DialogMeeting } from './lead-dialog-meeting'

interface Props {
	tabIndex?: number
	lead: LeadConversionBlockType
	onSuccess: (lead: LeadConversionBlockType) => void
}

export const LeadDialog = ({
	tabIndex = 0,
	lead: initialLead,
	onSuccess,
}: Props) => {
	const { lead } = useLeads()

	let color: 'blue' | 'green' | 'red' = 'blue'
	if (lead?.label === 'scheduled') {
		color = 'green'
	} else if (lead?.label === 'waiting') {
		color = 'red'
	}

	return lead ? (
		<TabSet
			tabIndex={tabIndex}
			tabTitles={['Overview', 'Documents']}
			context="seller-leads"
		>
			<div className="p-8 max-h-[600px] overflow-y-scroll box-border">
				<ContentBlock
					label="Lead Stage"
					description={
						<ColorChip
							label={LeadConversionLabelEnum[lead.label]}
							color={color}
						/>
					}
					margin="5"
				/>
				<div className="m_md:flex m_md:flex-wrap m_md:justify-center md:grid grid-cols-3 gap-4">
					<div className="md:col-span-1 m_md:flex flex-wrap justify-center">
						<Accordion title="Contact Info" expanded>
							<DialogForm lead={lead} onSuccess={onSuccess} />
						</Accordion>
						<Accordion title="Meeting Info" expanded>
							<DialogMeeting lead={lead} onSuccess={onSuccess} />
						</Accordion>
					</div>
					<div className="md:col-span-2 m_md:flex flex-wrap justify-center">
						<Accordion title="Questionnaire" expanded>
							<DialogForm lead={lead} onSuccess={onSuccess} />
						</Accordion>
						<Accordion title="Lead Notes" expanded>
							<DialogForm lead={lead} onSuccess={onSuccess} />
						</Accordion>
						<Accordion title="Client Notes" expanded>
							<DialogForm lead={lead} onSuccess={onSuccess} />
						</Accordion>
					</div>
				</div>
			</div>
		</TabSet>
	) : null
}

//
// 					<MeetingTab
// 						lead={lead}
// 						leadType="seller"
// 						onSuccess={onSuccess}
// 					/>
