import React, { FC } from 'react'
import { Card as MuiCard, Box, Typography, BoxProps } from '@mui/material'

interface Props extends BoxProps {
	title: string
	subtitle?: string
	action?: React.ReactNode
}

export const DashboardCard: FC<Props> = ({
	title,
	subtitle,
	action = null,
	children,
}) => {
	return (
		<MuiCard className="shadow-md mb-6">
			<Box
				className={`flex pb-2 ${subtitle ? 'items-start' : 'items-center'} ${
					subtitle ? 'pt-6' : 'pt-4'
				} px-4 md:px-8 flex-wrap`}
			>
				<Box>
					<Typography variant="h6" className="font-black">
						{title}
					</Typography>
				</Box>
				<Box
					className={`relative ${subtitle ? 'top-[-4px]' : ''} mr-0 ml-auto`}
				>
					{action}
				</Box>
				{subtitle && (
					<Box className="grow">
						<Typography className="pr-4 sm:pr-0 sm:max-w-[80%] font-medium">
							{subtitle}
						</Typography>
					</Box>
				)}
			</Box>
			<Box>{children}</Box>
		</MuiCard>
	)
}
