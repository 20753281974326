import React from 'react'
import {
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
	PinterestIcon,
} from 'react-share'
import { useForm } from 'react-hook-form'
import { SocialMediaType, TextFieldOnChangeType } from 'types'
import { setUser, getUser, useDebounce, isObjEqual } from 'utils'
import { useSnackbar } from 'notistack'
import { Box } from '@mui/material'
import { ContentBlock, TextField } from 'components'
import { INSTAGRAM_PNG, ZILLOW_PNG } from 'lib/theme'
import { AgentSetSettingsData, AgentSetWebsiteSettings } from 'services'
import { isListingProfileComplete } from '../helpers'

const defaultValues = {
	facebook: '',
	instagram: '',
	twitter: '',
	linkedIn: '',
	zillow: '',
	pinterest: '',
}

export const SocialMedia = () => {
	const profile = getUser()
	const { enqueueSnackbar } = useSnackbar()
	const { control } = useForm()

	const [socialUrls, setSocialUrls] = React.useState<SocialMediaType>(
		profile?.settings?.listingProfile?.social || defaultValues
	)
	const debouncedUrls = useDebounce(socialUrls, 600)

	const handleUpdateSocialMedia = async ({
		name,
		value,
	}: TextFieldOnChangeType) => {
		setSocialUrls({
			...socialUrls,
			[name]: value,
		})
	}

	React.useEffect(() => {
		if (!isObjEqual(debouncedUrls, getUser().settings.listingProfile.social)) {
			handleSave(debouncedUrls)
		}
	}, [debouncedUrls])

	const handleSave = async (newSocialMedia: SocialMediaType) => {
		const newProfile = getUser()
		newProfile.settings.listingProfile.social = newSocialMedia
		newProfile.settings.listingProfile.completed.social = true
		newProfile.website.settings.contact = {
			...newProfile.website.settings.contact,
			...newSocialMedia,
		}

		const updates = [
			{
				key: 'listingProfile',
				value: newProfile.settings.listingProfile,
			},
		]

		if (isListingProfileComplete(newProfile)) {
			newProfile.settings.onboarding.steps[1].complete = true

			updates.push({
				key: 'onboarding',
				value: newProfile.settings.onboarding,
			})
		}

		const result = await AgentSetSettingsData({
			agentUuid: profile.user.uuid,
			updates,
		})

		const result2 = await AgentSetWebsiteSettings({
			agentUuid: profile.user.uuid,
			settings: newProfile.website.settings,
		})

		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			return
		}
		if (result2.error) {
			enqueueSnackbar(result2.error, { variant: 'error' })
			return
		}
		if (result?.status === 'success' && result?.status === 'success') {
			setUser(newProfile)
			enqueueSnackbar('Social Media Updated!', { variant: 'success' })
		}
	}

	return (
		<Box className="pt-2 mx-auto max-w-[856px] md:mx-0">
			<div className="mb-8 max-w-[600px]">
				<ContentBlock
					label="Social Media (not required)"
					description="Add the urls to your social media accounts. These will be displayed on your website and emails so client and potential clients can find you."
				/>
			</div>
			<form className="max-w-[600px]">
				<div className="flex items-center">
					<FacebookIcon size={36} round className="mb-3" />
					<TextField
						control={control}
						name="facebook"
						label="Facebook Profile URL"
						defaultValue={socialUrls.facebook}
						className="ml-4"
						onChange={handleUpdateSocialMedia}
					/>
				</div>
				<div className="flex items-center">
					<img
						src={INSTAGRAM_PNG}
						alt="Instagram Logo"
						style={{ width: 32 }}
						className="mb-3"
					/>
					<TextField
						control={control}
						name="instagram"
						label="Instagram Profile URL"
						defaultValue={socialUrls.instagram}
						className="ml-4"
						onChange={handleUpdateSocialMedia}
					/>
				</div>
				<div className="flex items-center">
					<TwitterIcon size={36} round className="mb-3" />
					<TextField
						control={control}
						name="twitter"
						label="Twitter Profile URL"
						defaultValue={socialUrls.twitter}
						className="ml-4"
						onChange={handleUpdateSocialMedia}
					/>
				</div>
				<div className="flex items-center">
					<LinkedinIcon size={36} round className="mb-3" />
					<TextField
						control={control}
						name="linkedin"
						label="LinkedIn Profile URL"
						defaultValue={socialUrls.linkedin}
						className="ml-4"
						onChange={handleUpdateSocialMedia}
					/>
				</div>
				<div className="flex items-center">
					<img
						src={ZILLOW_PNG}
						alt="Zillow Logo"
						style={{ width: 32 }}
						className="mb-3"
					/>
					<TextField
						control={control}
						name="zillow"
						label="Zillow URL"
						defaultValue={socialUrls.zillow}
						className="ml-4"
						onChange={handleUpdateSocialMedia}
					/>
				</div>
				<div className="flex items-center">
					<PinterestIcon size={36} round className="mb-3" />
					<TextField
						control={control}
						name="pinterest"
						label="Pinterest Profile URL"
						defaultValue={socialUrls.pinterest}
						className="ml-4"
						onChange={handleUpdateSocialMedia}
					/>
				</div>
			</form>
		</Box>
	)
}
