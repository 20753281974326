import React from 'react'
import { useDialog } from 'providers'
import { AvailabilityType, LeadConversionBlockType, MeetingsType } from 'types'
import {
	getAvailableTimesToScheduleAppointments,
	addToMeetings,
	getLocalTimeFromZone,
	getUser,
} from 'utils'
import { DatePicker } from './date-picker'
import { TimePicker } from './timepicker'
import { TimezonePicker } from 'components'
import { AgentGetMeetingsData, AgentSetMeetingsData } from 'services'
import { format, formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'
import { updateLead } from 'views/agent/lead-conversion/helpers'

interface Props {
	lead: LeadConversionBlockType
}

export const LeadScheduler = ({ lead }: Props) => {
	const data = getUser()
	const [schedulerTimezone, setSchedulerTimezone] = React.useState<string>(
		Intl.DateTimeFormat().resolvedOptions().timeZone.replace(/_/g, ' ')
	)
	const [availableTimes, setAvailableTimes] = React.useState<Date[]>()
	const [selectedDate, setSelectedDate] = React.useState(new Date())
	const [meetingsData, setMeetingsData] = React.useState(data.meetings)

	const availability: AvailabilityType = data.settings.availability
	const excludedDates: Date[] = data.excludedDates

	const handleDateChange = (date: Date) => {
		setSelectedDate(date)
		getAgentMeetings()
	}

	const handleSaveLead = async (
		lead: LeadConversionBlockType,
		newMeetings: MeetingsType,
		meetingTime: string
	) => {
		const agentTimezone = availability.timezone.replace(/ /g, '_')
		const utcTime = zonedTimeToUtc(new Date(meetingTime), agentTimezone)
		const formattedDateTime = formatInTimeZone(
			utcTime,
			agentTimezone,
			'eee, MMM do @ h:mma'
		)
		const timeZone = formatInTimeZone(
			new Date(meetingTime),
			agentTimezone,
			'zzz'
		)

		const result1 = await updateLead(lead.uuid, [
			{
				key: 'meeting',
				value: {
					...lead.meeting,
					scheduled: true,
				},
			},
		])

		if (result1.status === 'success') {
			await AgentSetMeetingsData({
				meetings: newMeetings,
				agent: {
					uuid: data.user.uuid,
					first: data.user.first,
					last: data.user.last,
					email: data.user.settings.contact.email,
					meetingTime: formattedDateTime + ' ' + timeZone,
				},
				client: {
					...lead,
					meetingTime,
				},
			})
		}
		getAgentMeetings()
	}

	const getAgentMeetings = async () => {
		const result = await AgentGetMeetingsData({
			agentUuid: data.user.uuid,
		})

		if (result.status === 'success') {
			setMeetingsData({ ...result.meetings })
		}
	}

	const handleTimeSelected = (dateTime: Date) => {
		const zonedTime = getLocalTimeFromZone(
			dateTime,
			availability.timezone.replace(/ /g, '_'),
			schedulerTimezone?.replace(/ /g, '_')
		)
		const newMeetings = addToMeetings(zonedTime, meetingsData)
		const formattedMeetingTime =
			format(dateTime, 'eeee, MMM do @ h:mma') +
			' ' +
			formatInTimeZone(new Date(), schedulerTimezone?.replace(/ /g, '_'), 'zzz')

		handleSaveLead(lead, newMeetings, formattedMeetingTime)
	}

	const handleTimezoneChange = (timezone: string) => {
		setSchedulerTimezone(timezone.replace(/_/g, ' '))
	}

	React.useEffect(() => {
		setAvailableTimes(
			getAvailableTimesToScheduleAppointments(
				selectedDate,
				availability,
				meetingsData,
				schedulerTimezone?.replace(/ /g, '_')
			)
		)
	}, [meetingsData])

	return (
		<div className="sm:flex justify-center">
			<div>
				<DatePicker
					selectedDate={selectedDate}
					availability={availability}
					excludedDates={[]}
					onDateChange={handleDateChange}
				/>
				<div className="mt-3">
					<TimezonePicker
						timezone={schedulerTimezone}
						onChange={handleTimezoneChange}
						publicViewer
					/>
				</div>
			</div>
			<div className="mt-10 md:mt-0 md:max-w-[280px] h-[324px] overflow-y-auto">
				<TimePicker
					selectedDate={selectedDate}
					availableTimes={availableTimes || []}
					onSelect={handleTimeSelected}
				/>
			</div>
		</div>
	)
}
