import React, { FC } from 'react'
import { Avatar, Button, DatePicker, Scheduler, TimePicker } from 'components'
import { useDialog } from 'providers'
import { Typography } from '@mui/material'
import { PoweredBy } from 'lib'
import { AvailabilityType, FAQType } from 'types'

interface Props {
	user: {
		uuid: string
		name: string
		brokerage: string
		photo: string
		faq: FAQType
		meetings: any
		availability: AvailabilityType
	}
}

export const PublicWebsiteFooter: FC<Props> = ({ user }) => {
	const { uuid, name, brokerage, photo, faq } = user
	const { setDialogOpen } = useDialog()

	const handleSellYourHome = () => {
		setDialogOpen({
			title: 'Sell Your Home',
			content: <div>sell it</div>,
			showClose: true,
		})
	}

	const handleViewHomesForSale = () => {
		setDialogOpen({
			title: 'Homes For Sale',
			content: <div>buy it</div>,
			showClose: true,
		})
	}

	const handleWatchVideo = () => {
		setDialogOpen({
			title: 'Welcome Video',
			content: <div>watch it</div>,
			showClose: true,
		})
	}

	const handleViewListingPresentation = () => {
		setDialogOpen({
			title: 'Listing Presentation',
			content: <div>read it</div>,
			showClose: true,
		})
	}

	const year = new Date().getFullYear()

	return (
		<>
			<div className="bg-white">
				<div className="pb-28 pt-28 max-w-[1350px] mx-auto">
					<div className="md:grid place-content-center place-items-center grid-cols-12 gap-4 m_md:max-w-[635px] m_md:mx-auto m_md:px-5 md:px-10">
						<div className="m_md:mb-20 col-span-4 self-start">
							<Typography className="font-black text-2xl mb-5">
								Frequently Asked Questions
							</Typography>
							<div>
								{faq.map((faqBlock, index) => {
									return (
										<div className="mb-4" key={index}>
											<Typography className="font-bold text-md">
												{faqBlock.question}
											</Typography>
											<Typography className="font-semibold text-md">
												{faqBlock.answer}
											</Typography>
										</div>
									)
								})}
							</div>
						</div>
						<div
							className="col-span-8 self-start"
							id="badhous-footer-scheduler"
						>
							<Typography className="font-black text-2xl mb-5">
								Looking to Buy or Sell?
							</Typography>
							<Typography className="md:hidden m_md:text-center font-bold my-4 text-lg">
								Select a Day
							</Typography>
							<Scheduler uuid={uuid} />
						</div>
						<div></div>
					</div>
				</div>
			</div>
			<div className="py-16 md:flex items-center justify-center border-solid border-t border-gray-300 bg-white">
				<div className="m_md:mx-auto m_md:max-w-[98px] md:mr-10">
					<Avatar size={96} src={photo} />
				</div>
				<Button
					className="block font-semibold cursor-pointer m_md:mt-8 m_md:mb-4 m_md:mx-auto"
					onClick={handleSellYourHome}
					fullOnMobile
				>
					Sell Your Home
				</Button>
				<Button
					className="block font-semibold cursor-pointer m_md:mb-4 m_md:mx-auto"
					onClick={handleViewHomesForSale}
					fullOnMobile
				>
					Homes For Sale
				</Button>
				<Button
					className="block font-semibold cursor-pointer m_md:mb-4 m_md:mx-auto"
					onClick={handleWatchVideo}
					fullOnMobile
				>
					Watch My Welcome Video
				</Button>
				<Button
					className="block font-semibold cursor-pointer m_md:mx-auto"
					onClick={handleViewListingPresentation}
					fullOnMobile
				>
					View Listing Presentation
				</Button>
			</div>
			<div className="xmd:flex items-center justify-between bg-[#212020] py-6 px-8">
				<Typography className="block m_xmd:text-center m_xmd:mb-4 font-semibold text-white">
					© {year} {name}
					{`${brokerage ? `, ${brokerage}.` : '.'}`} All Rights Reserved
				</Typography>
				<div className="m_xmd:text-center">
					<PoweredBy white />
				</div>
			</div>
		</>
	)
}
