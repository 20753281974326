import React, { FC } from 'react'
import { Box } from '@mui/material'
import {
	Button,
	Card,
	AuthHeader,
	Page,
	TextField,
	Link,
	ActiveButtonGroup,
} from 'components'
import { userSignIn } from 'services'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { getUser, setUser, validateForm } from 'utils'
import { useSnackbar } from 'notistack'
import { ErrorMessageType, UserSignInType } from 'types'
import {
	useAvailability,
	useQuestionnaire,
	useFAQ,
	useRecentListings,
} from 'providers'

const defaultValues = {
	email: '',
	password: '',
}

export const SignIn: FC = () => {
	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const { initialize: initializeAvailability } = useAvailability()
	const { initialize: initializeQuestionnaire } = useQuestionnaire()
	const { setFAQ } = useFAQ()
	const { initialize: initializeRecentListings } = useRecentListings()
	const [submittingForm, setSubmittingForm] = React.useState(false)
	const [error, setError] = React.useState<ErrorMessageType>()
	const [userType, setUserType] =
		React.useState<UserSignInType['type']>('agent')

	const { handleSubmit, control } = useForm<Omit<UserSignInType, 'type'>>({
		defaultValues,
	})

	const handleActiveButtonGroupChange = (value: {
		value: number
		label: string
	}) => {
		const { label: userType } = value
		setUserType(userType.toLowerCase() as UserSignInType['type'])
	}

	const handleUserSignIn = async (data: Omit<UserSignInType, 'type'>) => {
		setSubmittingForm(true)
		setError(undefined)
		const formData = {
			type: userType,
			...data,
		}
		const error = validateForm(formData, {
			type: 'User Type',
			email: 'Email Address',
			password: 'Password',
		})
		if (error) {
			setError(error)
			setSubmittingForm(false)
			return
		}

		const result = await userSignIn({
			type: formData.type,
			email: formData.email,
			password: formData.password,
		})
		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			setSubmittingForm(false)
			return
		}

		delete result.status

		setSubmittingForm(false)
		setUser(result)
		initializeAvailability(result.settings.availability)
		initializeQuestionnaire([
			result.sellerQuestionnaire,
			result.buyerQuestionnaire,
		])
		initializeRecentListings(result.settings.listingProfile.recentListings)
		setFAQ(result.settings.listingProfile.faq)
		if (!result.settings.onboarding.complete) {
			navigate('/important-stuff/onboarding')
		} else {
			navigate('/dashboard')
		}
	}

	React.useEffect(() => {
		if (error) {
			enqueueSnackbar(error.message, { variant: 'error' })
		}
		if (getUser()) {
			setUser(null)
		}
	}, [error, enqueueSnackbar])

	return (
		<Page header={<AuthHeader title="Sign In" />} centered hideAlert>
			<Box display="flex" justifyContent="center">
				<Card
					largeTitle
					rainbowFooter
					noShadowMobile
					contain={380}
					title="Please Sign In"
					subtitle="Select your account type"
					action="Sign Up!"
					actionClick={() => navigate('/sign-up')}
				>
					<ActiveButtonGroup
						options={['Agent', 'Brokerage', 'Client']}
						onChange={handleActiveButtonGroupChange}
					/>
					<form onSubmit={handleSubmit(handleUserSignIn)}>
						<TextField
							type="email"
							control={control}
							name="email"
							label="Email Address"
							error={error?.key === 'email'}
						/>
						<TextField
							type="password"
							control={control}
							name="password"
							label="Password"
							error={error?.key === 'password'}
						/>
						<Box className="mb-5 text-right">
							<Link to="/forgot-password">
								<span>Forgot Password</span>
							</Link>
						</Box>
						<Button
							type="submit"
							variant="contained"
							fullWidth
							loading={submittingForm}
						>
							Sign In
						</Button>
					</form>
				</Card>
			</Box>
		</Page>
	)
}
