import React from 'react'
import {
	Avatar,
	Button,
	TextField,
	ContentBlock,
	UploadButton,
	IconTag,
} from 'components'
import {
	AgentWebsiteSettingsType,
	ErrorMessageType,
	UploadCompleteType,
} from 'types'
import { setUser, getUser, validateForm } from 'utils'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { AgentSetSettingsData, AgentSetWebsiteSettings } from 'services'
import { DEFAULT_USER_PHOTO } from 'lib/theme'
import { isListingProfileComplete } from '../helpers'

type AgentContactType = AgentWebsiteSettingsType['user'] &
	AgentWebsiteSettingsType['contact']

export const AgentContact = () => {
	const profile = getUser()
	const { enqueueSnackbar } = useSnackbar()
	const [agentHeadshot, setAgentHeadshot] = React.useState<string>(
		profile.settings.listingProfile.photo.publicUrl || DEFAULT_USER_PHOTO
	)
	const [submittingForm, setSubmittingForm] = React.useState(false)
	const [error, setError] = React.useState<ErrorMessageType>()

	const { handleSubmit, control } = useForm<AgentContactType>({
		defaultValues: {
			first: profile.website.settings.user.first,
			last: profile.website.settings.user.last,
			phone: profile.website.settings.contact.phone,
			photo: profile.website.settings.user.photo,
			email: profile.website.settings.contact.email,
		},
	})

	const handleUploadComplete = async ({
		publicUrl,
		filename,
		key,
		context,
	}: UploadCompleteType) => {
		const newProfile = getUser()
		newProfile.settings.listingProfile.photo = {
			publicUrl,
			filename,
			key,
		}
		newProfile.settings.listingProfile.completed.photo = true
		newProfile.website.settings.user.photo = publicUrl

		const updates = [
			{
				key: 'listingProfile',
				value: newProfile.settings.listingProfile,
			},
		]

		if (isListingProfileComplete(newProfile)) {
			newProfile.settings.onboarding.steps[1].complete = true

			updates.push({
				key: 'onboarding',
				value: newProfile.settings.onboarding,
			})
		}

		const result = await AgentSetSettingsData({
			agentUuid: profile.user.uuid,
			updates,
		})

		const result2 = await AgentSetWebsiteSettings({
			settings: newProfile.website.settings,
			agentUuid: profile.user.uuid,
		})

		if (result?.status === 'success' && result2?.status === 'success') {
			enqueueSnackbar(`${context} Updated!`, {
				variant: 'success',
			})

			setUser(newProfile)
			setAgentHeadshot(publicUrl)
		} else {
			enqueueSnackbar('Error saving headshot', {
				variant: 'error',
			})
		}
	}

	const handleContactInfoUpdate = async (data: AgentContactType) => {
		setSubmittingForm(true)
		setError(undefined)
		const formData = {
			type: 'agent',
			...data,
		}
		const error = validateForm(formData, {
			first: 'First Name',
			last: 'Last Name',
			email: 'Email',
			phone: 'Phone Number',
		})

		if (error) {
			setError(error)
			setSubmittingForm(false)
			return
		}

		const newProfile = getUser()

		newProfile.website.settings.user.first = formData.first
		newProfile.website.settings.user.last = formData.last
		newProfile.website.settings.contact.email = formData.email
		newProfile.website.settings.contact.phone = formData.phone

		const result = await AgentSetWebsiteSettings({
			settings: newProfile.website.settings,
			agentUuid: profile.user.uuid,
		})

		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			setSubmittingForm(false)
			return
		}
		setUser(newProfile)
		setSubmittingForm(false)
		enqueueSnackbar('Contact Info Updated!', { variant: 'success' })
	}

	React.useEffect(() => {
		if (error) {
			enqueueSnackbar(error.message, { variant: 'error' })
		}
	}, [error, enqueueSnackbar])

	return (
		<>
			<div className="md:grid md:grid-cols-2 md:gap-4 mb-12">
				<div className="md:mr-5 max-w-[400px] mx-auto">
					<ContentBlock
						label="Professional Headshot"
						description="This will appear on your website, brokerage website (if applicable), on your scheduler, and will be visible to your clients."
						margin="6"
						tag={
							!profile.settings.listingProfile.completed.photo && (
								<IconTag
									icon="Warning"
									text="Add your headshot!"
									color="#f48225"
								/>
							)
						}
					/>
				</div>
				<div className="max-w-[180px] mt-2 md:mt-0 mx-auto">
					<Avatar src={agentHeadshot} size={124} border center />
					<UploadButton
						addText="Choose Photo"
						context="Headshot"
						fileTypes=".jpg, .png, .jpeg"
						onUploadComplete={handleUploadComplete}
						maxSize={10}
						value={profile.settings.listingProfile.photo}
						className="mt-3"
						willDisplay
					/>
				</div>
			</div>
			<div className="md:grid md:grid-cols-2 md:gap-4">
				<div className="md:mr-5 max-w-[400px] mx-auto">
					<ContentBlock
						label="My Contact Information"
						description="This is the name, phone number, and email address that is public and visible to your clients and webiste visitors. All notifications will be sent to this email address."
						margin="6"
					/>
				</div>
				<form
					onSubmit={handleSubmit(handleContactInfoUpdate)}
					className="max-w-[400px] mx-auto"
				>
					<TextField
						control={control}
						name="first"
						label="First Name"
						error={error?.key === 'first'}
					/>
					<TextField
						control={control}
						name="last"
						label="Last Name"
						error={error?.key === 'last'}
					/>
					<TextField
						phoneMask
						control={control}
						name="phone"
						label="Phone (numbers only)"
						error={error?.key === 'phone'}
					/>
					<TextField
						type="email"
						control={control}
						name="email"
						label="Email Address"
						error={error?.key === 'email'}
					/>
					<Button
						type="submit"
						variant="contained"
						fullWidth
						loading={submittingForm}
					>
						Update
					</Button>
				</form>
			</div>
		</>
	)
}
