import request from './request'

export const GetPublicWebsiteFromHash = (payload: { hash: string }) => {
	return request({
		url: '/public/get-website',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}
