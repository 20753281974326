import React from 'react'
import { PublicWebsiteFooter, PublicWebsiteHeader } from 'components'
import { getUser, setUser } from 'utils'
import { useParams } from 'react-router-dom'
import { GetPublicWebsiteFromHash } from 'services'
import {
	AgentWebsiteHome,
	AgentWebsiteAbout,
	AgentWebsiteListing,
	AgentWebsiteListings,
} from './public'
import { setPublicUser } from 'utils/localstorage/public'

export const PublicWebsite = () => {
	let data: any = getUser()
	const { hash, route, client, index } = useParams()
	const isUser = Boolean(data?.user && data?.user?.type)
	const [siteData, setSiteData] = React.useState<any>()

	React.useEffect(() => {
		getUserSiteDetails()
	}, [route])

	const getUserSiteDetails = async () => {
		if (hash) {
			const result = await GetPublicWebsiteFromHash({
				hash,
			})

			setSiteData({ ...result.website })
			setPublicUser({ ...result.website })
		}
	}

	const userHeader = {
		uuid: siteData?.userUuid,
		name: siteData?.settings?.user.first + ' ' + siteData?.settings?.user.last,
		email: siteData?.settings?.contact?.email,
		phone: siteData?.settings?.contact?.phone,
		brokerage: siteData?.settings?.user?.brokerage,
		hash,
		signedIn: isUser,
		published: siteData?.settings?.published,
		meetings: siteData?.settings?.meetings,
		availability: siteData?.settings?.availability,
	}

	const userFooter = {
		uuid: siteData?.userUuid,
		name: siteData?.settings?.user.first + ' ' + siteData?.settings?.user.last,
		brokerage: siteData?.settings?.user?.brokerage,
		photo: siteData?.settings?.user.photo,
		faq: siteData?.settings?.faq,
		meetings: siteData?.settings?.meetings,
		availability: siteData?.settings?.availability,
	}

	const baseRoute = `/u/${hash}`

	const getPage = (route: string | undefined) => {
		let page = null
		switch (route) {
			case 'listing':
				page = (
					<AgentWebsiteListing
						listings={siteData?.settings?.listings}
						client={client}
						agent={{
							name: userHeader.name,
							photo: siteData?.settings?.user.photo,
						}}
						route={baseRoute}
						index={index}
					/>
				)
				break
			case 'listings':
				page = (
					<AgentWebsiteListings
						route={baseRoute}
						listings={siteData?.settings?.listings}
						photo={siteData?.settings?.user.photo}
					/>
				)
				break
			case 'about-me':
				page = <AgentWebsiteAbout signedIn={isUser} website={siteData} />
				break
			default:
				page = (
					<AgentWebsiteHome
						route={baseRoute}
						signedIn={isUser}
						website={siteData}
					/>
				)
		}

		return page
	}

	const page = getPage(route)

	return page && siteData ? (
		<div className="badhous-public-website-bg">
			<PublicWebsiteHeader user={userHeader} />
			<div className={`${isUser ? 'pt-10' : ''} min-h-[90vh]`}>{page}</div>
			<PublicWebsiteFooter user={userFooter} />
		</div>
	) : null
}
