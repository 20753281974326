import React, { FC } from 'react'
import {
	Typography,
	Autocomplete,
	TextField,
	InputAdornment,
	useTheme,
	styled,
	useMediaQuery,
	autocompleteClasses,
	Popper,
} from '@mui/material'
import { World } from '@styled-icons/typicons'
import { setTimezone } from 'utils'
import { format, formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import { listTimeZones } from 'timezone-support'

interface Props {
	timezone?: string
	labelColor?: string
	publicViewer?: boolean
	saveToLocalStorageOnChange?: boolean
	onChange?: (tz: string) => void
}

const LISTBOX_PADDING = 8
const getLocalTime = (timezone: string) => {
	const now = new Date()
	return format(utcToZonedTime(now, timezone.replace(/ /g, '_')), 'h:mm a')
}
const humanizeTimezone = (timezone: string) => timezone.replace(/_/g, ' ')

function renderRow(props: ListChildComponentProps) {
	const { data, index, style } = props
	const dataSet = data[index]
	const inlineStyle = {
		...style,
		top: (style.top as number) + LISTBOX_PADDING,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: 4,
		padding: '16px',
	}

	let zone = dataSet[1].split('/')
	zone = zone[zone.length - 1]

	return (
		<Typography component="li" {...dataSet[0]} style={inlineStyle}>
			<span className="font-bold pr-2 line-clamp-2 break-words">{zone}</span>
			<span className="font-bold">{dataSet[2]}</span>
		</Typography>
	)
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext)
	return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
	const ref = React.useRef<VariableSizeList>(null)
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true)
		}
	}, [data])
	return ref
}

const ListboxComponent = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
	const { children, ...other } = props
	const itemData: React.ReactChild[] = []
	;(children as React.ReactChild[]).forEach(
		(item: React.ReactChild & { children?: React.ReactChild[] }) => {
			itemData.push(item)
			itemData.push(...(item.children || []))
		}
	)

	const theme = useTheme()
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
		noSsr: true,
	})
	const itemCount = itemData.length
	const itemSize = smUp ? 36 : 48

	const getChildSize = (child: React.ReactChild) => {
		if (child.hasOwnProperty('group')) {
			return 48
		}

		return itemSize
	}

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
	}

	const gridRef = useResetCache(itemCount)

	return (
		<div ref={ref} style={{ padding: '0 32px' }}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={index => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	)
})

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
		border: 'solid 1px #e5e5e5',
		boxSizing: 'border-box',

		'& ul': {
			padding: 0,
			margin: 0,
		},
	},
})

export const TimezonePicker: FC<Props> = ({
	timezone,
	labelColor = '#FFFFFF',
	publicViewer,
	saveToLocalStorageOnChange,
	onChange,
}) => {
	const [timezoneOptions, setTimezoneOptions] = React.useState<
		{ label: string; time: string }[]
	>([])
	const initialZone =
		timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
	const activeTimezone = {
		label: initialZone,
		time: getLocalTime(initialZone),
	}

	const handleChange = (
		_: React.SyntheticEvent<Element, Event>,
		option: {
			label: string
		} | null
	) => {
		if (option) {
			if (saveToLocalStorageOnChange && option) {
				setTimezone(option.label)
			}
			onChange && onChange(option.label)
		}
	}

	const getTimezoneOptions = (e?: React.MouseEvent<HTMLDivElement>) => {
		const element = e?.target as Element
		if (
			element &&
			(element.getAttribute('data-testid') === 'CloseIcon' ||
				element.tagName === 'path')
		) {
			return
		}

		const timezones = listTimeZones().map(tz => {
			return {
				label: humanizeTimezone(tz),
				time: getLocalTime(tz),
			}
		})
		setTimezoneOptions([...timezones])
	}

	React.useEffect(() => {
		getTimezoneOptions()
	}, [])

	const region = formatInTimeZone(
		new Date(),
		activeTimezone.label.replace(/ /g, '_'),
		'zzz'
	)

	return (
		<div
			onClick={getTimezoneOptions}
			className={`${
				publicViewer ? 'badhous-timezone-autocomplete' : ''
			} flex items-center w-full max-w-[420px]`}
		>
			<Autocomplete
				disableListWrap
				value={activeTimezone}
				PopperComponent={StyledPopper}
				ListboxComponent={ListboxComponent}
				options={timezoneOptions}
				onChange={handleChange}
				isOptionEqualToValue={(option, value) => option.label === value.label}
				renderInput={params => (
					<TextField
						{...params}
						label="Timezone"
						InputProps={{
							...params.InputProps,
							placeholder: 'Select your Timezone',
							startAdornment: (
								<InputAdornment
									position="start"
									style={{ position: 'relative', top: 1 }}
								>
									<World size={24} />
								</InputAdornment>
							),
						}}
						InputLabelProps={{
							style: {
								fontWeight: 600,
								fontSize: 18,
								width: 90,
								textAlign: 'center',
								background: labelColor,
								borderRight: 'solid 5px #f7f7f7',
							},
						}}
					/>
				)}
				renderOption={(props, option) =>
					[props, option.label, option.time] as any
				}
				sx={{ width: '100%' }}
				size={publicViewer ? 'small' : undefined}
			/>
			{!publicViewer && (
				<Typography className="md:mt-[-10px] ml-3 font-semibold text-black-300">
					{region}
				</Typography>
			)}
		</div>
	)
}
