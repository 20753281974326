import React from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
	ActiveButtonGroup,
	AuthHeader,
	Button,
	Card,
	Page,
	TextField,
} from 'components'
import { useForm } from 'react-hook-form'
import { ErrorMessageType, UserSignInType } from 'types'
import { getUser, setUser, validateForm } from 'utils'
import { useSnackbar } from 'notistack'
import { sendPasswordResetEmail } from 'services'

type FormData = {
	email: string
}

const defaultValues = {
	email: '',
}

export const ForgotPassword = () => {
	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const [submittingForm, setSubmittingForm] = React.useState(false)
	const [error, setError] = React.useState<ErrorMessageType>()
	const [success, setSuccess] = React.useState(false)
	const [userType, setUserType] =
		React.useState<UserSignInType['type']>('agent')

	const { handleSubmit, control } = useForm<FormData>({ defaultValues })

	const handleActiveButtonGroupChange = (value: {
		value: number
		label: string
	}) => {
		const { label: userType } = value
		setUserType(userType.toLowerCase() as UserSignInType['type'])
	}

	const handleForgotPassword = async (data: FormData) => {
		setSubmittingForm(true)
		setError(undefined)
		const formData = {
			type: userType,
			...data,
		}
		const error = validateForm(formData, {
			type: 'User Type',
			email: 'Email Address',
		})
		if (error) {
			setError(error)
			setSubmittingForm(false)
			return
		}

		const result = await sendPasswordResetEmail({
			type: formData.type,
			email: formData.email,
		})
		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			setSubmittingForm(false)
			return
		}
		enqueueSnackbar('Thanks, check your email!', { variant: 'success' })
		setSubmittingForm(false)
		setSuccess(true)
	}

	React.useEffect(() => {
		if (error) {
			enqueueSnackbar(error.message, { variant: 'error' })
		}
		if (getUser()) {
			setUser(null)
		}
	}, [error, enqueueSnackbar])

	const title = success
		? "We've emailed you a link!"
		: 'Enter your email address'

	const subtitle = success
		? ''
		: 'Select your account type. We will email you a link to reset your password immediately!'
	return (
		<Page header={<AuthHeader title="Reset Password" />} centered>
			<Box display="flex" justifyContent="center">
				<Card
					largeTitle
					rainbowFooter
					contain={400}
					title={title}
					subtitle={subtitle}
					action={success ? undefined : 'Sign In'}
					actionClick={success ? undefined : () => navigate('/dashboard')}
					actionRight={success ? undefined : 'Sign Up!'}
					actionRightClick={success ? undefined : () => navigate('/sign-up')}
				>
					{success ? (
						<div className="w-72">
							<Typography className="pb-4 font-semibold">
								A link to reset your password has been sent to your email
								address!
							</Typography>
							<Typography className="pb-4 font-semibold">
								<span className="font-bold">
									Please check your spam folders.
								</span>{' '}
								The link will expire in 4 hours, follow the instructions in the
								email at your earliest convenience.
							</Typography>
							<Button
								variant="contained"
								fullWidth
								onClick={() => navigate('/sign-in')}
							>
								I understand
							</Button>
						</div>
					) : (
						<>
							<ActiveButtonGroup
								options={['Agent', 'Brokerage', 'Client']}
								onChange={handleActiveButtonGroupChange}
							/>
							<form onSubmit={handleSubmit(handleForgotPassword)}>
								<TextField
									type="email"
									control={control}
									name="email"
									label="Email Address"
									error={error?.key === 'email'}
								/>
								<Button
									type="submit"
									variant="contained"
									fullWidth
									loading={submittingForm}
								>
									Reset Password
								</Button>
							</form>
						</>
					)}
				</Card>
			</Box>
		</Page>
	)
}
