import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import {
	Button,
	Card,
	AuthHeader,
	Page,
	TextField,
	ActiveButtonGroup,
} from 'components'
import { userCreate } from 'services'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { getUser, setUser, validateForm } from 'utils'
import { useSnackbar } from 'notistack'
import { UserSignInType, UserCreateType, ErrorMessageType } from 'types'
import { useAvailability, useQuestionnaire, useRecentListings } from 'providers'

const defaultValues = {
	first: '',
	last: '',
	email: '',
	phone: '',
	password: '',
}

export const SignUp: FC = () => {
	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const { initialize: initializeAvailability } = useAvailability()
	const { initialize: initializeQuestionnaire } = useQuestionnaire()
	const { initialize: initializeRecentListings } = useRecentListings()
	const [submittingForm, setSubmittingForm] = React.useState(false)
	const [error, setError] = React.useState<ErrorMessageType>()
	const [userType, setUserType] =
		React.useState<UserSignInType['type']>('agent')

	const { handleSubmit, control } = useForm<Omit<UserCreateType, 'type'>>({
		defaultValues,
	})

	const handleActiveButtonGroupChange = (value: {
		value: number
		label: string
	}) => {
		const { label: userType } = value
		setUserType(userType.toLowerCase() as UserSignInType['type'])
	}

	const handleUserCreate = async (data: Omit<UserCreateType, 'type'>) => {
		setSubmittingForm(true)
		setError(undefined)
		const formData = {
			type: userType,
			...data,
		}

		if (formData.phone.length && formData.phone.length < 14) {
			setError({
				key: 'phone',
				message: 'Phone number must be 10 digits',
			})
			setSubmittingForm(false)
			return
		}

		if (formData.license?.length && formData.license?.indexOf('#') >= 0) {
			setError({
				key: 'license',
				message: 'Remove the # from the license field.',
			})
			setSubmittingForm(false)
			return
		}

		let error = validateForm(formData, {
			type: 'User Type',
			first: 'First Name',
			last: 'Last Name',
			phone: 'Phone Number',
			email: 'Email',
			password: 'Password',
		})

		if (userType === 'agent') {
			error = validateForm(formData, {
				license: 'RE License #',
			})
		}

		if (error) {
			setError(error)
			setSubmittingForm(false)
			return
		}

		if (formData.password.length < 8) {
			setError({
				key: 'password',
				message: 'Password must be at least 8 characters',
			})
			setSubmittingForm(false)
			return
		}

		const result = await userCreate({
			type: formData.type,
			first: formData.first,
			last: formData.last,
			email: formData.email,
			phone: formData.phone,
			license: formData.license,
			password: formData.password,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		})

		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			setSubmittingForm(false)
			return
		}

		setUser(result)
		initializeAvailability(result.settings.availability)
		initializeQuestionnaire([
			result.sellerQuestionnaire,
			result.buyerQuestionnaire,
		])
		initializeRecentListings(result.settings.listingProfile.recentListings)
		if (!result.settings.onboarding.complete) {
			navigate('/important-stuff/onboarding')
		} else {
			navigate('/dashboard')
		}
		setSubmittingForm(false)
	}

	React.useEffect(() => {
		if (error) {
			enqueueSnackbar(error.message, { variant: 'error' })
		}
		if (getUser()) {
			setUser(null)
		}
	}, [error, enqueueSnackbar])

	return (
		<Page header={<AuthHeader title="Sign Up" />} centered hideAlert>
			<Box display="flex" justifyContent="center">
				<Card
					largeTitle
					rainbowFooter
					noShadowMobile
					contain={380}
					title="Sign Up for Pinktree!"
					subtitle="Are you an agent or brokerage?"
					action="Sign In"
					actionClick={() => navigate('/sign-in')}
				>
					<ActiveButtonGroup
						options={['Agent', 'Brokerage']}
						onChange={handleActiveButtonGroupChange}
					/>
					<form onSubmit={handleSubmit(handleUserCreate)}>
						<TextField
							control={control}
							name="first"
							label="First Name"
							error={error?.key === 'first'}
						/>
						<TextField
							control={control}
							name="last"
							label="Last Name"
							error={error?.key === 'last'}
						/>
						<TextField
							phoneMask
							control={control}
							name="phone"
							label="Phone (numbers only)"
							error={error?.key === 'phone'}
						/>
						<TextField
							type="email"
							control={control}
							name="email"
							label="Email Address"
							error={error?.key === 'email'}
						/>
						{userType === 'agent' && (
							<TextField
								control={control}
								name="license"
								label="RE License #"
								error={error?.key === 'license'}
							/>
						)}
						<TextField
							type="password"
							control={control}
							name="password"
							label="Password"
							error={error?.key === 'password'}
						/>
						<Button
							type="submit"
							variant="contained"
							fullWidth
							loading={submittingForm}
						>
							Sign Up
						</Button>
					</form>
					<Typography
						className="text-center text-xs mt-4 mx-auto"
						style={{ maxWidth: 250 }}
					>
						By signing up you acknowledge and agree to the{' '}
						<a
							href="/"
							className="anchor underline text-xs text-blue-600 font-bold"
						>
							terms of service
						</a>{' '}
						and{' '}
						<a
							href="/"
							className="anchor underline text-xs text-blue-600 font-bold text-"
						>
							privacy policy
						</a>
					</Typography>
				</Card>
			</Box>
		</Page>
	)
}
