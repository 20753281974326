import { ForceGenericObjectType } from 'types'
import { getLocalStorageItem, setLocalStorageItem } from '../'

export const LOCAL_STORAGE_KEY_VIEWER_CLIENT = '__viewer_client'

export function setClient(client: ForceGenericObjectType | null) {
	setLocalStorageItem(LOCAL_STORAGE_KEY_VIEWER_CLIENT, client)
}

export function getClient(): string | null {
	return getLocalStorageItem(LOCAL_STORAGE_KEY_VIEWER_CLIENT)
}
