import React, { FC } from 'react'
import { Box, Popover, Typography } from '@mui/material'
import { Info, InfoOutlined } from '@mui/icons-material'

interface Props {
	title: string
	description: string
	count: number
	color: string
}

export const PipelineHeader: FC<Props> = ({
	title,
	description,
	count,
	color,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>()

	return (
		<>
			<Box
				className="flex items-center justify-between border border-solid rounded-[9999px] py-2 pl-5 pr-6"
				sx={{ borderColor: color }}
			>
				<Typography className="flex items-center font-bold">
					<div
						onMouseEnter={e => setAnchorEl(e.currentTarget)}
						className="flex mr-2"
					>
						<InfoOutlined sx={{ fontSize: 18, fill: color }} />
					</div>
					{title}
				</Typography>
				<Typography className="font-black">{count}</Typography>
			</Box>
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
			>
				<div
					onClick={() => setAnchorEl(null)}
					onMouseLeave={() => setAnchorEl(null)}
					className="p-4"
				>
					<Typography className="font-semibold">{description}</Typography>
				</div>
			</Popover>
		</>
	)
}
