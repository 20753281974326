import React from 'react'
import { Box, Typography } from '@mui/material'
import { IconButton } from 'components/molecules'
import { Edit } from '@mui/icons-material'
import { QuestionnaireBlockType } from 'types'

interface Props {
	questionnaireIndex: number
	questionnaireBlock: QuestionnaireBlockType
	onEdit: (questionIndex: number) => void
}

export const QuestionnaireBlock = ({
	questionnaireIndex,
	questionnaireBlock,
	onEdit,
}: Props) => {
	const answerType = questionnaireBlock.freeResponse
		? 'Free Response'
		: 'Multiple Choice'
	const possibleAnswers = questionnaireBlock.freeResponse
		? 'Client will type their answer'
		: questionnaireBlock.answers.join(', ')

	return (
		<Box className="flex items-start w-full">
			<Typography className="font-black mr-4 mt-4 text-gray-600 w-5">
				{questionnaireIndex + 1}
			</Typography>
			<Box className="w-full">
				<div className="badhous-questionnaire-question bg-gray-200 py-3 pl-14 pr-4 rounded-sm">
					<Typography className="font-semibold">
						{questionnaireBlock.question}
					</Typography>
				</div>
				<div className="m_md:hidden md:flex items-center p-2">
					<Typography className="font-semibold whitespace-nowrap">
						{answerType} Answer:
					</Typography>
					<Typography className="font-bold ml-2 line-clamp-1 pr-2 text-sm">
						{possibleAnswers}
					</Typography>
				</div>
			</Box>
			<Box className="ml-2 mt-1">
				<IconButton
					tooltip="Edit Question"
					onClick={() => onEdit(questionnaireIndex)}
					noBorder
				>
					<Edit />
				</IconButton>
			</Box>
		</Box>
	)
}
