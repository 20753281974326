import React from 'react'
import { CalendarMonth, East, West } from '@mui/icons-material'
import { Typography, useTheme } from '@mui/material'
import {
	addMinutes,
	differenceInDays,
	isPast,
	isToday,
	isTomorrow,
} from 'date-fns'
import { format } from 'date-fns-tz'
import { getUser, setUser, useDebounce } from 'utils'
import {
	ForceGenericObjectType,
	LeadConversionBlockType,
	LeadConversionIndexType,
	LeadConversionLabelEnum,
	LeadConversionType,
} from 'types'
import { AgentSendMeetingLinkToLead, AgentSetLead } from 'services'
import { useLeads } from 'providers'
import { useSnackbar } from 'notistack'

const profile = getUser()

export const getScheduleLabel = (date: Date) => {
	const icon = <CalendarMonth sx={{ marginRight: 1 }} />
	if (isToday(date)) {
		return (
			<Typography className="badhous-schedule-label flex items-center justify-center">
				{icon}Today
			</Typography>
		)
	} else if (isTomorrow(date)) {
		return (
			<Typography className="badhous-schedule-label flex items-center justify-center">
				{icon}Tomorrow
			</Typography>
		)
	} else {
		return (
			<Typography className="badhous-schedule-label flex items-center justify-center">
				{icon}
				{format(date, 'eee, MMM do')}
			</Typography>
		)
	}
}

export const handleMoveDirection = (source: string, destination: string) => {
	const start = source === 'prospects' ? 0 : source === 'scheduled' ? 1 : 2
	const end =
		destination === 'prospects' ? 0 : destination === 'scheduled' ? 1 : 2

	if (end > start) {
		return true
	}
	return false
}

export const useWindowWidth = (offset: number = 0) => {
	function reportWindowWidth() {
		return window.innerWidth
	}

	window.onresize = reportWindowWidth
	let debouncedWidth = useDebounce(reportWindowWidth, 4000)

	if (debouncedWidth === undefined || debouncedWidth <= 1094) {
		return window.innerWidth
	}

	return debouncedWidth - offset
}

export const getEnabledAssistantTabs = (
	leads: LeadConversionType
): boolean[] => {
	const prospectsEnabled = leads.prospects.length
		? leads.prospects.some((lead: LeadConversionBlockType) => {
				return (
					differenceInDays(new Date(), new Date(lead.schedulingAttempt)) >= 3
				)
		  })
		: false

	const meetingsEnabled = leads.scheduled.length
		? leads.scheduled.some((lead: LeadConversionBlockType) => {
				return isPast(
					addMinutes(
						new Date(lead.meeting.date),
						profile.settings.availability.stepInMinutes
					)
				)
		  })
		: false

	const contractEnabled = leads.waiting.length
		? leads.waiting.some((lead: LeadConversionBlockType) => {
				return differenceInDays(new Date(), new Date(lead.followUpAttempt)) >= 7
		  })
		: false

	return [prospectsEnabled, meetingsEnabled, contractEnabled]
}

export const updateLead = async (
	leadUuid: string,
	updates: ForceGenericObjectType[]
) => {
	const result = await AgentSetLead({
		agentUuid: profile.user.uuid,
		leads: [
			{
				leadUuid,
				updates,
			},
		],
	})

	return result
}

export const deleteLeadByUuid = async (leadUuid: string) => {
	const result = await AgentSetLead({
		agentUuid: profile.user.uuid,
		deleteUuid: leadUuid,
	})

	return result
}

export const sendMeetingLink = async (
	lead: LeadConversionBlockType,
	updates?: ForceGenericObjectType[]
) => {
	const allUpdates = [
		{
			key: 'schedulingAttempt',
			value: new Date(),
		},
	] as ForceGenericObjectType[]

	if (updates) {
		allUpdates.push(...updates)
	}

	const result = await AgentSendMeetingLinkToLead({
		agentUuid: profile.user.uuid,
		agentName: profile.user.first + ' ' + profile.user.last,
		leads: [
			{
				leadUuid: lead.uuid,
				leadName: lead.name,
				leadEmail: lead.email,
				updates: allUpdates,
			},
		],
	})

	return result
}
