import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Button, IconButton, VideoPlayer } from 'components'
import { Typography } from '@mui/material'
import { Article } from '@mui/icons-material'
import {
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
	PinterestIcon,
} from 'react-share'
import { INSTAGRAM_PNG, ZILLOW_PNG } from 'lib/theme'

interface Props {
	signedIn: boolean
	website: any
}

export const AgentWebsiteAbout = ({ signedIn, website }: Props) => {
	const navigate = useNavigate()
	const { settings, type, userUuid } = website
	const {
		contact: { facebook, instagram, twitter, linkedin, zillow, pinterest },
		welcomeVideo,
	} = settings

	return (
		<>
			<div className="mt-16 py-[48px] md:py-[64px] badhous-public-website-hero">
				<div className="max-w-[1350px] mx-auto px-4 md:px-10">
					<div className="md:flex items-center justify-center">
						<Avatar size={120} src={settings.user.photo} />
						<div className="ml-8">
							<Typography className="max-w-[475px] font-black text-[28px] leading-[30px] mb-2">
								{settings.user.first + ' ' + settings?.user.last}
							</Typography>
							<a
								href={`tel:+1${settings.contact.phone}`}
								className="block max-w-[475px] font-bold text-[22px] mb-1"
								style={{ textDecoration: 'none ' }}
							>
								{settings.contact.phone}
							</a>
							<a
								href={`mailto:${settings.contact.email}`}
								className="block max-w-[475px] font-bold text-[22px] ml-[2px]"
								style={{ textDecoration: 'none ' }}
							>
								{settings.contact.email}
							</a>
						</div>
					</div>
					<div className="mt-12 max-w-[680px] mx-auto">
						{welcomeVideo ? (
							<div className="rounded-3xl overflow-hidden">
								<VideoPlayer id="about-me" src={welcomeVideo} autoFit />
							</div>
						) : null}
						<div className="flex justify-center border-t border-solid border-gray-200 pt-10 mt-10 mb-10">
							<Button
								variant="outlined"
								startIcon={
									<Article
										color="secondary"
										sx={{ fontSize: '24px !important' }}
									/>
								}
								className="ml-3"
							>
								View Listing Presentation
							</Button>
						</div>
						<div className="flex items-center justify-between w-[290px] mx-auto mb-4">
							<IconButton
								tooltip="Visit My Facebook"
								noBorder
								onClick={() => window.open(facebook, '_blank')}
							>
								<FacebookIcon size={36} round />
							</IconButton>
							<IconButton
								tooltip="Visit My Instagram"
								noBorder
								onClick={() => window.open(instagram, '_blank')}
							>
								<img
									src={INSTAGRAM_PNG}
									alt="Instagram Logo"
									style={{ width: 32 }}
								/>
							</IconButton>
							<IconButton
								tooltip="Visit My Twitter"
								noBorder
								onClick={() => window.open(twitter, '_blank')}
							>
								<TwitterIcon size={36} round />
							</IconButton>
							<IconButton
								tooltip="Visit My LinkedIn"
								noBorder
								onClick={() => window.open(linkedin, '_blank')}
							>
								<LinkedinIcon size={36} round />
							</IconButton>
							<IconButton
								tooltip="Visit My Zillow"
								noBorder
								onClick={() => window.open(zillow, '_blank')}
							>
								<img src={ZILLOW_PNG} alt="Zillow Logo" style={{ width: 32 }} />
							</IconButton>
							<IconButton
								tooltip="Visit My Pinterest"
								noBorder
								onClick={() => window.open(pinterest, '_blank')}
							>
								<PinterestIcon size={36} round />
							</IconButton>
						</div>
						<Typography className="font-semibold">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
							sit amet ultricies lorem. Ut luctus elementum justo, quis
							tincidunt nisl efficitur sed. Proin nec leo nec risus dignissim
							venenatis a at nulla. Nulla sodales nec erat egestas lobortis. In
							id orci at est eleifend placerat. Nunc bibendum urna hendrerit,
							sodales urna eget, fermentum nisi. Nullam orci enim, molestie id
							placerat ut, porta sed urna. Ut commodo porttitor sem, quis
							aliquet urna. Nulla facilisi. Praesent eget interdum felis, sit
							amet mattis mauris. Aliquam ultrices felis dapibus ante rhoncus
							ultrices. Etiam rhoncus leo enim, non faucibus nisl efficitur etc
						</Typography>
					</div>
				</div>
			</div>
		</>
	)
}
