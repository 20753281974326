import React from 'react'
import { Button, TextField, ContentBlock } from 'components'
import { Box } from '@mui/material'
import { ErrorMessageType, UserAgentType } from 'types'
import { setUser, getUser, validateForm } from 'utils'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { userUpdate } from 'services'

interface Props {
	account: UserAgentType
}

export const AgentAccount = ({ account }: Props) => {
	const profile = getUser()
	const { enqueueSnackbar } = useSnackbar()
	const [submittingForm, setSubmittingForm] = React.useState(false)
	const [error, setError] = React.useState<ErrorMessageType>()

	const { handleSubmit, control } = useForm<Omit<UserAgentType, 'type'>>({
		defaultValues: {
			uuid: account.uuid,
			first: account.first,
			last: account.last,
			email: account.email,
			phone: account.phone,
		},
	})

	const handleUserUpdate = async (data: Omit<UserAgentType, 'type'>) => {
		setSubmittingForm(true)
		setError(undefined)
		const formData = {
			type: 'agent',
			...data,
		}

		if (formData.phone.length && formData.phone.length < 14) {
			setError({
				key: 'phone',
				message: 'Phone number must be 10 digits',
			})
			setSubmittingForm(false)
			return
		}

		const error = validateForm(formData, {
			first: 'First Name',
			last: 'Last Name',
			email: 'Email',
			phone: 'Phone Number',
		})

		if (error) {
			setError(error)
			setSubmittingForm(false)
			return
		}

		const result = await userUpdate({
			uuid: account.uuid,
			type: formData.type,
			first: formData.first,
			last: formData.last,
			email: `${account.email}::${formData.email}`,
			phone: formData.phone,
		})

		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			setSubmittingForm(false)
			return
		}

		setUser({
			...profile,
			user: {
				...profile.user,
				first: formData.first,
				last: formData.last,
				email: formData.email,
				phone: formData.phone,
			},
		})
		setSubmittingForm(false)
		enqueueSnackbar('Account Updated!', { variant: 'success' })
	}

	React.useEffect(() => {
		if (error) {
			enqueueSnackbar(error.message, { variant: 'error' })
		}
	}, [error, enqueueSnackbar])

	return (
		<Box className="pt-[10px] mx-auto max-w-[400px] md:mx-0 md:max-w-full">
			<div className="md:grid md:grid-cols-2 md:gap-4">
				<div className="md:mr-5 max-w-[400px]">
					<ContentBlock
						label="Personal Information"
						description="This information will not be visible to anyone but you. The contact
						information for your website can be found in your"
						margin="8"
						link={{
							path: '/important-stuff/my-profile',
							text: 'profile.',
						}}
					/>
				</div>
				<form
					onSubmit={handleSubmit(handleUserUpdate)}
					className="max-w-[400px]"
				>
					<TextField
						control={control}
						name="first"
						label="First Name"
						error={error?.key === 'first'}
					/>
					<TextField
						control={control}
						name="last"
						label="Last Name"
						error={error?.key === 'last'}
					/>
					<TextField
						phoneMask
						control={control}
						name="phone"
						label="Phone (numbers only)"
						error={error?.key === 'phone'}
					/>
					<TextField
						type="email"
						control={control}
						name="email"
						label="Email Address"
						error={error?.key === 'email'}
					/>
					<Button
						type="submit"
						variant="contained"
						fullWidth
						loading={submittingForm}
					>
						Update
					</Button>
				</form>
			</div>
		</Box>
	)
}
