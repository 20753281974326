import React from 'react'
import { AddNewButton, ContentBlock, IconTag } from 'components'
import { useRecentListings } from 'providers'
import { RecentListingCard } from './recent-listing-card'
import { getUser } from 'utils'

const emptyListing = {
	line1: '',
	line2: '',
	city: '',
	state: '',
	zip: '',
	closePrice: '',
	timeOnMarket: '',
}

export const RecentListings = () => {
	const profile = getUser()
	const { recentListings, setRecentListings } = useRecentListings()

	const handleAddNew = () => {
		setRecentListings(recentListings.length, emptyListing)
	}

	return (
		<div className="pt-2 mx-auto max-w-[640px] md:mx-0 md:max-w-[808px]">
			<div className="mb-8 max-w-[600px]">
				<ContentBlock
					label="Recently Closed Listings"
					description="Add up to 3 listings you recently closed. Address, close price, and the time on the market. This will help you stand out to potential clients!"
					tag={
						!profile.settings.listingProfile.completed.recentListings && (
							<IconTag
								icon="Warning"
								text="Add recent listings!"
								color="#f48225"
							/>
						)
					}
				/>
			</div>
			<div className="mb-6">
				{recentListings.length
					? recentListings.map((recentListing, index) => {
							return (
								<RecentListingCard
									key={`${recentListing.line1}-${recentListing.zip}-${index}`}
									recentListingIndex={index}
									recentListing={recentListing}
									hideRemove={Boolean(!recentListing.line1)}
								/>
							)
					  })
					: null}
				{!recentListings.length && (
					<RecentListingCard
						recentListingIndex={0}
						recentListing={emptyListing}
						hideRemove
					/>
				)}
			</div>
			{recentListings.length < 3 && (
				<AddNewButton
					text={`Add ${recentListings.length ? 'another' : 'a'} Listing`}
					onClick={handleAddNew}
				/>
			)}
		</div>
	)
}
