import React, { FC } from 'react'
import {
	Card as MuiCard,
	Box,
	Typography,
	BoxProps,
	useMediaQuery,
	useTheme,
} from '@mui/material'
import { CardFooterButton } from './card-footer-button'

interface Props extends BoxProps {
	title?: string
	subtitle?: string
	flat?: boolean
	flatSides?: boolean
	largeTitle?: boolean
	rainbowFooter?: boolean
	thin?: boolean
	contain?: number | string
	marginBottom?: number
	underline?: boolean
	noGutter?: boolean
	noShadowMobile?: boolean
	action?: string
	actionClick?: () => void
	actionRight?: string
	actionRightClick?: () => void
}

export const Card: FC<Props> = ({
	title,
	subtitle,
	flat,
	flatSides,
	largeTitle,
	rainbowFooter,
	thin,
	contain = '100%',
	marginBottom = 0,
	underline,
	noGutter,
	noShadowMobile,
	action,
	actionClick,
	actionRight,
	actionRightClick,
	children,
}) => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const borderStyle = `border-solid border-gray-200`
	const titleClass = `font-bold ${thin ? 'pt-5' : 'pt-8'} px-4 md:px-8 ${
		underline
			? `${largeTitle ? 'text-lg' : ''} pb-4 mb-2 border-b ${borderStyle}`
			: `${largeTitle ? 'text-lg' : ''}`
	}`
	const subtitleClass = 'font-semibold px-4 md:px-8 mb-2 text-gray-600'
	const footerClass = `relative mt-2 flex items-center ${
		actionRight ? 'justify-between' : 'justify-center'
	}${action ? ` border-t ${borderStyle}` : ''} ${
		rainbowFooter ? 'badhous-rainbow-card-border' : ''
	}`

	return (
		<MuiCard
			className={noShadowMobile && isMobile ? 'shadow-md' : 'shadow-lg'}
			sx={{ maxWidth: contain, width: contain ? '100%' : 'auto', marginBottom }}
		>
			{flat ? (
				<Box className={noGutter ? 'pt-4' : 'p-4'}>{children}</Box>
			) : (
				<>
					{title && <Typography className={titleClass}>{title}</Typography>}
					{subtitle && (
						<Typography className={subtitleClass}>{subtitle}</Typography>
					)}
					<Box
						className={`${action ? 'py-4' : 'pt-4 pb-8'} ${
							!flatSides ? (isMobile ? 'px-4' : 'px-8') : 'px-0'
						} ${noGutter ? 'px-0 pb-0' : ''}`}
					>
						{children}
					</Box>
					{action && (
						<Box className={footerClass}>
							<CardFooterButton onClick={actionClick} fullWidth>
								{action}
							</CardFooterButton>
							{actionRight && (
								<CardFooterButton
									className="border-l border-solid border-gray-200"
									onClick={actionRightClick}
									fullWidth
								>
									{actionRight}
								</CardFooterButton>
							)}
						</Box>
					)}
				</>
			)}
		</MuiCard>
	)
}
