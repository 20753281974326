import React from 'react'
import uuid from 'react-uuid'
import { ActiveButtonGroup, Button, IconButton, AddNewButton } from 'components'
import {
	ErrorMessageType,
	QuestionnaireType,
	QuestionnaireBlockType,
	QuestionnaireEnum,
} from 'types'
import {
	setUser,
	getUser,
	validateForm,
	reactKeyedValue,
	getReactKeyedValues,
} from 'utils'
import { useSnackbar } from 'notistack'
import { AgentSetQuestionnaire, AgentSetWebsiteSettings } from 'services'
import { Typography, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useDialog } from 'providers'

interface Props {
	questionnaireType: QuestionnaireEnum
	questionnaire: QuestionnaireType
	update?: boolean
	questionIndex?: number
	onSave: (
		index: number,
		question: QuestionnaireBlockType,
		remove?: boolean
	) => void
}

export const AddEditQuestion = ({
	questionnaireType,
	questionnaire: savedQuestionnaire,
	update,
	questionIndex = 0,
	onSave,
}: Props) => {
	const profile = getUser()
	const { enqueueSnackbar } = useSnackbar()
	const { setDialogClosed } = useDialog()
	const [question, setQuestion] = React.useState(
		update ? savedQuestionnaire?.[questionIndex]?.question : ''
	)
	const [multipleChoice, setMultipleChoice] = React.useState({
		status: update ? !savedQuestionnaire?.[questionIndex]?.freeResponse : false,
		answers: update
			? getReactKeyedValues(savedQuestionnaire?.[questionIndex]?.answers || [])
			: [reactKeyedValue()],
	})

	const [submittingForm, setSubmittingForm] = React.useState(false)
	const [error, setError] = React.useState<ErrorMessageType>()

	const handleQuestionChange = (value: string) => {
		setQuestion(value)
	}

	const handleAnswerChange = (value: string, index: number) => {
		const newMultipleChoice = [...multipleChoice.answers]
		newMultipleChoice.splice(index, 1, {
			...newMultipleChoice[index],
			value,
		})

		setMultipleChoice({
			...multipleChoice,
			answers: newMultipleChoice,
		})
	}

	const handleAddOption = () => {
		setMultipleChoice({
			...multipleChoice,
			answers: [...multipleChoice.answers, reactKeyedValue()],
		})
	}

	const handleRemoveOption = (optionIndex: number) => {
		const newAnswers = [...multipleChoice.answers]
		newAnswers.splice(optionIndex, 1)

		setMultipleChoice({
			...multipleChoice,
			answers: newAnswers,
		})
	}

	const handleDeleteQuestion = async () => {
		if (update) {
			const newQuestionnaire = [...savedQuestionnaire]
			newQuestionnaire.splice(questionIndex, 1)
			const questionnaire = [...newQuestionnaire]

			if (await saveQuestionnaire(questionnaire)) {
				onSave(questionIndex, savedQuestionnaire[questionIndex], true)
			}
		}
	}

	const handleSaveQuestion = async (event: any) => {
		event.preventDefault()
		setSubmittingForm(true)
		setError(undefined)

		let questionKey = `question-${questionIndex}`
		const answers = multipleChoice.answers.map(item => item.value)

		const error = validateForm(
			{ [questionKey]: question },
			{
				[questionKey]: 'Question',
			}
		)

		if (error) {
			setError(error)
			setSubmittingForm(false)
			return
		}

		let questionnaire
		if (update) {
			questionnaire = [...savedQuestionnaire]
			questionnaire.splice(questionIndex, 1, {
				freeResponse: !multipleChoice.status,
				question,
				answers,
			})
		} else {
			questionnaire = [
				...savedQuestionnaire,
				{
					freeResponse: !multipleChoice.status,
					question,
					answers,
				},
			]
		}

		if (await saveQuestionnaire(questionnaire)) {
			onSave(questionIndex, {
				freeResponse: !multipleChoice.status,
				question,
				answers,
			})
		}
	}

	const saveQuestionnaire = async (newQuestionnaire: QuestionnaireType) => {
		const newProfile = getUser()
		newProfile.website.settings[questionnaireType] = newQuestionnaire

		const result = await AgentSetQuestionnaire({
			type: questionnaireType,
			questionnaire: newQuestionnaire,
			agentUuid: profile.user.uuid,
		})

		const result2 = await AgentSetWebsiteSettings({
			agentUuid: profile.user.uuid,
			settings: newProfile.website.settings,
		})

		if (result.error || result2.error) {
			enqueueSnackbar(result.error || result2.error, { variant: 'error' })
			setSubmittingForm(false)
			return false
		}
		setUser({
			...profile,
			[questionnaireType]: newQuestionnaire,
		})
		setSubmittingForm(false)
		setDialogClosed()
		return true
	}

	type valueType = {
		value: number
		label: string
	}
	const handleActiveButtonGroupChange = (data: valueType) => {
		setMultipleChoice({
			...multipleChoice,
			status: Boolean(data.value),
		})
	}

	return (
		<div className="px-4 py-6 mx-auto md:w-[400px]" style={{ maxWidth: 400 }}>
			<form onSubmit={handleSaveQuestion}>
				<TextField
					name={`question-${uuid()}`}
					label="Question"
					defaultValue={question}
					sx={{ width: '100%' }}
					onChange={event => handleQuestionChange(event.target.value)}
					error={error?.key === `question-${questionIndex}`}
					autoFocus
				/>
				<div className="mb-5 mx-2">
					<Typography className="font-bold mt-3 pb-2 mb-3 border-b border-solid border-gray-200">
						Answer Type
					</Typography>
					<ActiveButtonGroup
						activeIndex={multipleChoice.status ? 1 : 0}
						options={['Free Response', 'Multiple Choice']}
						onChange={handleActiveButtonGroupChange}
					/>
					<div
						className={`mb-10 ${
							multipleChoice.status
								? `pl-9 mr-[-8px] pt-2 ${
										multipleChoice?.answers?.length < 2 ? 'pr-[44px]' : ''
								  }`
								: ''
						} `}
					>
						<>
							{multipleChoice.status &&
								multipleChoice?.answers?.map((answer, index) => {
									return (
										<div key={answer.key} className="flex items-center">
											<TextField
												name={answer.key}
												label={`Option ${index + 1}`}
												defaultValue={answer.value}
												onChange={event =>
													handleAnswerChange(event.target.value, index)
												}
												sx={{ width: '100%' }}
											/>
											<div>
												<div
													className={
														multipleChoice?.answers.length > 1
															? 'w-[40px] ml-1'
															: ''
													}
												></div>
												{index > 0 && (
													<IconButton
														tooltip="Remove Option"
														noBorder
														onClick={() => handleRemoveOption(index)}
														className="mb-3 ml-1"
													>
														<Close />
													</IconButton>
												)}
											</div>
										</div>
									)
								})}
						</>
						{!multipleChoice.status && (
							<Typography className="font-bold text-center p-2 bg-gray-200 rounded-lg">
								The client will type their answer
							</Typography>
						)}
						{multipleChoice.status && multipleChoice?.answers?.length < 4 && (
							<div
								className={
									multipleChoice?.answers?.length > 1 ? 'mr-[44px]' : ''
								}
							>
								<AddNewButton text="Add Option" onClick={handleAddOption} />
							</div>
						)}
					</div>
				</div>
				<Button
					type="submit"
					variant="contained"
					fullWidth
					loading={submittingForm}
				>
					{update ? 'Update Question' : 'Add Question'}
				</Button>
				{update && (
					<Button
						className="mt-2"
						fullWidth
						loading={submittingForm}
						onClick={handleDeleteQuestion}
					>
						Delete Question
					</Button>
				)}
			</form>
		</div>
	)
}
