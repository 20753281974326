import React from 'react'
import { Typography } from '@mui/material'
import { differenceInDays } from 'date-fns'
import {
	AgentLeadActionEnum,
	LeadConversionBlockType,
	LeadConversionType,
} from 'types'
import { Button, LeadCard, PopoverButton } from 'components'
import { useLeads } from 'providers'
import { updateLead } from '../helpers'
import { useSnackbar } from 'notistack'
import { EMOJI_FIRE } from 'lib/theme'

export const HotContracts = () => {
	const { leads, setLeads } = useLeads()
	const { enqueueSnackbar } = useSnackbar()
	const [contractsWaiting, setContractsWaiting] = React.useState<
		LeadConversionBlockType[]
	>([])

	const handleLeadAction = async (
		action: AgentLeadActionEnum,
		contractWaitingIndex: number,
		leadIndex: number
	) => {
		const newLeads = { ...leads }
		const lead = contractsWaiting[contractWaitingIndex]

		switch (action) {
			case AgentLeadActionEnum.FOLLOW_UP:
				newLeads.waiting[leadIndex].followUpAttempt = new Date()
				setFollowUp(lead, newLeads)
				break
			case AgentLeadActionEnum.ARCHIVE:
				newLeads.waiting.splice(lead.index, 1)
				newLeads.archived.push({
					...lead,
				})
				archiveLead(lead, newLeads)
				break
		}
	}

	const setFollowUp = async (
		lead: LeadConversionBlockType,
		newLeads: LeadConversionType
	) => {
		await updateLead(lead.uuid, [
			{
				key: 'followUpAttempt',
				value: new Date(),
			},
		])

		setLeads(newLeads)
		enqueueSnackbar(`7 Day Reminder Set for ${lead.name.split(' ')[0]}!`, {
			variant: 'success',
		})
	}

	const archiveLead = async (
		lead: LeadConversionBlockType,
		newLeads: LeadConversionType
	) => {
		await updateLead(lead.uuid, [
			{
				key: 'archived',
				value: 1,
			},
		])

		setLeads(newLeads)
		enqueueSnackbar(`${lead.name.split(' ')[0]} Moved to Archive`, {
			variant: 'success',
		})
	}

	React.useEffect(() => {
		const contractWaitingArr: LeadConversionBlockType[] = []

		leads.waiting.forEach((lead, index) => {
			if (differenceInDays(new Date(), new Date(lead.followUpAttempt)) >= 7) {
				contractWaitingArr.push({ ...lead, index })
			}
		})

		setContractsWaiting(contractWaitingArr)
	}, [leads])

	return (
		<div className="overflow-y-auto badhous-dialog-content-body">
			<Typography className="font-bold text-xl text-center">
				Let's Get Cooking! {EMOJI_FIRE}
			</Typography>
			<Typography className="sm:text-lg mt-2 font-semibold text-center">
				These leads should become your clients. Follow Up!
			</Typography>
			<div className="m_xmd:mt-6 mt-4 xmd:flex items-center flex-wrap max-w-[560px] mx-auto">
				{contractsWaiting.map((lead, index) => {
					return (
						<LeadCard
							key={lead.uuid}
							leadType={lead.type}
							name={lead.name}
							subtext={[lead.phone, lead.email]}
							className={
								'xmd:even:ml-2 w-[275px] max-w-[275px] mb-2 m_xmd:mx-auto'
							}
						>
							<Typography className="font-bold text-center mb-4">
								It's been{' '}
								{differenceInDays(new Date(), new Date(lead.followUpAttempt))}{' '}
								days!
							</Typography>
							<PopoverButton
								customButton={
									<Button
										className="block bg-gray-200 hover:bg-[#daf6db] rounded-lg mb-2"
										fullWidth
									>
										I'll Follow Up
									</Button>
								}
								onPromptButtonClick={() =>
									handleLeadAction(
										AgentLeadActionEnum.FOLLOW_UP,
										index,
										lead.index
									)
								}
							>
								<div className="p-4 pb-2 max-w-[280px]">
									<Typography className="text-lg font-bold">
										Make sure you do!
									</Typography>
									<Typography className="mt-2 font-semibold">
										Consider calling, texting, or sending an email and try to
										get your contract signed!
									</Typography>
								</div>
							</PopoverButton>
							<PopoverButton
								customButton={
									<Button
										className="block bg-gray-200 hover:bg-gray-300 rounded-lg mb-2"
										fullWidth
									>
										Archive
									</Button>
								}
								onPromptButtonClick={() =>
									handleLeadAction(
										AgentLeadActionEnum.ARCHIVE,
										index,
										lead.index
									)
								}
							>
								<div className="p-4 pb-2 max-w-[280px]">
									<Typography className="text-lg font-bold">
										Are you sure?
									</Typography>
									<Typography className="mt-2 font-semibold">
										Archiving this lead will remove it from your Contract
										Waiting pipeline. You'll be able to find{' '}
										<span className="font-black">{lead.name}</span> in your
										archives.
									</Typography>
								</div>
							</PopoverButton>
						</LeadCard>
					)
				})}
			</div>
		</div>
	)
}
