import React from 'react'
import { AppHeader, IconButton, Page } from 'components'
import { getUser } from 'utils'
import { useNavigate } from 'react-router-dom'
import { LeadConversionPipeline, LeadArchive } from 'views'
import { Notifications } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import { useDialog, useLeads } from 'providers'
import { AssistantMenu } from 'views/agent/lead-conversion/views'
import { getEnabledAssistantTabs } from 'views/agent/lead-conversion/helpers'

export const LeadConversion = () => {
	const navigate = useNavigate()
	let data = getUser() || {}
	const theme = useTheme()
	const { leads, getLeads } = useLeads()
	const { setDialogOpen } = useDialog()
	const enabledTabs = getEnabledAssistantTabs(leads)

	React.useEffect(() => {
		if (!data.user) {
			navigate('/sign-in')
		}
	}, [data, navigate])

	if (!data.user) {
		return null
	}

	let header = <AppHeader first={data.user.first} type={data.user.type} />

	const handleOpenAssistant = () => {
		setDialogOpen({
			title: 'Pinktree AI Assistant',
			colorDot: theme.palette.secondary.main,
			content: <AssistantMenu />,
			showClose: true,
		})
	}

	React.useEffect(() => {
		getLeads()
	}, [])

	const Assistant =
		enabledTabs.indexOf(true) >= 0 ? (
			<div className="badhous-pulse" style={{ margin: '0 0 0 auto' }}>
				<IconButton
					tooltip="Important Notifications!"
					onClick={handleOpenAssistant}
				>
					<Notifications sx={{ fill: '#ff0083 !important' }} />
				</IconButton>
			</div>
		) : undefined

	return (
		<Page
			header={header}
			sidebar={data.user.type}
			title="Lead Conversion Assistant"
			menuRight={Assistant}
		>
			<LeadConversionPipeline leads={leads} enabledTabs={enabledTabs} />
			<LeadArchive leads={leads} />
		</Page>
	)
}
