import React, { FC } from 'react'
import { Box, Typography, useTheme, alpha } from '@mui/material'

interface Props {
	label: string
	color?: 'blue' | 'green' | 'red'
}

export const ColorChip: FC<Props> = ({ label, color: themeColor }) => {
	const theme = useTheme()

	let color = theme.palette.blue.main
	switch (themeColor) {
		case 'green':
			color = theme.palette.green.main
			break
		case 'red':
			color = theme.palette.red.main
			break
	}

	return (
		<Box
			className="rounded-full px-4 inline-block"
			style={{ backgroundColor: alpha(color, 0.15) }}
		>
			<Typography className="font-semibold leading-8" style={{ color }}>
				{label}
			</Typography>
		</Box>
	)
}
