import React from 'react'

interface Props {
	color: string
}

export const ColorDot = ({ color }: Props) => {
	return (
		<span
			className="block rounded-full mr-3 h-[14px] w-[14px]"
			style={{ backgroundColor: color }}
		></span>
	)
}
