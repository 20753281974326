import React from 'react'
import { AppHeader, Page } from 'components'
import { getUser } from 'utils'
import { useNavigate } from 'react-router-dom'

export const ActiveClients = () => {
	const navigate = useNavigate()
	let data = getUser() || {}

	let header = <AppHeader first={data.user.first} type={data.user.type} />

	React.useEffect(() => {
		if (!data.user) {
			navigate('/sign-in')
		}
	}, [data, navigate])

	if (!data.user) {
		return null
	}

	return (
		<Page header={header} sidebar={data.user.type} title="Active Clients">
			<div></div>
		</Page>
	)
}
