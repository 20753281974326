import React, { FC } from 'react'
import DraftEditor from '@draft-js-plugins/editor'
import createLinkPlugin from '@draft-js-plugins/anchor'
import { EditorState } from 'draft-js'
import '@draft-js-plugins/emoji/lib/plugin.css'
import '@draft-js-plugins/static-toolbar/lib/plugin.css'
import '@draft-js-plugins/anchor/lib/plugin.css'
import editorStyles from '../../lib/text-editor-styles/editor.module.css'
import toolbarStyles from '../../lib/text-editor-styles/toolbar.module.css'
import linkStyles from '../../lib/text-editor-styles/link.module.css'
import buttonStyles from '../../lib/text-editor-styles/button.module.css'
import createToolbarPlugin from '@draft-js-plugins/static-toolbar'
import {
	ItalicButton,
	BoldButton,
	UnderlineButton,
	UnorderedListButton,
	OrderedListButton,
	BlockquoteButton,
	CodeBlockButton,
} from '@draft-js-plugins/buttons'

const linkPlugin = createLinkPlugin({
	theme: linkStyles as any,
	placeholder: 'https://…',
	linkTarget: '_blank',
})

export interface TextEditorProps {
	state?: EditorState | null
	height?: string | number
	placeholder?: string
	onChange: (state: EditorState) => void
	onFocus?: () => void
	readOnly?: boolean
	testId?: string
	maxHeight?: string
}

export const TextEditor: FC<TextEditorProps> = ({
	state,
	height = 250,
	placeholder,
	onChange,
	onFocus,
	readOnly,
	testId,
	maxHeight,
}) => {
	const [{ plugins, Toolbar }] = React.useState(() => {
		const toolbarPlugin = createToolbarPlugin({
			theme: { toolbarStyles, buttonStyles },
		})
		const { Toolbar } = toolbarPlugin
		const plugins = [toolbarPlugin, linkPlugin]
		return {
			plugins,
			Toolbar,
		}
	})

	return (
		<div className={editorStyles.editor} style={{ height }}>
			<Toolbar>
				{externalProps => (
					<div>
						<BoldButton {...externalProps} />
						<ItalicButton {...externalProps} />
						<UnderlineButton {...externalProps} />
						<UnorderedListButton {...externalProps} />
						<OrderedListButton {...externalProps} />
						<BlockquoteButton {...externalProps} />
						<CodeBlockButton {...externalProps} />
						<linkPlugin.LinkButton {...(externalProps as any)} />
					</div>
				)}
			</Toolbar>
			<div style={{ maxHeight: maxHeight, overflow: 'auto' }}>
				<DraftEditor
					/* https://github.com/facebook/draft-js/issues/325#issuecomment-365304318 **/
					/* webDriverTestID gets converted to `data-testid` in DOM */
					webDriverTestID={testId}
					readOnly={readOnly}
					editorState={state || undefined}
					onChange={onChange}
					plugins={plugins}
					onFocus={onFocus}
					placeholder={placeholder}
				/>
			</div>
		</div>
	)
}
