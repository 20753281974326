import React from 'react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'

interface Props<T extends { key: string; value: string }> {
	options: T[]
	className?: string
	onChange?: (value: string) => void
}

export function OptionSelect<T extends { key: string; value: string }>({
	options,
	className,
	onChange,
}: Props<T>) {
	const handleChange = (event: SelectChangeEvent<string>) => {
		if (onChange) {
			onChange(event.target.value)
		}
	}

	const renderAddress =
		options.length > 1 ? (
			<Select
				autoWidth
				value={options[0].key}
				label="null"
				onChange={handleChange}
				size="small"
				sx={{ position: 'absolute', top: -6, left: 19 }}
				className={className}
			>
				{options.map(option => {
					return (
						<MenuItem key={option.key} value={option.key}>
							<p className="truncate max-w-xs block m-0 p-0">{option.value}</p>
						</MenuItem>
					)
				})}
			</Select>
		) : (
			<>{options[0].value}</>
		)

	return renderAddress
}
