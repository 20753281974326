import React from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AuthHeader, Button, Card, Page, TextField } from 'components'
import { useForm } from 'react-hook-form'
import { ErrorMessageType, UserSignInType } from 'types'
import { useGetQuery, validateForm } from 'utils'
import { useSnackbar } from 'notistack'
import { setUserPassword } from 'services'

type FormData = {
	password: string
	confirmPassword: string
}

const defaultValues = {
	password: '',
	confirmPassword: '',
}

export const ResetPassword = () => {
	const navigate = useNavigate()
	const queryParams = useGetQuery()
	const { enqueueSnackbar } = useSnackbar()
	const [submittingForm, setSubmittingForm] = React.useState(false)
	const [error, setError] = React.useState<ErrorMessageType>()
	const [success, setSuccess] = React.useState(false)

	const { handleSubmit, control } = useForm<FormData>({ defaultValues })

	const handleResetPassword = async (data: FormData) => {
		setSubmittingForm(true)
		setError(undefined)
		const type = queryParams.get('t')
		const email = queryParams.get('a')
		const date = queryParams.get('u')

		if (!type || !email || !date) {
			enqueueSnackbar('User is invalid, try again', { variant: 'error' })
			setSubmittingForm(false)
			return
		}
		const error = validateForm(data, {
			password: 'Password',
			confirmPassword: 'Confirm Password',
		})
		if (error) {
			setError(error)
			setSubmittingForm(false)
			return
		}

		if (data.password !== data.confirmPassword) {
			enqueueSnackbar('Passwords must match', { variant: 'error' })
			setSubmittingForm(false)
			return
		}

		if (data.password.length < 8) {
			enqueueSnackbar('Passwords must be at least 8 characters', {
				variant: 'error',
			})
			setSubmittingForm(false)
			return
		}

		const payload = {
			type,
			email,
			password: data.password,
			date,
		} as UserSignInType & { date: string }

		const result = await setUserPassword(payload)
		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			setSubmittingForm(false)
			return
		}
		enqueueSnackbar('Password Saved!', { variant: 'success' })
		setSubmittingForm(false)
		setSuccess(true)
	}

	const title = success ? 'All done!' : 'Enter a new password'

	return (
		<Page header={<AuthHeader title="Update Your Password" />} centered>
			<Box display="flex" justifyContent="center">
				<Card contain={380} title={title}>
					{success ? (
						<div className="w-72">
							<Typography className="pb-4 font-semibold">
								Your password has been saved successfully. Click the button
								below to sign in with your new password.
							</Typography>
							<Button
								variant="contained"
								fullWidth
								onClick={() => navigate('/sign-in')}
							>
								Sign In
							</Button>
						</div>
					) : (
						<form onSubmit={handleSubmit(handleResetPassword)}>
							<TextField
								type="password"
								control={control}
								name="password"
								label="Password"
								error={error?.key === 'password'}
							/>
							<TextField
								type="password"
								control={control}
								name="confirmPassword"
								label="Confirm Password"
								error={error?.key === 'confirmPassword'}
							/>
							<Button
								type="submit"
								variant="contained"
								fullWidth
								loading={submittingForm}
							>
								Save Password
							</Button>
						</form>
					)}
				</Card>
			</Box>
		</Page>
	)
}
