import React from 'react'
import { Box } from '@mui/material'
import {
	AgentContact,
	PhotographerContact,
	FileUploads,
	Questionnaire,
	FAQ,
	RecentListings,
	// Experiences,
	SocialMedia,
} from './my-profile-views'

export const MyProfile = () => {
	return (
		<Box className="pt-[10px] mx-auto mb-12 max-w-[640px] md:max-w-full">
			<div className="bg-white shadow-lg p-8 rounded-lg mb-10">
				<AgentContact />
			</div>
			<div className="bg-white shadow-lg p-8 rounded-lg mb-10">
				<PhotographerContact />
			</div>
			<div className="bg-white shadow-lg p-8 rounded-lg mb-10">
				<FileUploads />
			</div>
			<div className="bg-white shadow-lg p-8 rounded-lg mb-10">
				<Questionnaire />
			</div>
			<div className="bg-white shadow-lg p-8 rounded-lg mb-10">
				<FAQ />
			</div>
			<div className="bg-white shadow-lg p-8 rounded-lg mb-10">
				<RecentListings />
			</div>
			{/* <Experiences /> */}
			<div className="bg-white shadow-lg p-8 rounded-lg mb-10">
				<SocialMedia />
			</div>
		</Box>
	)
}
