import React from 'react'
import { Box } from '@mui/material'
import {
	ActiveButtonGroup,
	AddNewButton,
	Button,
	ContentBlock,
	IconButton,
	QuestionnaireBlock,
} from 'components'
import { useDialog, useQuestionnaire } from 'providers'
import {
	QuestionnaireEnum,
	QuestionnaireBlockType,
	QuestionnaireType,
} from 'types'
import { AddEditQuestion } from './questionnaire-dialog'
import {
	DragDropContext,
	Droppable,
	Draggable,
	DraggingStyle,
	NotDraggingStyle,
	DropResult,
} from 'react-beautiful-dnd'
import { DragIndicator } from '@mui/icons-material'
import { AgentSetQuestionnaire, AgentSetWebsiteSettings } from 'services'
import { getUser, setUser } from 'utils'
import { useSnackbar } from 'notistack'

export const Questionnaire = () => {
	const profile = getUser()
	const { enqueueSnackbar } = useSnackbar()
	const {
		sellerQuestionnaire,
		buyerQuestionnaire,
		setQuestionnaire,
		resetQuestionnaire,
		addToQuestionnaire,
	} = useQuestionnaire()
	const { setDialogOpen } = useDialog()
	const [reorder, setReorder] = React.useState(false)
	const [activeQuestionnaireType, setActiveQuestionnaireType] =
		React.useState<QuestionnaireEnum>(QuestionnaireEnum.SELLER_QUESTIONNAIRE)
	const [activeQuestionnaire, setActiveQuestionnaire] =
		React.useState<QuestionnaireType>(sellerQuestionnaire)

	const questionnaire =
		activeQuestionnaireType === QuestionnaireEnum.SELLER_QUESTIONNAIRE
			? sellerQuestionnaire
			: buyerQuestionnaire

	const handleActiveButtonGroupChange = (value: {
		value: number
		label: string
	}) => {
		const { value: questionnaireType } = value
		if (questionnaireType === 0) {
			setActiveQuestionnaireType(QuestionnaireEnum.SELLER_QUESTIONNAIRE)
			setActiveQuestionnaire(sellerQuestionnaire)
		} else {
			setActiveQuestionnaireType(QuestionnaireEnum.BUYER_QUESTIONNAIRE)
			setActiveQuestionnaire(buyerQuestionnaire)
		}
	}

	const handleAddQuestion = (_: number, question: QuestionnaireBlockType) => {
		addToQuestionnaire(activeQuestionnaireType, question)
		setQuestionnaire(
			activeQuestionnaireType,
			activeQuestionnaire.length,
			question
		)
	}

	const handleUpdateQuestion = (
		index: number,
		question: QuestionnaireBlockType,
		remove?: boolean
	) => {
		if (remove) {
			setQuestionnaire(activeQuestionnaireType, index)
		} else {
			setQuestionnaire(activeQuestionnaireType, index, question)
		}
	}

	const handleAddNew = () => {
		setDialogOpen({
			title: 'Add a Question',
			content: (
				<AddEditQuestion
					questionnaireType={activeQuestionnaireType}
					questionnaire={activeQuestionnaire}
					onSave={handleAddQuestion}
				/>
			),
			showClose: true,
		})
	}

	const handleEdit = (questionnaireIndex: number) => {
		setDialogOpen({
			title: 'Update Question',
			content: (
				<AddEditQuestion
					update
					questionIndex={questionnaireIndex}
					questionnaireType={activeQuestionnaireType}
					questionnaire={activeQuestionnaire}
					onSave={handleUpdateQuestion}
				/>
			),
			showClose: true,
		})
	}

	const getItemStyle = (
		isDragging: boolean,
		draggableStyle: DraggingStyle | NotDraggingStyle | undefined
	): React.CSSProperties => ({
		userSelect: 'none',
		display: 'flex',
		paddingTop: 12,
		paddingBottom: 12,
		width: '100%',
		border: 'solid 1px',
		background: '#fff',
		borderColor: isDragging ? '#c5c5c5' : 'transparent',
		borderRadius: 8,
		boxShadow: isDragging ? '0 0 12px 0 #ccc' : '0 0 12px 0 transparent',
		...draggableStyle,
	})

	const reorderItems = (
		list: QuestionnaireType,
		startIndex: number,
		endIndex: number
	): QuestionnaireType => {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)

		return result
	}

	const handleOnDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return
		}

		const items: QuestionnaireType = reorderItems(
			questionnaire,
			result.source.index,
			result.destination.index
		)
		resetQuestionnaire(activeQuestionnaireType, items)
		saveQuestionnaire(items)
	}

	const saveQuestionnaire = async (newQuestionnaire: QuestionnaireType) => {
		const newProfile = getUser()
		newProfile.website.settings.sellerQuestionnaire = newQuestionnaire
		newProfile.website.settings.buyerQuestionnaire = newQuestionnaire

		const result = await AgentSetQuestionnaire({
			type: activeQuestionnaireType,
			questionnaire: newQuestionnaire,
			agentUuid: profile.user.uuid,
		})

		const result2 = await AgentSetWebsiteSettings({
			agentUuid: profile.user.uuid,
			settings: newProfile.website.settings,
		})

		if (result.error || result2.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			return false
		}
		enqueueSnackbar('Questionnaire Order Changed!', { variant: 'success' })
		setUser({
			...getUser(),
			questionnaire: newQuestionnaire,
		})
	}

	return (
		<Box className="pt-2 mx-auto max-w-[856px] md:mx-0">
			<div className="mb-4 max-w-[600px]">
				<ContentBlock
					label="Client Onboarding Questionnaire"
					description="Your prospects will fill this out when they schedule a meeting, you can also send this questionnaire directly to new clients that you add to the Lead Conversion page. Feel free to customize it to your needs."
				/>
				<Button
					startIcon={
						!reorder ? (
							<DragIndicator sx={{ fill: '#05a501 !important' }} />
						) : undefined
					}
					className="mt-4"
					variant="outlined"
					onClick={() => setReorder(!reorder)}
				>
					{reorder ? 'Done Reordering' : 'Reorder Questions'}
				</Button>
			</div>
			<ActiveButtonGroup
				options={['For Sellers', 'For Buyers']}
				onChange={handleActiveButtonGroupChange}
			/>
			<DragDropContext onDragEnd={handleOnDragEnd}>
				<Droppable droppableId="droppable-questionnaire">
					{(provided): JSX.Element => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{questionnaire.map((questionnaireBlock, index) => (
								<Draggable
									key={`${index}-id`}
									draggableId={`${index}-id`}
									index={index}
								>
									{(provided, snapshot): JSX.Element => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											style={getItemStyle(
												snapshot.isDragging,
												provided.draggableProps.style
											)}
										>
											<div {...provided.dragHandleProps}>
												{reorder && (
													<IconButton
														noBorder
														tooltip="Reorder"
														sx={{ marginTop: '8px', marginRight: '8px' }}
													>
														<DragIndicator
															sx={{ fill: '#05a501 !important' }}
														/>
													</IconButton>
												)}
											</div>
											<QuestionnaireBlock
												questionnaireBlock={questionnaireBlock}
												questionnaireIndex={index}
												onEdit={handleEdit}
											/>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			<div
				className={
					activeQuestionnaire.length
						? `${reorder ? 'ml-[84px]' : 'ml-9'} mr-12 max-w-[782px]`
						: ''
				}
			>
				<AddNewButton
					text={`Add ${activeQuestionnaire.length ? 'another' : 'a'} Question`}
					onClick={handleAddNew}
				/>
			</div>
		</Box>
	)
}
