import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { Avatar } from './avatar'

interface Props {
	title: string
	last?: boolean
	outline?: boolean
	prefix?: string
	avatar?: string
	subtitle?: string
	justify?: 'between' | 'center'
	onClick?: () => void
}

export const DataItem: FC<Props> = ({
	title,
	last,
	outline,
	prefix,
	avatar,
	subtitle = null,
	justify = false,
	onClick,
}) => {
	const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (onClick && event.detail === 1) {
			onClick()
		}
	}

	return (
		<Box
			onClick={handleClick}
			className={`${!last ? 'shadow-sm ' : ' '}${
				avatar ? 'px-4 pb-4' : 'p-4'
			} ${
				outline ? 'border border-solid border-gray-300' : ''
			} rounded-lg bg-white flex items-center flex-wrap content-between`}
			sx={{
				justifyContent: justify ? `space-${justify}` : 'flex-start',
				transition: '.2s',
				cursor: onClick ? 'pointer' : 'default',
				'&:hover': {
					backgroundColor: onClick ? '#f5f5f5' : '#fff',
				},
			}}
		>
			{avatar && (
				<div className={avatar ? 'mt-4' : ''}>
					<Avatar text={avatar} />
				</div>
			)}
			<div className={avatar ? 'mt-4' : ''}>
				<div className="flex items-center flex-wrap">
					<Typography className="font-semibold">
						{prefix && <span className="font-bold">{prefix} </span>}
						{title}
					</Typography>
				</div>
				{subtitle && (
					<Typography className="text-gray-600 font-semibold">
						{subtitle}
					</Typography>
				)}
			</div>
		</Box>
	)
}
