import React from 'react'
import { useTheme, useMediaQuery, Typography, Alert } from '@mui/material'
import { Button, MobileMenuIcon } from 'components'
import { Share } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { AvailabilityType } from 'types'
import { useMobileMenu } from 'providers'

interface Props {
	user: {
		uuid: string
		name: string
		email: string
		phone: string
		hash: string | undefined
		brokerage?: string
		signedIn?: boolean
		published?: boolean
		meetings: any
		availability: AvailabilityType
	}
}

export const PublicWebsiteHeader = ({ user }: Props) => {
	const theme = useTheme()
	const { publicMenuOpen } = useMobileMenu()
	const navigate = useNavigate()
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

	const menuButtonVariant = isMediumScreen ? 'flat' : 'outlined'
	const scheduleButtonVariant = isMediumScreen ? 'flat' : 'marketing'
	return (
		<>
			{user.signedIn && (
				<div className="badhous-signed-in-alert-public-website">
					<Alert severity="info" className="font-semibold">
						Your website is
						<span className="font-black">
							{user.published ? ' published' : ' unpublished'}!
						</span>
						<span
							className="inline-block ml-2 font-bold underline cursor-pointer hover:text-[#000] duration-200"
							onClick={() => navigate('/website')}
						>
							Site Settings
						</span>
					</Alert>
				</div>
			)}
			<div
				className={`
        fixed top-0 left-0 right-0 border-solid border-b border-gray-300 badhous-header-border bg-white z-[12] h-16
					${
						isMediumScreen
							? ` badhous-public-website-header ${
									publicMenuOpen ? ' active' : ''
							  }`
							: `px-4 md:bottom-auto items-center `
					}`}
			>
				{isMediumScreen && (
					<div>
						<MobileMenuIcon publicWebsite />
					</div>
				)}
				<div
					className={`md:flex items-center md:h-16 ${
						isMediumScreen ? 'badhous-menu-container' : ''
					}`}
					style={{ paddingTop: isMediumScreen && user?.signedIn ? 70 : 0 }}
				>
					<Typography
						className="m_md:text-center m_md:block m_md:w-full flex items-center text-lg badhous-user-slug font-semibold cursor-pointer"
						onClick={() => (user.hash ? navigate(`/u/${user.hash}`) : null)}
					>
						{user.name}
						{user.brokerage && (
							<span className="text-base text-gray-800 badhous-user-slug capitalize">
								{user.brokerage}
							</span>
						)}
					</Typography>
					<div className="md:ml-10">
						<Button
							variant={menuButtonVariant}
							className="font-semibold m_md:block m_md:w-full"
							onClick={() => {
								navigate(`/u/${user.hash}`)
							}}
						>
							Home
						</Button>
						<Button
							variant={menuButtonVariant}
							className="md:ml-3 font-semibold m_md:block m_md:w-full"
							onClick={() => {
								navigate(`/u/${user.hash}/about-me`)
							}}
						>
							About Me
						</Button>
						<Button
							variant={menuButtonVariant}
							className="md:ml-3 font-semibold m_md:block m_md:w-full"
							onClick={() => {
								navigate(`/u/${user.hash}/listings`)
							}}
						>
							Homes For Sale
						</Button>
					</div>
					<div className="md:ml-auto md:flex justify-end">
						<Button
							variant={menuButtonVariant}
							startIcon={isMediumScreen ? undefined : <Share />}
							className="md:mr-2 md:max-h-[42px] m_md:block m_md:w-full"
						>
							Share this Page
						</Button>
						<Button
							variant={scheduleButtonVariant}
							className="md:max-h-[42px] m_md:block m_md:w-full"
						>
							Buying or Selling?
						</Button>
					</div>
				</div>
			</div>
		</>
	)
}
