import React from 'react'
import {
	Button as MuiButton,
	ButtonProps,
	Typography,
	CircularProgress,
} from '@mui/material'
import { Tooltip } from './tooltip'

interface Props extends ButtonProps {
	tooltip?: string
	loading?: boolean
	large?: boolean
	textLight?: boolean
	fullOnMobile?: boolean
}

export const Button = ({
	onClick,
	children,
	disabled,
	tooltip,
	loading,
	large,
	textLight,
	fullOnMobile,
	...rest
}: Props) => {
	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (onClick && event.detail === 1) {
			onClick(event)
		}
	}

	const style = large
		? {
				fontSize: '19px',
		  }
		: undefined

	return (
		<MuiButton
			{...rest}
			onClick={handleClick}
			disabled={disabled || loading}
			className={[rest.className, fullOnMobile ? 'm_xs:w-full' : ''].join(' ')}
		>
			{loading && (
				<div style={{ position: 'absolute', display: 'flex' }}>
					<CircularProgress style={{ color: 'white' }} size={28} />
				</div>
			)}
			<Tooltip title={tooltip || ''} disableHoverListener={Boolean(!tooltip)}>
				<Typography
					style={{ visibility: loading ? 'hidden' : 'visible' }}
					sx={{
						...style,
						color: textLight ? '#374151 !important' : 'initial',
					}}
				>
					{children}
				</Typography>
			</Tooltip>
		</MuiButton>
	)
}
