import React from 'react'
import { Button, Card, TextField } from 'components'
import { useForm } from 'react-hook-form'
import {
	ErrorMessageType,
	RecentListingCardType,
	RecentListingType,
} from 'types'
import { AgentSetSettingsData, AgentSetWebsiteSettings } from 'services'
import { getUser, setUser, validateForm } from 'utils'
import { useSnackbar } from 'notistack'
import { useRecentListings } from 'providers'
import { isListingProfileComplete } from '../helpers'

interface Props {
	recentListingIndex: number
	recentListing: RecentListingCardType
	hideRemove?: boolean
}

export const RecentListingCard = ({
	recentListingIndex,
	recentListing,
	hideRemove,
}: Props) => {
	const profile = getUser()
	const [submittingForm, setSubmittingForm] = React.useState(false)
	const [error, setError] = React.useState<ErrorMessageType>()
	const [isEditing, setIsEditing] = React.useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const { setRecentListings } = useRecentListings()

	const defaultValues = {
		line1: recentListing.line1 || '',
		line2: recentListing.line2 || '',
		city: recentListing.city || '',
		state: recentListing.state || '',
		zip: recentListing.zip || '',
		closePrice: recentListing.closePrice || '',
		timeOnMarket: recentListing.timeOnMarket || '',
	}

	const { handleSubmit, control } = useForm<RecentListingCardType>({
		defaultValues,
	})

	const handleDelete = async () => {
		const newRecentListings = getUser().settings.listingProfile.recentListings

		newRecentListings.splice(recentListingIndex, 1)

		await save(newRecentListings)
		setRecentListings(recentListingIndex)
	}

	const handleSave = async (formData: RecentListingCardType) => {
		setSubmittingForm(true)
		setError(undefined)

		const error = validateForm(
			formData,
			{
				line1: 'Address Line 1',
				city: 'City',
				state: 'State',
				zip: 'Postal Code',
				closePrice: 'Close Price',
				timeOnMarket: 'Time on Market',
			},
			{
				zip: {
					pattern: /\D/g,
					error: 'Postal code can contain numbers only',
				},
			}
		)

		if (error) {
			setError(error)
			setSubmittingForm(false)
			return
		}

		const newRecentListings = getUser().settings.listingProfile.recentListings
		newRecentListings.splice(recentListingIndex, 1, formData)

		await save(newRecentListings)
		setRecentListings(recentListingIndex, formData)
	}

	const save = async (recentListings: RecentListingType) => {
		const newProfile = getUser()
		newProfile.settings.listingProfile.recentListings = recentListings
		newProfile.settings.listingProfile.completed.recentListings = true
		newProfile.website.settings.recentListings = recentListings

		const updates = [
			{
				key: 'listingProfile',
				value: newProfile.settings.listingProfile,
			},
		]

		if (isListingProfileComplete(newProfile)) {
			newProfile.settings.onboarding.steps[1].complete = true

			updates.push({
				key: 'onboarding',
				value: newProfile.settings.onboarding,
			})
		}

		const result = await AgentSetSettingsData({
			agentUuid: profile.user.uuid,
			updates,
		})

		const result2 = await AgentSetWebsiteSettings({
			settings: newProfile.website.settings,
			agentUuid: profile.user.uuid,
		})

		if (result?.status === 'success' && result2?.status === 'success') {
			setUser(newProfile)
			setIsEditing(false)
			setSubmittingForm(false)
			enqueueSnackbar('Listing Saved!', { variant: 'success' })
		}
	}

	React.useEffect(() => {
		if (error) {
			enqueueSnackbar(error.message, { variant: 'error' })
		}
	}, [error, enqueueSnackbar])

	React.useEffect(() => {
		const listingFields = { ...recentListing } as any
		if (Object.keys(recentListing).every((key: any) => !listingFields[key])) {
			setIsEditing(true)
		}
	}, [])

	return (
		<Card flat marginBottom={4} contain={856}>
			<form className="p-2" onSubmit={handleSubmit(handleSave)}>
				<div className="mb-3">
					<TextField
						control={control}
						name="line1"
						label="Street Address Line 1"
						size="small"
						defaultValue={recentListing.line1}
						error={error?.key === 'line1'}
						className="variant-card"
						disabled={!isEditing}
					/>
				</div>
				<div className="mb-3">
					<TextField
						control={control}
						name="line2"
						label="Line 2 (optional)"
						size="small"
						error={error?.key === 'line2'}
						className="variant-card"
						disabled={!isEditing}
					/>
				</div>
				<div className="md:flex md:mb-3 w-full">
					<div className="mb-3 md:mb-0 w-full">
						<TextField
							control={control}
							name="city"
							label="City"
							size="small"
							defaultValue={recentListing.city}
							error={error?.key === 'city'}
							className="variant-card"
							disabled={!isEditing}
						/>
					</div>
					<div className="md:mx-2 mb-3 md:mb-0 w-full">
						<TextField
							caps
							control={control}
							name="state"
							label="State"
							maxLength={2}
							size="small"
							defaultValue={recentListing.state}
							error={error?.key === 'state'}
							className="variant-card"
							disabled={!isEditing}
						/>
					</div>
					<div className="mb-3 md:mb-0 w-full">
						<TextField
							numbersOnly
							control={control}
							name="zip"
							label="Postal Code"
							maxLength={5}
							size="small"
							defaultValue={recentListing.zip}
							error={error?.key === 'zip'}
							className="variant-card"
							disabled={!isEditing}
						/>
					</div>
				</div>
				<div className="mt-8 md:flex md:items-center">
					<div className="md:w-[30%] mb-3 md:mb-0">
						<TextField
							caps
							control={control}
							name="closePrice"
							label="Close Price"
							size="small"
							defaultValue={recentListing.closePrice}
							error={error?.key === 'closePrice'}
							className="variant-card"
							disabled={!isEditing}
						/>
					</div>
					<div className="md:ml-2 md:w-[30%] mb-3 md:mb-0">
						<TextField
							control={control}
							name="timeOnMarket"
							label="Time on Market"
							size="small"
							defaultValue={recentListing.timeOnMarket}
							error={error?.key === 'timeOnMarket'}
							className="variant-card"
							disabled={!isEditing}
						/>
					</div>
					<div className="flex mr-0 ml-auto w-full md:w-auto">
						{!hideRemove && (
							<Button
								size="small"
								className="md:mr-2 w-full md:w-auto"
								onClick={handleDelete}
							>
								Remove
							</Button>
						)}
						{isEditing && (
							<Button
								type="submit"
								variant="contained"
								loading={submittingForm}
								size="small"
								className="mr-0 w-full md:w-auto"
							>
								Save
							</Button>
						)}
						{!isEditing && (
							<Button
								variant="outlined"
								size="small"
								className="mr-0 min-w-[80px] w-full md:w-auto"
								onClick={() => setIsEditing(true)}
							>
								Edit
							</Button>
						)}
					</div>
				</div>
			</form>
		</Card>
	)
}
