import React from 'react'
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

interface Props {
	label: string
	description: React.ReactNode
	tag?: React.ReactNode
	link?: {
		path: string
		text: string
	}
	margin?: string
}

export const ContentBlock = ({
	label,
	description,
	tag,
	link,
	margin = '0',
}: Props) => {
	return (
		<div className={`mb-${margin}`}>
			<Typography variant="contentLabel" className="block mb-3">
				{label}
			</Typography>
			<Typography className="font-semibold">
				{description}
				{link && (
					<>
						{' '}
						<Link to={link.path} className="font-bold">
							{link.text}
						</Link>
					</>
				)}
			</Typography>
			<div className="max-w-[400px]" style={{ marginTop: tag ? 8 : 0 }}>
				{tag}
			</div>
		</div>
	)
}
