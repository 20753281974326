import request from '../request'
import { UserAgentType, UserCreateType, UserSignInType } from 'types'

export const userCreate = (payload: UserCreateType) => {
	return request({
		url: '/auth/user-create',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}
export const userUpdate = (
	payload: Omit<UserAgentType, 'brokerageUuid' | 'photo'>
) => {
	return request({
		url: '/auth/user-update',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}
export const userSignIn = (payload: UserSignInType) => {
	return request({
		url: '/auth/user-sign-in',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}
export const sendPasswordResetEmail = (
	payload: Omit<UserSignInType, 'password'>
) => {
	return request({
		url: '/auth/send-password-reset-email',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}
export const setUserPassword = (payload: UserSignInType & { date: string }) => {
	return request({
		url: '/auth/set-user-password',
		method: 'POST',
		data: {
			payload,
			env: process.env.DB_HOST,
		},
	})
}
