import React from 'react'
import { useSaveBeforeExit } from 'utils'

export const useDebounce = (value: any, delay: number) => {
	const [debouncedValue, setDebouncedValue] = React.useState(value)

	React.useEffect(() => {
		if (delay === 0) {
			setDebouncedValue(value)
			return
		}

		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)

		return () => {
			clearTimeout(handler)
		}
	}, [value, delay])

	useSaveBeforeExit(Boolean(delay && value !== debouncedValue))

	return debouncedValue
}
