import React from 'react'
import { DashboardCard, DataItem } from 'components'
import { format } from 'date-fns'
import { ClientActivityType, ClientActivityItemType } from 'types'
import { MyTasks } from './tasks'
import { DailyRoutine } from './routine'
import { Typography } from '@mui/material'

interface Props {
	clientActivity: ClientActivityType[]
}

export const AgentDashboard = ({ clientActivity }: Props) => {
	return (
		<div className="max-w-[700px] mx-auto md:max-w-full md:grid md:grid-cols-2 md:gap-4 mt-6">
			<div>
				<MyTasks />
				<DashboardCard title="Client Activity">
					<div className="p-4 pb-0">
						{clientActivity?.length ? (
							clientActivity.map((client, index) => {
								const { first, last, activity, updatedAt } = client
								const items = JSON.parse(activity)
								if (items.length) {
									return items.map(
										(listItem: ClientActivityItemType, i: number) => {
											return (
												<DataItem
													last={index + 1 === clientActivity.length}
													key={`${listItem.title}-${i}`}
													avatar={`${first[0]}`}
													title={listItem.title}
													prefix={`${first} ${last}`}
													subtitle={format(
														new Date(updatedAt),
														'MMM do yyyy @ hh:mm aa'
													)}
												/>
											)
										}
									)
								}
								return null
							})
						) : (
							<Typography align="center" className="px-4 pt-2 pb-4 font-bold">
								No Recent Activity
							</Typography>
						)}
					</div>
				</DashboardCard>
			</div>
			<div>
				<DailyRoutine />
			</div>
		</div>
	)
}
