import React, { FC } from 'react'
import { Avatar } from './avatar'
import { useTheme, Box, Typography, AvatarGroup } from '@mui/material'
import { BUYER_STAMP_PNG, SELLER_STAMP_PNG } from 'lib/theme'
import { BuyerOrSellerType } from 'types'

interface Props {
	name: string
	bold?: boolean
	avatar?: string
	leadType?: BuyerOrSellerType
	subtext?: string
	noMargin?: boolean
	alert?: string
	link?: React.ReactNode
	color?: 'blue' | 'green' | 'red'
	action?: React.ReactNode
	onClick?: () => void
}

export const ContactItem: FC<Props> = ({
	name,
	bold,
	avatar,
	leadType,
	subtext,
	noMargin,
	alert,
	link,
	color = null,
	action,
	onClick,
}) => {
	const theme = useTheme()
	let borderColor = theme.palette.secondary.main
	switch (color) {
		case 'blue':
			borderColor = theme.palette.blue.light
			break
		case 'green':
			borderColor = theme.palette.green.light
			break
		case 'red':
			borderColor = theme.palette.red.light
			break
	}

	const handleClick = () => {
		if (onClick) {
			onClick()
		}
	}

	return (
		<Box
			onClick={handleClick}
			className="w-full box-border border border-[#c5c5c5] py-3 px-4 rounded-lg bg-white flex items-center justify-between shadow-md"
			sx={{
				borderLeftColor: `${borderColor} !important`,
				borderLeftWidth: '2px !important',
				borderLeftStyle: 'solid',
				transition: '.2s',
				marginBottom: noMargin ? 0 : 1,
				cursor: onClick ? 'pointer' : 'inherit',
				'&:hover': {
					backgroundColor: onClick ? '#f5f5f5' : '#fff',
				},
			}}
		>
			<Box className="flex items-center relative">
				{leadType ? (
					<Avatar
						src={leadType === 'buyer' ? BUYER_STAMP_PNG : SELLER_STAMP_PNG}
						size={32}
					/>
				) : (
					<Avatar gray text={avatar?.[0] || name[0]} />
				)}
				<Box className={`${leadType ? 'ml-2' : ''}`}>
					<Typography className={`font-${bold ? 'bold' : 'semibold'}`}>
						{name}
					</Typography>
					{(alert || subtext) && (
						<Typography
							className="font-semibold"
							style={{
								color: alert ? theme.palette.red.main : theme.palette.grey[600],
							}}
						>
							{alert || subtext}
						</Typography>
					)}
					{link}
				</Box>
			</Box>
			{action}
		</Box>
	)
}
