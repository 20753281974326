import React from 'react'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import {
	setUser,
	getUser,
	useDebounce,
	isArrEqual,
	reactKeyedValue,
	capitalize,
} from 'utils'
import { AddNewButton, IconButton, TextField } from 'components'
import { AgentSetSettingsData } from 'services'
import { useSnackbar } from 'notistack'
import { Delete } from '@mui/icons-material'
import { ReactKeyedValueType } from 'types'

interface Props {
	stage: string
	checklist: ReactKeyedValueType[]
}

export const ChecklistBlock = ({ stage, checklist }: Props) => {
	const profile = getUser()
	const { control } = useForm()
	const { enqueueSnackbar } = useSnackbar()

	const [checkListItems, setCheckListItems] =
		React.useState<ReactKeyedValueType[]>(checklist)

	const debouncedItems = useDebounce(checkListItems, 1000)

	const handleAddItem = () => {
		const newItems = [...checkListItems, reactKeyedValue()]
		setCheckListItems(newItems)
	}

	const handleDeleteItem = (index: number) => {
		const newItems = [...checkListItems]
		newItems.splice(index, 1)
		setCheckListItems(newItems)
	}

	const handleUpdateChecklistBlock = async (value: string, index: number) => {
		const newItems = [...checkListItems]
		newItems.splice(index, 1, {
			...newItems[index],
			value,
		})
		setCheckListItems(newItems)
	}

	const handleSave = async (newChecklist: ReactKeyedValueType[]) => {
		const checklists = {
			...profile.settings.checklists,
			[stage]: newChecklist.map(item => item.value),
		}

		const result = await AgentSetSettingsData({
			agentUuid: profile.user.uuid,
			updates: [
				{
					key: 'checklists',
					value: checklists,
				},
			],
		})

		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			return
		}

		if (result?.status === 'success') {
			enqueueSnackbar(`${capitalize(stage)} checklist updated!`, {
				variant: 'success',
			})

			setUser({
				...profile,
				settings: {
					...profile.settings,
					checklists,
				},
			})
		}
	}

	React.useEffect(() => {
		if (
			!isArrEqual(
				debouncedItems.map((item: ReactKeyedValueType) => item.value),
				profile.settings.checklists[stage]
			)
		) {
			handleSave(debouncedItems)
		}
	}, [debouncedItems])

	return (
		<form>
			{checkListItems.map((listItem, index) => {
				return (
					<div className="flex items-center" key={`${stage}-${listItem.key}`}>
						<Typography className="font-black mb-3 min-w-[20px]">
							{index + 1}
						</Typography>
						<TextField
							control={control}
							name={`${stage}-${listItem.key}`}
							label={!listItem.value.length ? `Add a ${stage} task` : ''}
							defaultValue={listItem.value}
							className="ml-5"
							onChange={({ value }) => handleUpdateChecklistBlock(value, index)}
							size="small"
						/>
						<IconButton
							noBorder
							tooltip="Remove Item"
							onClick={() => handleDeleteItem(index)}
							className="ml-2 mb-3"
						>
							<Delete />
						</IconButton>
					</div>
				)
			})}
			<div className={`${checkListItems.length ? 'pl-10 pr-12' : ''}`}>
				<AddNewButton
					text={`Add ${checkListItems.length ? 'another' : 'an'} Item`}
					onClick={handleAddItem}
				/>
			</div>
		</form>
	)
}
