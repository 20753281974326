import React from 'react'
import { Box, Typography } from '@mui/material'
import { ContentBlock, StageLabel } from 'components'
import { ChecklistType } from 'types'
import { ChecklistBlock } from './checklist-views'
import { getReactKeyedValues } from 'utils'

interface Props {
	checklists: ChecklistType
}

export const AgentChecklists = ({ checklists }: Props) => {
	return (
		<Box className="pt-[10px] mx-auto max-w-[856px] md:mx-0 mb-20">
			<div className="mb-8 max-w-[600px]">
				<ContentBlock
					label="Set up your checklists for each selling stage"
					description="We’ve created a generic checklist for you, feel free to modify or remove items as needed. This checklist will appear when you are managing an active client."
				/>
			</div>
			<div className="max-w-[720px] mb-10">
				<StageLabel label="Listing" showStageLabel />
				<Typography className="mt-3 mb-5">
					This checklist represents all the things you need to do when you first
					get a client listing
				</Typography>
				<ChecklistBlock
					stage="listing"
					checklist={getReactKeyedValues(checklists.listing)}
				/>
			</div>
			<div className="max-w-[720px] py-10 mb-10 border-y border-solid border-gray-200">
				<StageLabel label="Marketing" showStageLabel />
				<Typography className="mt-3 mb-5">
					This checklist represents all the things you need to do when you are
					marketing a property
				</Typography>
				<ChecklistBlock
					stage="marketing"
					checklist={getReactKeyedValues(checklists.marketing)}
				/>
			</div>
			<div className="max-w-[720px] mb-10">
				<StageLabel label="Closing" showStageLabel />
				<Typography className="mt-3 mb-5">
					This checklist represents all the things you need to do when you are
					closing on a property
				</Typography>
				<ChecklistBlock
					stage="closing"
					checklist={getReactKeyedValues(checklists.closing)}
				/>
			</div>
		</Box>
	)
}
