import { useContext } from 'react'
import { AssistantContext } from '../assistant-provider'
import { AvailabilityContext } from '../availability-provider'
import { DialogContext } from '../dialog-provider'
import { FAQContext } from '../faq-provider'
import { LeadDialogContext } from '../lead-dialog-provider'
import { LeadsContext } from '../leads-provider'
import { MobileMenuContext } from '../mobile-menu-provider'
import { QuestionnaireContext } from 'providers/questionnaire-provider'
import { RecentListingsContext } from '../recent-listings-provider'
import { SaveChangesContext } from '../save-changes-provider'

export const useAssistant = () => useContext(AssistantContext)
export const useAvailability = () => useContext(AvailabilityContext)
export const useDialog = () => useContext(DialogContext)
export const useFAQ = () => useContext(FAQContext)
export const useLeadDialog = () => useContext(LeadDialogContext)
export const useLeads = () => useContext(LeadsContext)
export const useMobileMenu = () => useContext(MobileMenuContext)
export const useQuestionnaire = () => useContext(QuestionnaireContext)
export const useRecentListings = () => useContext(RecentListingsContext)
export const useSaveChanges = () => useContext(SaveChangesContext)
