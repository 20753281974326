import React, { FC } from 'react'
import { Box, Button as MuiButton, Typography } from '@mui/material'
import { AddCircleOutline } from '@mui/icons-material'

interface Props {
	text: string
	height?: number
	className?: string
	onClick: () => void
}

export const AddNewButton: FC<Props> = ({
	text,
	height,
	className,
	onClick,
}) => {
	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (event.detail === 1) {
			onClick()
		}
	}

	return (
		<MuiButton
			fullWidth
			variant="addNew"
			onClick={handleClick}
			sx={{ height: height || 'auto' }}
			className={className}
		>
			<Box className="flex justify-center items-center">
				<AddCircleOutline />
				<Typography>{text}</Typography>
			</Box>
		</MuiButton>
	)
}
