import React, { FC } from 'react'
import { PoweredBy } from 'lib'
import { OptionSelect } from '../molecules'
import { AddressType } from 'types'
import { Avatar } from 'components'

interface Props {
	first: string
	addresses: AddressType[]
}

export const ClientHeader: FC<Props> = ({ first, addresses }) => {
	const handleChange = (value: string) => {
		console.log('changed address')
		console.log(value)
	}

	return (
		<div className="relative px-4 border-solid border-b border-gray-300 badhous-header-border">
			<div className="flex justify-between items-center h-16">
				<div className="flex items-center text-lg font-semibold">
					<Avatar text={first[0]} />
					<span className="badhous-user-name">{first}</span>
					<span className="badhous-user-slug select-no-label">
						<span className="text-base text-gray-800 truncate max-w-xs">
							<OptionSelect<AddressType>
								options={addresses}
								onChange={handleChange}
								className="max-w-[50vw]"
							/>
						</span>
					</span>
				</div>
				<div>
					<PoweredBy />
				</div>
			</div>
		</div>
	)
}
