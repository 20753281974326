import { ErrorMessageType, ForceGenericObjectType } from 'types'

export const validateForm = (
	data: ForceGenericObjectType,
	opts: ForceGenericObjectType,
	rules?: ForceGenericObjectType
) => {
	let error: ErrorMessageType = undefined
	Object.keys(opts).some(field => {
		if (rules && rules[field]) {
			const regex = new RegExp(rules[field].pattern)
			if (regex.test(data[field])) {
				error = {
					message: rules[field].error,
					key: field,
				}
				return true
			}
		}

		if (!data[field]?.length) {
			error = {
				message: `${opts[field]} is required`,
				key: field,
			}
			return true
		}
		return false
	})

	return error
}

export const isObjEqual = (object1: any, object2: any) => {
	try {
		if (Object.keys(object1).length !== Object.keys(object2).length) {
			return false
		}
		return Object.keys(object1).every(key => object1[key] === object2[key])
	} catch (e) {
		return false
	}
}

export const isArrEqual = (arr1: any, arr2: any) => {
	try {
		if (arr1.length !== arr2.length) {
			return false
		}
		return arr1.every((item: any, index: number) => item === arr2[index])
	} catch (e) {
		return false
	}
}

export const objHasAllValues = (object1: any) => {
	try {
		return Object.keys(object1).every(
			key =>
				object1[key] !== undefined &&
				object1[key] !== null &&
				object1[key] !== ''
		)
	} catch (e) {
		return false
	}
}
