import React from 'react'
import { Box, Typography } from '@mui/material'
import { Card } from './card'
import { StartCompleteButton } from './start-complete-button'
import { OnboardingStep } from 'types'
import { useNavigate } from 'react-router-dom'

export const OnboardingCard = ({
	complete,
	number,
	title,
	subtitle,
	path,
	onClick,
}: OnboardingStep & { number: number; onClick?: (index: number) => void }) => {
	const navigate = useNavigate()
	const handleClick = () => {
		onClick && onClick(number)
	}

	return (
		<Box className={`mb-4 ${complete ? 'cursor-pointer' : ''}`}>
			<Card flat>
				<div
					className="sm:flex w-full py-1"
					onClick={() => complete && navigate(path)}
				>
					<div className="flex items-center justify-center sm:justify-start w-full">
						<span className="hidden sm:flex items-center justify-center w-8 h-8 rounded-[9999px] border border-solid border-gray-300 mr-3">
							<Typography className="font-bold">{number}</Typography>
						</span>
						<div className="py-1 pl-4">
							<Typography
								className="text-center sm:text-left font-bold"
								sx={{
									cursor: complete ? 'pointer' : 'default',
									fontSize: 18,
									'&:hover': {
										color: complete ? '#0064d6' : '#333333',
										textDecoration: complete ? 'underline' : 'none',
									},
								}}
							>
								{title}
							</Typography>
							<Typography className="text-center sm:text-left font-semibold text-gray-600">
								{subtitle}
							</Typography>
						</div>
					</div>
					<div className="mx-auto mt-4 sm:mt-1 sm:mx-0 w-[110px]">
						<StartCompleteButton
							complete={complete}
							path={path}
							onClick={handleClick}
						/>
					</div>
				</div>
			</Card>
		</Box>
	)
}
