import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Typography } from '@mui/material'
import { ActiveListings, AgentPublicWebsiteHero } from 'views'
import { ClientListingType } from 'types'

interface Props {
	route: string
	signedIn: boolean
	website: any
}

export const AgentWebsiteHome = ({ route, signedIn, website }: Props) => {
	const navigate = useNavigate()
	const { settings, type, userUuid } = website

	const listings = settings.listings as ClientListingType[]

	return (
		<>
			<AgentPublicWebsiteHero
				siteHeader={settings.siteHeader}
				photo={settings.user.photo}
			/>
			<div className="bg-white">
				<div className="max-w-[1350px] mx-auto py-[60px] md:py-[80px] px-4 md:px-10">
					<Typography variant="h5" className="font-black mb-5 m_md:text-center">
						Homes For Sale
					</Typography>
					<ActiveListings route={route} listings={listings} />
				</div>
			</div>
		</>
	)
}
