import React, { FC } from 'react'
import { alpha, Box, Button, ButtonProps, Typography } from '@mui/material'
import { RadioButtonUnchecked, CheckCircle } from '@mui/icons-material'

interface Props extends Pick<ButtonProps, 'className'> {
	text: string
	color?: string
	complete?: boolean
	marginRight?: number
	onChange?: (complete: boolean) => void
}

export const CheckboxCompleteButton: FC<Props> = ({
	text,
	color = '#099800',
	complete = false,
	marginRight = 0,
	className,
	onChange,
}) => {
	const [isComplete, setIsComplete] = React.useState(complete)

	const handleOnChange = (state: boolean) => {
		setIsComplete(state)
		if (onChange) {
			onChange(state)
		}
	}

	React.useEffect(() => {
		if (complete) {
			setIsComplete(complete)
		}
	}, [complete])

	return (
		<Button
			variant="complete"
			onClick={() => handleOnChange(!isComplete)}
			className={className}
			sx={{
				border: `solid 1px ${color}`,
				marginRight,
				backgroundColor: `${alpha(color, isComplete ? 0.1 : 0)} !important`,
				'&:hover': {
					backgroundColor: `${alpha(
						color,
						isComplete ? 0.25 : 0.05
					)} !important`,
				},
				'& .MuiTouchRipple-child': {
					backgroundColor: `${color} !important`,
				},
			}}
		>
			<Typography>{text}</Typography>
			<Box className="badhous-divider" style={{ backgroundColor: color }} />
			<Box className="badhous-icon">
				{isComplete ? (
					<CheckCircle sx={{ fill: color }} />
				) : (
					<RadioButtonUnchecked sx={{ fill: color }} />
				)}
			</Box>
		</Button>
	)
}
