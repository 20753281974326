import React from 'react'
import { useTheme } from '@mui/material'
import { Button, ContactItem, PipelineHeader } from 'components'
import {
	DragDropContext,
	Droppable,
	Draggable,
	DraggingStyle,
	NotDraggingStyle,
	DropResult,
	DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { LeadConversionType } from 'types'
import { getScheduleLabel } from './helpers'
import { format, formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'
import { useLeads } from 'providers'
import { useSearchParams } from 'react-router-dom'

interface Props {
	timezone: string
	pipeline: LeadConversionType
	droppableMaxHeight?: number
	onDragEnd: (result: DropResult) => void
}

export const PipelineInner = ({
	timezone,
	pipeline,
	droppableMaxHeight,
	onDragEnd,
}: Props) => {
	const [_, setSearchParams] = useSearchParams()
	const { setLead } = useLeads()
	const theme = useTheme()

	const getItemStyle = (
		draggableStyle: DraggingStyle | NotDraggingStyle | undefined
	): React.CSSProperties => ({
		userSelect: 'none',
		display: 'flex',
		width: '100%',
		marginBottom: '8px',
		borderRadius: 8,
		...draggableStyle,
	})

	const getDroppableBackground = (
		index: number,
		snapshot: DroppableStateSnapshot
	): string => {
		// Giving isDraggingOver preference
		let bg = ''
		if (snapshot.isDraggingOver) {
			switch (index) {
				case 1:
					bg = 'rgb(56 175 253 / 11%)'
					break
				case 2:
					bg = 'rgb(6 188 8 / 11%)'
					break
				case 3:
					bg = 'rgb(253 56 56 / 11%)'
					break
			}
		}
		return bg
	}

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<div className="col-span-1">
				<PipelineHeader
					title="Prospects"
					description="these are your prospects..."
					count={pipeline.prospects.length}
					color={theme.palette.blue.light}
				/>
				<Droppable droppableId="prospects">
					{(provided, snapshot): JSX.Element => (
						<div
							className="mt-2 rounded-xl bg-[#f0f2f4] pt-4 pb-10 px-4 box-border"
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={{
								height: droppableMaxHeight,
								overflowY: 'auto',
								backgroundColor: getDroppableBackground(1, snapshot),
							}}
						>
							{pipeline.prospects.length
								? pipeline.prospects.map((item: any, index: number) => {
										return (
											<Draggable
												key={item.key}
												draggableId={item.key}
												index={index}
											>
												{(provided): JSX.Element => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getItemStyle(provided.draggableProps.style)}
													>
														<ContactItem
															noMargin
															name={item.name}
															leadType={item.type}
															subtext={`via ${item.source}`}
															color="blue"
															action={
																<Button
																	textLight
																	onClick={() => {
																		setLead(item)
																		setSearchParams({
																			lead: item.uuid,
																		})
																	}}
																>
																	View
																</Button>
															}
														/>
													</div>
												)}
											</Draggable>
										)
								  })
								: null}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</div>
			<div className="col-span-1">
				<PipelineHeader
					title="Meetings"
					description="these are your meetings..."
					count={pipeline.scheduled.length}
					color={theme.palette.green.light}
				/>
				<Droppable droppableId="scheduled">
					{(provided, snapshot): JSX.Element => (
						<div
							className="mt-2 rounded-xl bg-[#f0f2f4] px-4 pb-10 box-border"
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={{
								height: droppableMaxHeight,
								overflowY: 'auto',
								backgroundColor: getDroppableBackground(2, snapshot),
							}}
						>
							{pipeline.scheduled.length
								? pipeline.scheduled.map((item: any, index: number) => {
										let label = null
										const lastDate = index
											? pipeline.scheduled[index - 1].meeting.date
											: item.meeting.date
										const curDate = format(
											new Date(item.meeting.date),
											'yyyy-MM-dd'
										)

										if (index === 0) {
											label = getScheduleLabel(new Date(item.meeting.date))
										} else if (
											format(new Date(lastDate), 'yyyy-MM-dd') !== curDate
										) {
											label = getScheduleLabel(new Date(item.meeting.date))
										}

										const utcTime = zonedTimeToUtc(
											new Date(item.meeting.date),
											timezone
										)
										const formattedDateTime = formatInTimeZone(
											utcTime,
											timezone,
											'h:mma'
										)
										const timeZone = formatInTimeZone(
											new Date(item.meeting.date),
											timezone,
											'zzz'
										)
										return (
											<React.Fragment key={item.key}>
												{label && (
													<div className="bg-[#f0f2f4] py-1 sticky top-0 z-10">
														{label}
													</div>
												)}
												<div className="px-1">
													<Draggable draggableId={item.key} index={index}>
														{(provided): JSX.Element => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={getItemStyle(
																	provided.draggableProps.style
																)}
															>
																<ContactItem
																	noMargin
																	bold
																	name={`${formattedDateTime} ${timeZone}`}
																	leadType={item.type}
																	subtext={item.name}
																	color="green"
																	action={
																		<Button
																			textLight
																			onClick={() => {
																				setLead(item)
																				setSearchParams({
																					lead: item.uuid,
																				})
																			}}
																		>
																			View
																		</Button>
																	}
																/>
															</div>
														)}
													</Draggable>
												</div>
											</React.Fragment>
										)
								  })
								: null}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</div>
			<div className="col-span-1">
				<PipelineHeader
					title="Contracts Waiting"
					description="these are your contracts waiting..."
					count={pipeline.waiting.length}
					color={theme.palette.red.light}
				/>
				<Droppable droppableId="waiting">
					{(provided, snapshot): JSX.Element => (
						<div
							className="mt-2 rounded-xl bg-[#f0f2f4] pt-4 pb-10 px-4 box-border"
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={{
								height: droppableMaxHeight,
								overflowY: 'auto',
								backgroundColor: getDroppableBackground(3, snapshot),
							}}
						>
							{pipeline.waiting.length
								? pipeline.waiting.map((item: any, index: number) => {
										return (
											<Draggable
												key={item.key}
												draggableId={item.key}
												index={index}
											>
												{(provided): JSX.Element => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getItemStyle(provided.draggableProps.style)}
													>
														<ContactItem
															noMargin
															name={item.name}
															leadType={item.type}
															subtext="Send follow up email"
															color="red"
															action={
																<Button
																	textLight
																	onClick={() => {
																		setLead(item)
																		setSearchParams({
																			lead: item.uuid,
																		})
																	}}
																>
																	View
																</Button>
															}
														/>
													</div>
												)}
											</Draggable>
										)
								  })
								: null}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</div>
		</DragDropContext>
	)
}
