import React from 'react'
import { Avatar, Button, Image } from 'components'
import { Typography } from '@mui/material'
import { FOR_SALE_IMG } from 'lib/theme'
import { Verified, YouTube } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { scrollToScheduler } from 'utils'

interface Props {
	siteHeader: string
	photo: string
}

export const AgentPublicWebsiteHero = ({ siteHeader, photo }: Props) => {
	const navigate = useNavigate()

	return (
		<div className="mt-16 py-[90px] lg:py-[64px] badhous-public-website-hero">
			<div className="max-w-[1350px] mx-auto md:flex items-center px-4 md:px-10">
				<div className="xmd:flex items-start max-w-[85%] xmd:max-w-[70%] xl:max-w-[100%] m_md:mx-auto">
					<Avatar size={140} src={photo} border center />
					<div className="m_xmd:mt-5 xmd:ml-6">
						<Typography className="m_xmd:max-w-[420px] md:max-w-[75%] m_xmd:mx-auto m_xmd:text-center font-black text-[26px] xmd:text-[29px] lg:text-[34px] xmd:leading-[46px]">
							{siteHeader}
						</Typography>
						<div className="m_xs:mx-auto xs:flex m_xmd:justify-center mt-5">
							<Button
								variant="outlined"
								large
								className="m_xs:mb-5 xs:mr-4"
								startIcon={
									<Verified
										color="primary"
										sx={{ fontSize: '26px !important' }}
									/>
								}
								onClick={() => navigate('./about-me')}
								fullOnMobile
							>
								My Approach
							</Button>
							<Button
								variant="contained"
								color="secondary"
								large
								fullOnMobile
								onClick={scrollToScheduler}
							>
								Buying or Selling?
							</Button>
						</div>
					</div>
				</div>
				<div className="md:w-[50%]">
					<div className="mt-24 m-auto m_md:max-w-[320px] md:mx-auto md:mt-0">
						<Image src={FOR_SALE_IMG} />
					</div>
					<Button
						variant="outlined"
						startIcon={
							<YouTube color="secondary" sx={{ fontSize: '28px !important' }} />
						}
						className="mt-5 mx-auto flex"
					>
						Watch Welcome Video
					</Button>
				</div>
			</div>
		</div>
	)
}
