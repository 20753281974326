import React, { FC } from 'react'
import uuid from 'react-uuid'
import { FAQBlockType, FAQType } from 'types'
import { getUser } from 'utils'

const INITIAL_STATE = [{ key: uuid(), question: '', answer: '' }]

interface Props {
	faq: FAQType
	setFAQ: (faq: FAQType) => void
	updateFAQ: (index: number, faqBlock?: FAQBlockType) => void
}

export const FAQContext = React.createContext<Props>({
	faq: INITIAL_STATE,
	setFAQ: () => {},
	updateFAQ: () => {},
})

export const FAQProvider: FC<{ children: React.ReactNode }> = ({
	children,
}): JSX.Element => {
	const profile = getUser()
	const savedFaq = profile?.settings?.listingProfile?.faq
	const [faq, setFAQ] = React.useState(
		savedFaq?.length ? savedFaq : INITIAL_STATE
	)

	const handleSetFAQ = (faqs: FAQType) => {
		if (!faqs.length) {
			setFAQ([{ key: uuid(), question: '', answer: '' }])
		} else {
			setFAQ(faqs)
		}
	}

	const handleUpdateFAQ = (index: number, faqBlock?: FAQBlockType) => {
		const newFaq = [...faq]
		if (faqBlock) {
			newFaq.splice(index, 1, faqBlock)
		} else {
			newFaq.splice(index, 1)
		}
		setFAQ(newFaq)
	}

	return (
		<FAQContext.Provider
			value={{
				faq,
				setFAQ: handleSetFAQ,
				updateFAQ: handleUpdateFAQ,
			}}
		>
			{children}
		</FAQContext.Provider>
	)
}
