import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { StarRating } from 'components/molecules'
import { Button } from './button'

interface Props {
	listPrice: number
	offerPrice: number
	rating: number
	highlight?: boolean
	accepted?: boolean
}

export const OfferCard: FC<Props> = ({
	listPrice,
	offerPrice,
	rating = 5,
	highlight,
	accepted,
}) => {
	return (
		<Box
			sx={{
				border: accepted ? 'none' : 'unset',
				borderColor: highlight ? '#22c55e!important' : '#e2e2e2',
			}}
			className="badhous-wave-pattern relative py-11 px-9 border border-solid rounded-lg shadow-lg max-w-[232px] bg-white"
		>
			{accepted && <span className="badhous-rainbow-border rounded-lg" />}
			<Box>
				<Typography className="text-center font-semibold" sx={{ fontSize: 14 }}>
					Offer {accepted ? 'Accepted!' : 'Received!'}!
				</Typography>
				<Typography
					sx={{ fontSize: 34 }}
					className="font-black text-center my-2"
				>
					${offerPrice}k
				</Typography>
			</Box>
			<Box className="flex justify-center">
				<StarRating size={24} rating={rating} count={5} presentational />
			</Box>
			<Typography
				sx={{ fontSize: 14 }}
				className="font-semibold text-center mt-2 mb-6"
			>
				{listPrice < offerPrice
					? 'Above'
					: listPrice > offerPrice
					? 'Below'
					: ''}{' '}
				Asking Price
			</Typography>
			<Button variant="contained" onClick={() => console.log('opening offer')}>
				View Offer
			</Button>
		</Box>
	)
}
