import axios from 'axios'

const baseURL = process.env.REACT_APP_API_PATH || 'http://localhost:3002/api'
const client = axios.create({
	baseURL,
})

const request = (options: any) => {
	const onSuccess = (response: any) => {
		return response.data
	}

	const onError = (error: any) => {
		if (process.env.REACT_APP_ENVIRONMENT !== 'local') return

		if (error.response) {
			console.error('Status:', error.response.status)
			console.error('Data:', error.response.data)
			console.error('Headers:', error.response.headers)
		} else {
			console.error('Error Message:', error.message)
		}

		return Promise.reject(error.response || error.message)
	}

	return client(options).then(onSuccess).catch(onError)
}

export default request
