import { ForceGenericObjectType } from 'types'
import { getLocalStorageItem, setLocalStorageItem } from '../'

export const LOCAL_STORAGE_KEY_VIEWER = '__viewer'

export function setUser(user: ForceGenericObjectType | null) {
	setLocalStorageItem(LOCAL_STORAGE_KEY_VIEWER, user)
}

export function getUser(): any | null {
	return getLocalStorageItem(LOCAL_STORAGE_KEY_VIEWER)
}
