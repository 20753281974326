import React from 'react'
import { useDialog } from 'providers'
import { AddCircle, Edit } from '@mui/icons-material'
import { DashboardCard, IconButton, ItemList } from 'components'
import { AddTask } from './add-task'
import { UpdateTask } from './update-task'
import { getUser, setUser } from 'utils'
import { CheckedItemListType } from 'types'
import { AgentSetDashboardTasks } from 'services'
import { useSnackbar } from 'notistack'

export const MyTasks = () => {
	const profile = getUser()
	const { enqueueSnackbar } = useSnackbar()
	const [loading, setLoading] = React.useState<number | undefined>()
	const [tasks, setTasks] = React.useState(profile.tasks)
	const { setDialogOpen, setDialogClosed } = useDialog()

	const handleItemChange = (
		value: {
			value: string | undefined
			index: number
		},
		checked: boolean | undefined
	) => {
		setLoading(value.index)
		tasks[value.index].checked = checked
		saveTasks([...tasks])
	}

	const handleSetTasks = (tasks: CheckedItemListType[]) => {
		setTasks(tasks)
		setDialogClosed()
	}

	const handleAddItem = async () => {
		setDialogOpen({
			title: 'Add a Task',
			content: <AddTask tasks={tasks} onSuccess={handleSetTasks} />,
			showClose: true,
			maxWidth: 'xs',
		})
	}

	const handleEditItem = async (index: number) => {
		setDialogOpen({
			title: 'Edit Task',
			content: (
				<UpdateTask
					task={{
						primary: tasks[index].primary,
						checked: tasks[index].checked,
						index,
					}}
					tasks={tasks}
					onSuccess={handleSetTasks}
					onDelete={index => handleItemRemove(index)}
				/>
			),
			showClose: true,
			maxWidth: 'xs',
		})
	}

	const handleItemRemove = async (index: number) => {
		tasks.splice(index, 1)
		const newTasks = [...tasks]
		setLoading(index)
		saveTasks(newTasks)
		setDialogClosed()
	}

	const saveTasks = async (newTasks: CheckedItemListType[]) => {
		const result = await AgentSetDashboardTasks({
			data: newTasks,
			agentUuid: profile.user.uuid,
		})
		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			return
		}
		setTasks(newTasks)
		setLoading(undefined)
	}

	React.useEffect(() => {
		setUser({
			...profile,
			tasks,
		})
	}, [profile, tasks])

	return (
		<DashboardCard
			title="My Tasks"
			action={
				<IconButton tooltip="Add Task" noBorder onClick={handleAddItem}>
					<AddCircle />
				</IconButton>
			}
		>
			<ItemList
				strike
				ordered
				type="Tasks"
				items={tasks}
				loading={loading}
				secondaryAction={
					<IconButton tooltip="Edit Task" noBorder>
						<Edit />
					</IconButton>
				}
				secondaryActionClick={handleEditItem}
				onClick={(value, checked) => handleItemChange(value, checked)}
			/>
		</DashboardCard>
	)
}
