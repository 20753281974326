import React, { FC } from 'react'
import { Button } from 'components/atoms'
import { Box, BoxProps } from '@mui/material'
import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css'

interface Props extends Pick<BoxProps, 'children'> {
	slides?: number
	currentIndex?: number
	footerContent?: React.ReactNode
}

export const ContentSlider: FC<Props> = ({
	children,
	slides = 4,
	footerContent,
	currentIndex = 0,
}) => {
	const [selected, setSelected] = React.useState(currentIndex)
	const [slideCount, setSlideCount] = React.useState(slides)
	const handleFirstMount = (props: any) => {
		!slides && setSlideCount(props.slides)
	}

	return (
		<Box>
			<AwesomeSlider
				infinite
				selected={selected}
				bullets={false}
				organicArrows={false}
				buttons={false}
				className="my-3 w-full"
				onFirstMount={handleFirstMount}
			>
				{children}
			</AwesomeSlider>
			<Box
				className="flex border-t border-gray-300 p-5"
				sx={{ justifyContent: footerContent ? 'space-between' : 'flex-end' }}
			>
				{footerContent}
				<Box>
					<Button
						className="mr-2"
						variant="outlined"
						onClick={() => setSelected(selected - 1)}
						disabled={selected === 0}
					>
						Back
					</Button>
					<Button
						variant="contained"
						onClick={() => setSelected(selected + 1)}
						disabled={selected + 1 === slideCount}
					>
						Next
					</Button>
				</Box>
			</Box>
		</Box>
	)
}
