import React from 'react'
import { ClientListingType } from 'types'
import { useNavigate } from 'react-router-dom'
import {
	Image,
	Button,
	Avatar,
	DataItem,
	PropertyCard,
	DatePicker,
	TimePicker,
} from 'components'
import { Typography } from '@mui/material'
import { SAMPLE_HOME_PHOTO, SAMPLE_PROPERTY_PHOTO } from 'lib/theme'
import { getAddressAndDescription, getClientListing } from 'utils'
import { YouTube } from '@mui/icons-material'
import { ActiveListings } from 'views'

type ListingType = {}

interface Props {
	listings: ClientListingType[]
	agent: {
		name: string
		photo: string
	}
	route: string
	client: string | undefined
	index: string | undefined
}

export const AgentWebsiteListing = ({
	listings,
	agent,
	route: baseRoute,
	client = '',
	index = '',
}: Props) => {
	const navigate = useNavigate()

	const listing = getClientListing(listings, client, index)

	let formattedAddress, formattedDescription
	if (listing) {
		const { address, description } = getAddressAndDescription(
			listing.address,
			true
		)
		formattedAddress = address
		formattedDescription = description
	}

	const propertyPhotos = [
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
		SAMPLE_PROPERTY_PHOTO,
	]

	const propertyFeatures = [
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
		'House Feature/Amenity',
	]

	const filteredListings = listings.filter(listing => {
		const notCurrent =
			listing.client === client && parseInt(index) === listing.index
		return !notCurrent
	})

	return (
		<>
			<div className="mt-16 py-[48px] md:pt-[64px] md:pb-[90px] badhous-public-website-hero">
				<div className="max-w-[1350px] mx-auto flex items-center justify-center px-4 md:px-10">
					<div className="relative md:flex items-start">
						<div className="sm:w-[420px] shadow-lg rounded-3xl overflow-hidden badhous-image">
							<Image height="100%" width="100%" src={SAMPLE_HOME_PHOTO} />
							<span className="block badhous-listing-agent-photo">
								<Avatar src={agent.photo} size={98} border />
							</span>
						</div>
					</div>
					<div className="ml-10 badhous-large-image">
						<Typography className="max-w-[475px] font-bold text-[26px] text-gray-600 leading-[46px] mb-2">
							{formattedDescription}
						</Typography>
						<Typography className="max-w-[475px]">
							<span className="block font-black text-[34px] leading-[46px]">
								{formattedAddress.street}
							</span>
							{formattedAddress.line2 && (
								<span className="block font-black text-[34px] leading-[46px]">
									{formattedAddress.line2}
								</span>
							)}
							<span className="block font-black text-[34px] leading-[46px]">
								{formattedAddress.location}
							</span>
						</Typography>
						<div className="flex mt-5">
							<Button
								large
								variant="contained"
								color="secondary"
								className="mr-4"
								onClick={() => navigate('./about-me')}
							>
								Tour the Home
							</Button>
							<Button variant="outlined" large>
								Submit an Offer
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div className="max-w-[1350px] mx-auto">
				<div className="max-w-[480px] mx-auto my-20 ">
					<Typography className="font-bold text-[#365584] text-xl text-center">
						From the Agent, {agent.name}
					</Typography>
					<Typography className="mt-4 font-semibold text-center text-lg">
						"This is a beautiful 4 bedroom property, with pool, and backyard
						deck. Close to schools and 15 freeway, with wonderful views of San
						Diego Valley"
					</Typography>
				</div>
				<div className="max-w-[480px] mx-auto mt-8 mb-24">
					<Typography className="font-black text-2xl text-center">
						Watch the Property Tour
					</Typography>
					<div className="max-w-[300px] shadow-lg rounded-3xl mx-auto mt-8 overflow-hidden badhous-image">
						<Image height="100%" width="100%" src={SAMPLE_HOME_PHOTO} />
					</div>
					<Button
						variant="outlined"
						startIcon={
							<YouTube color="secondary" sx={{ fontSize: '28px !important' }} />
						}
						className="mt-5 mx-auto flex"
					>
						Watch Property Tour
					</Button>
				</div>
				<div className="flex flex-wrap justify-center sm:justify-start px-[.5%] md:px-10">
					{propertyPhotos.map((photo, index) => {
						return (
							<div
								key={index}
								className={`w-[220px] xs:w-[49%] sm:w-[32.3%] md:w-[24%] m-3 xs:m-[.5%] xs:ml-0 xs:mr-[1%] badhous-image`}
							>
								<Image src={photo} />
							</div>
						)
					})}
				</div>
				<div className="px-[5] md:px-10 my-16">
					<Typography className="w-full text-lg font-black mb-5">
						Overview & Features
					</Typography>
					<Typography className="text-lg font-semibold mb-10">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sit
						amet ultricies lorem. Ut luctus elementum justo, quis tincidunt nisl
						efficitur sed. Proin nec leo nec risus dignissim venenatis a at
						nulla. Nulla sodales nec erat egestas lobortis. In id orci at est
						eleifend placerat. Nunc bibendum urna hendrerit, sodales urna eget,
						fermentum nisi. Nullam orci enim, molestie id placerat ut, porta sed
						urna. Ut commodo porttitor sem, quis aliquet urna. Nulla facilisi.
						Praesent eget interdum felis, sit amet mattis mauris. Aliquam
						ultrices felis dapibus ante rhoncus ultrices. Etiam rhoncus leo
						enim, non faucibus nisl efficitur etc.
					</Typography>
					<div className="flex flex-wrap justify-center sm:justify-start">
						{propertyFeatures.map((feature, index) => {
							return (
								<div
									key={index}
									className={`w-[220px] xs:w-[49%] sm:w-[32.3%] md:w-[24%] m-3 xs:m-[.5%] xs:ml-0 xs:mr-[1%] badhous-image`}
								>
									<DataItem title={feature} outline />
								</div>
							)
						})}
					</div>
				</div>
				<div className="mt-16 pl-5 pr-1 md:px-10 pt-16 border-t border-solid border-gray-200 mb-28">
					<Typography variant="h5" className="font-black mb-5 m_md:text-center">
						More Homes For Sale
					</Typography>
					<div className="flex flex-wrap m_md:justify-center">
						<ActiveListings route={baseRoute} listings={filteredListings} />
					</div>
				</div>
			</div>
		</>
	)
}
