import React, { FC } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { Button, MenuButton } from '../atoms'
import { getRoutes, getUser } from 'utils'
import { Logout } from '@mui/icons-material'

interface Props {
	type: 'agent' | 'brokerage'
}

export const NavSidebar = ({ type }: Props) => {
	const routes = getRoutes(type)
	const profile = getUser()
	const navigate = useNavigate()
	const location = useLocation()

	return (
		<Box
			width="185px"
			marginRight={4}
			className="md:fixed md:h-[100vh] left-0 top-0 pt-[80px] box-border badhous-nav-sidebar"
		>
			{routes.map(route => {
				if (route.name && route.path) {
					const isActive =
						route.name === 'Important Stuff'
							? location.pathname.indexOf('/important-stuff/') === 0
							: location.pathname === route.path

					if (!profile.user.brokerageUuid && route.name === 'Brokerage') {
						return null
					}

					return (
						<MenuButton
							key={route.path}
							active={isActive}
							onClick={() => {
								navigate(route.path)
							}}
						>
							{route.name}
						</MenuButton>
					)
				} else if (route.secondary) {
					return (
						<div key="secondary">
							<Typography className="font-black text-gray-500 pl-5 py-2 mb-2">
								{route.secondary.title}
							</Typography>
							{route.secondary.routes.map(secondaryRoute => {
								const isActive = location.pathname === secondaryRoute.path
								return (
									<MenuButton
										key={secondaryRoute.path}
										secondary
										active={isActive}
										onClick={() => {
											navigate(secondaryRoute.path)
										}}
									>
										{secondaryRoute.name}
									</MenuButton>
								)
							})}
						</div>
					)
				}
				return null
			})}
			<div className="absolute md:bottom-[20px] w-full">
				<Button
					variant="sidebar"
					fullWidth
					endIcon={<Logout />}
					onClick={() => navigate('/sign-in')}
					sx={{
						'&:hover *': {
							color: '#333333 !important',
						},
						'& p': {
							marginBottom: '0 !important',
						},
					}}
				>
					Sign Out
				</Button>
			</div>
		</Box>
	)
}
