import React, { FC } from 'react'
import { Card as MuiCard, Box, Typography } from '@mui/material'
import { CardFooterButton } from './card-footer-button'
import { Image } from './image'
import { Tooltip } from './tooltip'

interface Props {
	uuid: string
	description: string
	address: string
	imageUrl?: string
	adminViewer?: boolean
	listingOnline?: boolean
	onChangeVisibility?: (uuid: string, listingOnline: boolean) => void
	onClick?: () => void
}

export const PropertyCard: FC<Props> = ({
	uuid,
	description,
	address,
	imageUrl,
	adminViewer = false,
	listingOnline = false,
	onChangeVisibility,
	onClick,
}) => {
	const handleView = () => {
		if (onClick) {
			onClick()
		}
	}

	const handleChangeVisibility = (e: React.MouseEvent) => {
		e.stopPropagation()
		if (onChangeVisibility) {
			onChangeVisibility(uuid, !listingOnline)
		}
	}

	const handleEdit = (e: React.MouseEvent) => {
		e.stopPropagation()
		console.log('edit')
	}

	const cardClass = `relative p-1 ${
		adminViewer ? '' : 'pb-6 '
	}transition duration-200 rounded-lg border border-solid border-gray-300 shadow-lg hover:border-blue-500 cursor-pointer mr-[10px] w-[240px]`

	return (
		<MuiCard className={cardClass} onClick={handleView}>
			{!listingOnline && (
				<div className="absolute w-[100px] left-[75px] top-[8px] rounded-md bg-[#fff] z-[99] shadow-lg">
					<Typography className="font-semibold text-center text-[#333] leading-[40px]">
						Hidden
					</Typography>
				</div>
			)}
			<Image fit="cover" src={imageUrl} height={150} />
			<Box>
				<Typography
					variant="subtitle2"
					className="mt-4 mb-2 font-bold text-center max-w-[190px] mx-auto text-gray-600"
				>
					{description}
				</Typography>
				<Tooltip title={address}>
					<Typography
						variant="subtitle1"
						className="font-bold text-center max-w-[190px] mx-auto line-clamp-2"
					>
						{address}
					</Typography>
				</Tooltip>
			</Box>
			{adminViewer && (
				<Box className="mt-6 ml-[-4px] mr-[-4px] flex items-center justify-between border-t border-solid border-gray-300">
					<CardFooterButton onClick={handleChangeVisibility} fullWidth>
						{listingOnline ? 'Hide' : 'Show'}
					</CardFooterButton>
					<CardFooterButton onClick={handleEdit} fullWidth>
						Edit
					</CardFooterButton>
				</Box>
			)}
		</MuiCard>
	)
}
