import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import {
	DataGrid,
	DataGridProps,
	GridSelectionModel,
	GridSlotsComponent,
} from '@mui/x-data-grid'
import { Button } from 'components'

type DataTableProps = Omit<
	DataGridProps,
	'components' | 'className' | 'onSelectionModelChange' | 'autoHeight'
>

interface Props extends DataTableProps {
	components?: Omit<
		GridSlotsComponent,
		'NoRowsOverlay' | 'NoResultsOverlay' | 'Pagination'
	>
	dataContext: string
	onNextPage?: () => void
	onPreviousPage?: () => void
	onRowSelected?: (gridSelectionModel: GridSelectionModel) => void
	disableNext?: boolean
	disablePrevious?: boolean
	renderOnSelection?: React.ReactNode
	disableHeaderActions?: boolean
}

export const Table: FC<Props> = ({
	rows,
	rowHeight,
	components,
	dataContext,
	onNextPage,
	onPreviousPage,
	onRowSelected,
	disableNext,
	disablePrevious,
	renderOnSelection,
	disableHeaderActions = false,
	...rest
}) => {
	const tableAutoHeight = (rows.length || 1) * (rowHeight || 65) + 111
	const [selectedRows, setSelectedRows] = React.useState<GridSelectionModel>([])

	const handleSelectionModelChange = (selectionModel: GridSelectionModel) => {
		setSelectedRows(selectionModel)
		onRowSelected && onRowSelected(selectionModel)
	}

	const noRowsClass =
		'inset-0 top-[65px] absolute flex self-center items-center justify-center text-gray-800 bg-white'

	return (
		<Box
			className="w-full shadow-lg bg-white rounded-lg overflow-hidden"
			sx={{ height: tableAutoHeight > 320 ? tableAutoHeight : 320 }}
		>
			<DataGrid
				{...rest}
				rows={rows}
				rowHeight={rowHeight}
				onSelectionModelChange={handleSelectionModelChange}
				components={{
					...components,
					NoRowsOverlay: () => (
						<Typography className={noRowsClass}>No {dataContext}</Typography>
					),
					NoResultsOverlay: () => (
						<Typography className={noRowsClass}>
							No {dataContext} found
						</Typography>
					),
					Pagination: () => (
						<Box
							width="100%"
							display="flex"
							alignItems="center"
							justifyContent="flex-start"
						>
							<Box>{selectedRows.length ? renderOnSelection : null}</Box>
							{onPreviousPage && onNextPage ? (
								<Box className="ml-auto mr-0">
									<Button
										className="w-24"
										variant="outlined"
										disabled={disablePrevious}
										onClick={() => onPreviousPage()}
									>
										Previous
									</Button>
									<Button
										className="ml-2 w-24"
										variant="outlined"
										disabled={disableNext}
										onClick={() => onNextPage()}
									>
										Next
									</Button>
								</Box>
							) : null}
						</Box>
					),
				}}
			/>
		</Box>
	)
}
