import React, { FC } from 'react'
import {
	Dialog,
	Typography,
	DialogProps as MuiDialogProps,
	Breakpoint,
	useTheme,
	useMediaQuery,
	DialogActions,
	Slide,
} from '@mui/material'
import { ColorDot, IconButton, VideoPlayer } from 'components'
import { Close } from '@mui/icons-material'
import { TransitionProps } from '@mui/material/transitions/transition'

type DialogProps = {
	title?: string
	subtitle?: string
	subtitleTransform?: boolean
	colorDot?: string
	showClose?: boolean
	content?: React.ReactNode
	actions?: React.ReactNode
	video?: string
	footer?: React.ReactNode
	className?: string
} & Pick<MuiDialogProps, 'maxWidth' | 'fullWidth'>

interface Props {
	dialogOpen: boolean
	closed: boolean
	canceled: boolean
	setDialogOpen: (props: DialogProps) => void
	setDialogClosed: (canceled?: boolean) => void
}

export const DialogContext = React.createContext<Props>({
	dialogOpen: false,
	closed: false,
	canceled: false,
	setDialogOpen: () => {},
	setDialogClosed: () => {},
})

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

export const DialogProvider: FC<{ children: React.ReactNode }> = ({
	children,
}): JSX.Element => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [open, setOpen] = React.useState(false)
	const [closed, setClosed] = React.useState(false)
	const [canceled, setCanceled] = React.useState(false)
	const [content, setContent] = React.useState<React.ReactNode | undefined>()
	const [actions, setActions] = React.useState<React.ReactNode | undefined>()
	const [colorDot, setColorDot] = React.useState<string | undefined>()
	const [video, setVideo] = React.useState('')
	const [title, setTitle] = React.useState<string | undefined>()
	const [subtitle, setSubtitle] = React.useState<string | undefined>()
	const [subtitleTransform, setSubtitleTransform] = React.useState<
		boolean | undefined
	>()
	const [showClose, setShowClose] = React.useState<boolean | undefined>(false)
	const [footer, setFooter] = React.useState<React.ReactNode>()
	const [className, setClassName] = React.useState<string>()
	const [maxWidth, setMaxWidth] = React.useState<
		Breakpoint | false | undefined
	>(false)
	const [fullWidth, setFullWidth] = React.useState<boolean>(false)

	const handleOpen = (props: DialogProps) => {
		setOpen(true)
		setCanceled(false)
		setClosed(false)

		if (props.content) {
			setContent(props.content)
		}
		if (props.actions) {
			setActions(props.actions)
		}
		if (props.colorDot) {
			setColorDot(props.colorDot)
		}
		if (props.video) {
			setVideo(props.video)
		}
		if (props.title) {
			setTitle(props.title)
		}
		if (props.subtitle) {
			setSubtitle(props.subtitle)
		}
		if (props.subtitleTransform) {
			setSubtitleTransform(props.subtitleTransform)
		}
		if (props.showClose) {
			setShowClose(props.showClose)
		}
		if (props.maxWidth) {
			setMaxWidth(props.maxWidth)
		}
		if (props.fullWidth) {
			setFullWidth(props.fullWidth)
		}
		if (props.footer) {
			setFooter(props.footer)
		}
		if (props.className) {
			setClassName(props.className)
		}
	}

	const handleClose = () => {
		reset()
		setCanceled(true)
	}

	const reset = () => {
		setOpen(false)
		setColorDot(undefined)
		setContent(undefined)
		setActions(undefined)
		setVideo('')
		setMaxWidth(false)
		setFullWidth(false)
		setTitle(undefined)
		setSubtitle(undefined)
		setSubtitleTransform(undefined)
		setClassName('')
		setClosed(true)
	}

	return (
		<DialogContext.Provider
			value={{
				dialogOpen: open,
				closed,
				canceled,
				setDialogOpen: handleOpen,
				setDialogClosed: reset,
			}}
		>
			<Dialog
				fullScreen={isMobile}
				open={open}
				maxWidth={maxWidth}
				fullWidth={fullWidth}
				className={`badhous-${className}`}
				TransitionComponent={Transition}
			>
				{title && (
					<div
						className={`px-4 pb-2 pt-2 flex border-solid border-b border-b-gray-200 border-t-[3px] border-t-[#333] sm:border-t-0 items-center justify-between`}
					>
						<div>
							<Typography
								className="font-bold flex items-center capitalize"
								sx={{ fontSize: isMobile ? 15 : 18 }}
							>
								{title}
								{subtitle && !colorDot && (
									<span
										className={`inline text-gray-600 font-bold pl-1 ${
											!subtitleTransform ? 'normal-case' : ''
										}`}
										style={{ fontSize: isMobile ? 14 : 16 }}
									>
										• {subtitle}
									</span>
								)}
								{colorDot && !subtitle && (
									<span className="block ml-3">
										<ColorDot color={colorDot} />
									</span>
								)}
							</Typography>
						</div>
						<div className="flex items-center">
							{actions && <div>{actions}</div>}
							{showClose && (
								<IconButton
									onClick={() => handleClose()}
									tooltip="Close Window"
									className="ml-4"
								>
									<Close />
								</IconButton>
							)}
						</div>
					</div>
				)}
				{video ? <VideoPlayer src={video} id="listing-profile" /> : content}
				{footer && (
					<DialogActions className="badhous-dialog-sticky-footer">
						{footer}
					</DialogActions>
				)}
			</Dialog>
			{children}
		</DialogContext.Provider>
	)
}
