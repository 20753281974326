import React from 'react'
import uuid from 'react-uuid'
import { Box } from '@mui/material'
import { AddNewButton, ContentBlock, FAQBlock, IconTag } from 'components'
import { useFAQ, useSaveChanges } from 'providers'
import { AgentSetSettingsData, AgentSetWebsiteSettings } from 'services'
import { getUser, setUser } from 'utils'
import { FAQBlockType } from 'types'
import { isListingProfileComplete } from '../helpers'

export const FAQ = () => {
	const profile = getUser()
	const { faq, updateFAQ, setFAQ } = useFAQ()
	const {
		save,
		canceled,
		setCanceled,
		setSave,
		unsavedChanges,
		setUnsavedChanges,
	} = useSaveChanges()

	const saveFAQ = React.useCallback(async () => {
		const newProfile = getUser()
		newProfile.settings.listingProfile.faq = faq
		newProfile.website.settings.faq = faq
		newProfile.settings.listingProfile.completed.faq = true

		const updates = [
			{
				key: 'listingProfile',
				value: newProfile.settings.listingProfile,
			},
		]

		if (isListingProfileComplete(newProfile)) {
			newProfile.settings.onboarding.steps[1].complete = true

			updates.push({
				key: 'onboarding',
				value: newProfile.settings.onboarding,
			})
		}

		const result = await AgentSetSettingsData({
			agentUuid: profile.user.uuid,
			updates,
		})

		const result2 = await AgentSetWebsiteSettings({
			agentUuid: profile.user.uuid,
			settings: newProfile.website.settings,
		})

		if (result?.status === 'success' && result2?.status === 'success') {
			setSave(false)
			setUser(newProfile)
		}
	}, [profile, faq, setSave])

	const handleAddNew = () => {
		updateFAQ(faq.length, { key: uuid(), question: '', answer: '' })
	}

	const handleChange = (index: number, newFAQBlock: FAQBlockType) => {
		updateFAQ(index, newFAQBlock)
	}

	const handleDelete = (faqIndex: number) => {
		if (!unsavedChanges) {
			setUnsavedChanges()
		}
		updateFAQ(faqIndex)
	}

	React.useEffect(() => {
		if (save) {
			saveFAQ()
		}
	}, [save, saveFAQ])

	React.useEffect(() => {
		if (canceled) {
			setFAQ(getUser().settings.listingProfile.faq)
			setCanceled()
		}
	}, [setFAQ, setCanceled, canceled])

	return (
		<Box className="pt-2 mx-auto max-w-[680px] md:mx-0 md:max-w-full">
			<div className="mb-8 max-w-[600px]">
				<ContentBlock
					label="Frequently Asked Questions"
					description="Add common questions and answers your clients may have for you. This will display on your website and when clients schedule a call with you."
					tag={
						!profile.settings.listingProfile.completed.faq && (
							<IconTag icon="Warning" text="Add some FAQ!" color="#f48225" />
						)
					}
				/>
			</div>
			{faq.map((faqBlock: FAQBlockType, index: number) => {
				return (
					<FAQBlock
						key={faqBlock.key}
						faqBlock={faqBlock}
						faqIndex={index}
						onChange={newFaqBlock => handleChange(index, newFaqBlock)}
						onDelete={handleDelete}
					/>
				)
			})}
			<div className={`mr-12 ${faq.length ? 'ml-12 max-w-[782px]' : ''}`}>
				<AddNewButton
					text={`Add ${faq.length ? 'another' : 'a'} FAQ`}
					onClick={handleAddNew}
				/>
			</div>
		</Box>
	)
}
