import React, { TextareaHTMLAttributes } from 'react'

interface Props
	extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'style'> {
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
	fontBold?: boolean
	style?: object
}

export const TextArea = ({
	id,
	onChange,
	fontBold,
	style = {},
	...rest
}: Props) => {
	const [curValue, setCurValue] = React.useState(rest.value || '')
	const [textarea, setTextarea] = React.useState<HTMLTextAreaElement | null>(
		null
	)

	const handleResize = (event: any) => {
		if (textarea && event) {
			if (event.target.value.length < curValue.toString().length) {
				textarea.style.height = 'auto'
				textarea.style.height = textarea.scrollHeight + 'px'
			}

			const diff = Math.abs(textarea.clientHeight - textarea.scrollHeight)
			if (diff > 7) {
				textarea.style.height = 'auto'
				textarea.style.height = textarea.scrollHeight + 'px'
			}
		}
	}

	React.useEffect(() => {
		if (id) {
			const textareaEl = document.querySelector(
				`#${id}`
			) as HTMLTextAreaElement | null

			if (textareaEl) {
				const diff = Math.abs(textareaEl.clientHeight - textareaEl.scrollHeight)
				textareaEl.style.height = 'auto'

				if (diff > 37) {
					textareaEl.style.height = textareaEl.scrollHeight + 12 + 7 + 'px'
				} else {
					textareaEl.style.height = textareaEl.scrollHeight + 4 + 'px'
				}
			}

			setTextarea(textareaEl)
		}
	}, [])

	return (
		<div
			className="badhous-textarea bg-transparent overflow-hidden rounded-md border border-solid border-[#bfbfc0] p-3 shadow-md"
			style={{ ...style }}
		>
			<textarea
				{...rest}
				rows={1}
				id={id}
				onInput={handleResize}
				onChange={e => {
					setCurValue(e.target.value)
					onChange(e)
				}}
			/>
		</div>
	)
}
