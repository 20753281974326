import React, { FC } from 'react'
import uuid from 'react-uuid'
import { AgentGetLeads } from 'services'
import {
	LeadConversionBlockType,
	LeadConversionIndexType,
	LeadConversionType,
} from 'types'
import { getUser, setUser } from 'utils'

const INITIAL_STATE = {
	prospects: [],
	scheduled: [],
	waiting: [],
	archived: [],
}

interface Props {
	leads: LeadConversionType
	lead?: LeadConversionBlockType
	getLeads: () => void
	setLeads: (leads: LeadConversionType) => void
	setLead: (
		lead: LeadConversionBlockType,
		newDestination?: LeadConversionIndexType,
		archive?: number
	) => void
	deleteLead: (lead: LeadConversionBlockType) => void
}

export const LeadsContext = React.createContext<Props>({
	leads: INITIAL_STATE,
	lead: undefined,
	getLeads: () => {},
	setLeads: () => {},
	setLead: () => {},
	deleteLead: () => {},
})

export const LeadsProvider: FC<{ children: React.ReactNode }> = ({
	children,
}): JSX.Element => {
	const profile = getUser()
	const [lead, setLead] = React.useState<LeadConversionBlockType>()
	const [leads, setLeads] = React.useState<LeadConversionType>(INITIAL_STATE)

	const handleGetLeads = async () => {
		const result = await AgentGetLeads({
			agentUuid: profile.user.uuid,
		})

		Object.keys(result.leads).forEach(key => {
			result.leads[key] = result.leads[key].map(
				(item: LeadConversionBlockType) => {
					return {
						key: uuid(),
						...item,
					}
				}
			)
		})

		if (result.status === 'success') {
			const sortedLeads = sortLeads(result.leads)
			setLeads(sortedLeads)
			setUser({
				...profile,
				leads: result.leads,
			})
		}
	}

	const handleSetLeads = (updatedLeads: LeadConversionType) => {
		const sortedLeads = sortLeads(updatedLeads)
		setLeads(sortedLeads)
		setUser({
			...profile,
			leads: updatedLeads,
		})
	}

	const handleSetLead = (
		currentLead: LeadConversionBlockType,
		newDestination?: LeadConversionIndexType,
		archive?: number
	) => {
		if (newDestination || archive !== undefined) {
			const newLeads = { ...leads }
			let finalLeadPosition: LeadConversionIndexType = 'archived'

			if (archive === 1) {
				newLeads[currentLead.label].splice(currentLead.index, 1)

				currentLead.archived = archive

				newLeads.archived.push({
					...currentLead,
				})
			} else if (archive === 0) {
				newLeads.archived.splice(currentLead.index, 1)

				currentLead.archived = archive

				newLeads[currentLead.label].push({
					...currentLead,
				})

				finalLeadPosition = currentLead.label
			} else if (newDestination) {
				newLeads[currentLead.label].splice(currentLead.index, 1)

				currentLead.label = newDestination

				newLeads[newDestination].push({
					...currentLead,
				})

				finalLeadPosition = newDestination
			}

			const sortedLeads = sortLeads(newLeads)

			let updatedLead: LeadConversionBlockType | null = null

			sortedLeads[finalLeadPosition].some(lead => {
				if (lead.uuid === currentLead.uuid) {
					updatedLead = lead
				}
			})

			if (updatedLead) {
				setLead(updatedLead)
				setLeads(sortedLeads)
			} else {
				console.error(`lead update error: ${currentLead.uuid}`)
			}
		} else {
			setLead(currentLead)
		}
	}

	const handleDeleteLead = (currentLead: LeadConversionBlockType) => {
		const newLeads = { ...leads }
		if (currentLead.archived) {
			newLeads.archived.splice(currentLead.index, 1)
		} else {
			newLeads[currentLead.label].splice(currentLead.index, 1)
		}
		const sortedLeads = sortLeads(newLeads)
		setLead(undefined)
		setLeads(sortedLeads)
	}

	const sortLeads = (newLeads: LeadConversionType) => {
		const sortedLeads: LeadConversionType = newLeads
		sortedLeads.prospects.sort(function (a, b) {
			const x = a.name.toUpperCase()
			const y = b.name.toUpperCase()

			return x == y ? 0 : x > y ? 1 : -1
		})

		sortedLeads.scheduled.sort((a, b) => {
			const dateA = new Date(a.meeting.date).getTime()
			const dateB = new Date(b.meeting.date).getTime()
			return dateA - dateB
		})

		sortedLeads.waiting.sort(function (a, b) {
			const x = a.name.toUpperCase()
			const y = b.name.toUpperCase()

			return x == y ? 0 : x > y ? 1 : -1
		})

		sortedLeads.archived.sort(function (a, b) {
			const x = a.name.toUpperCase()
			const y = b.name.toUpperCase()

			return x == y ? 0 : x > y ? 1 : -1
		})

		const prospects = sortedLeads.prospects.map((lead, index) => {
			return {
				...lead,
				index,
			}
		})
		const scheduled = sortedLeads.scheduled.map((lead, index) => {
			return {
				...lead,
				index,
			}
		})
		const waiting = sortedLeads.waiting.map((lead, index) => {
			return {
				...lead,
				index,
			}
		})
		const archived = sortedLeads.archived.map((lead, index) => {
			return {
				...lead,
				index,
			}
		})

		return {
			prospects,
			scheduled,
			waiting,
			archived,
		}
	}

	return (
		<LeadsContext.Provider
			value={{
				leads,
				lead,
				getLeads: handleGetLeads,
				setLeads: handleSetLeads,
				setLead: handleSetLead,
				deleteLead: handleDeleteLead,
			}}
		>
			{children}
		</LeadsContext.Provider>
	)
}
