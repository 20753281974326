import React from 'react'
import { Accordion, AuthHeader, Avatar, Page } from 'components'
import { useNavigate } from 'react-router-dom'
import { getLead } from 'utils'

export const WaitingRoom = () => {
	const { agent, user } = getLead() as any
	const navigate = useNavigate()

	React.useEffect(() => {
		if (!agent.uuid || !user.email) {
			navigate('/sign-in')
		}
	}, [user, navigate])

	if (!user.email) {
		return null
	}

	let header = (
		<AuthHeader
			title={agent.brokerage.name || `${agent.first} ${agent.last}`}
		/>
	)

	return (
		<Page header={header}>
			<Accordion title="Meeting Details" expanded>
				<div>
					<Avatar
						text={!agent.photo ? agent.first[0] : undefined}
						src={agent.photo || undefined}
					/>
				</div>
			</Accordion>
			<div>waiting room</div>
		</Page>
	)
}
