import React from 'react'
import { ContentBlock, LeadScheduler, PopoverButton } from 'components'
import { BuyerOrSellerType, LeadConversionBlockType } from 'types'
import { format } from 'date-fns'
import { Typography, TextField } from '@mui/material'
import { formatInTimeZone } from 'date-fns-tz'
import { useDebounce } from 'utils'
import { AgentSetLead } from 'services'
import { useSnackbar } from 'notistack'
import { updateLead } from '../helpers'

type LeadBlockType = LeadConversionBlockType

interface Props {
	lead: LeadConversionBlockType
	onSuccess?: (lead: LeadBlockType) => void
}

export const DialogMeeting = ({ lead, onSuccess }: Props) => {
	const { enqueueSnackbar } = useSnackbar()

	const [scheduledCall, setScheduledCall] = React.useState(
		lead.meeting.scheduled
	)
	const [meetingLink, setMeetingLink] = React.useState<string | undefined>(
		lead.meeting.link
	)
	const debouncedMeetingLink = useDebounce(meetingLink, 600)

	const formattedDateTime = scheduledCall
		? format(new Date(lead.meeting.date), 'eee, MMM do @ h:mma')
		: null

	const timeZone = scheduledCall
		? formatInTimeZone(
				new Date(),
				lead.meeting.timezone.replace(/ /g, '_'),
				'zzz'
		  )
		: null

	const handleRescheduleMeeting = () => {}

	const popoverButton = (
		<Typography
			className="mt-6 underline font-semibold cursor-pointer"
			onClick={handleRescheduleMeeting}
		>
			Reschedule Meeting
		</Typography>
	)

	const handleConfirmReschedule = () => {
		console.log('confirmed')
	}

	const handleUpdateMeeting = async (meetingLink: string) => {
		const updatedMeeting = {
			...lead.meeting,
			link: meetingLink,
		}

		const result = await updateLead(lead.uuid, [
			{
				key: 'meeting',
				value: updatedMeeting,
			},
		])

		if (result.error) {
			enqueueSnackbar(result.error, { variant: 'error' })
			return
		}
		if (onSuccess) {
			onSuccess({
				...lead,
				meeting: updatedMeeting,
			})
		}
		enqueueSnackbar('Meeting link updated!', { variant: 'success' })
	}

	React.useEffect(() => {
		if (debouncedMeetingLink !== lead.meeting.link) {
			handleUpdateMeeting(debouncedMeetingLink)
		}
	}, [debouncedMeetingLink])

	return (
		<div className="px-5 md:h-[356px] md:max-h-[356px] box-border">
			{!scheduledCall ? (
				<LeadScheduler lead={lead} />
			) : (
				<>
					<div className="pb-5 border-b border-solid border-gray-200">
						<ContentBlock
							label={`Your meeting with ${lead.name}`}
							description={`${formattedDateTime} ${timeZone}`}
						/>
						<PopoverButton
							className="p-5 max-w-[250px]"
							customButton={popoverButton}
							onPromptButtonClick={handleConfirmReschedule}
						>
							<Typography className="font-bold text-lg">Reschedule?</Typography>
							<Typography className="mt-3 font-semibold">
								<span className="font-bold">
									This meeting will be canceled.
								</span>{' '}
								A link to select a new date & time will be sent via email to{' '}
								{lead.name}.
							</Typography>
						</PopoverButton>
					</div>
					<div className="mt-5">
						<TextField
							fullWidth
							name="meetingLink"
							label="Video meeting link (optional)"
							value={meetingLink}
							onChange={e => setMeetingLink(e.target.value)}
						/>
					</div>
				</>
			)}
		</div>
	)
}
