import React from 'react'
import { LeadConversionBlockType, MeetingsType } from 'types'
import { ContentBlock, Avatar, Button, Image } from 'components'
import { DialogForm } from 'views'
import { Typography } from '@mui/material'
import { AccessTimeFilled, Article, YouTube } from '@mui/icons-material'
import { BUYER_PNG, SELLER_PNG } from 'lib/theme'

interface Props {
	lead: Omit<LeadConversionBlockType, 'type'>
	photo: string
	callDuration: string
	meetingTime: string
	meetings: MeetingsType
	onSave: (
		lead: LeadConversionBlockType,
		meetings: MeetingsType,
		meetingTime: string,
		leadType: 'buyer' | 'seller'
	) => void
}

export const SchedulerForm = ({
	lead,
	photo,
	callDuration,
	meetingTime,
	meetings,
	onSave,
}: Props) => {
	const [leadType, setLeadType] = React.useState<'buyer' | 'seller'>()

	return leadType ? (
		<div className="py-8 flex flex-wrap m_md:justify-center">
			<div className="m_xs:pl-5 sm:pl-10 pr-5 max-w-[275px] m_md:mx-auto">
				<Avatar src={photo} border center />
				<Typography className="mt-4 mx-auto max-w-[210px] font-bold text-center">
					Welcome! I look forward to speaking with you soon.
				</Typography>
				<Typography className="mt-4 font-bold flex items-center justify-center">
					<AccessTimeFilled sx={{ marginRight: '4px' }} />
					{callDuration}
				</Typography>
				<Button
					fullWidth
					variant="outlined"
					startIcon={
						<YouTube color="secondary" sx={{ fontSize: '28px !important' }} />
					}
					className="mt-5 mb-3 mx-auto flex"
				>
					Watch Welcome Video
				</Button>
			</div>
			<div className="m_md:mt-12">
				<div className="px-5 m_md:max-w-[400px] mx-auto">
					<ContentBlock label="Meeting Date & Time" description={meetingTime} />
				</div>
				<DialogForm
					lead={{
						...lead,
						type: leadType,
					}}
					onSave={newLead => {
						onSave(newLead, meetings, meetingTime, leadType)
					}}
					publicViewer
				/>
			</div>
		</div>
	) : (
		<div className="p-5 md:w-[400px] md:pt-6 md:pb-10">
			<Typography className="mb-6 text-xl font-bold text-center">
				Are you Buying or Selling?
			</Typography>
			<div className="flex justify-center items-center">
				<div
					className="badhous-icon-button"
					onClick={() => setLeadType('buyer')}
				>
					<Image src={BUYER_PNG} width={64} center />
					<Typography className="mt-2 font-semibold">I'm Buying</Typography>
				</div>
				<div
					className="badhous-icon-button ml-2"
					onClick={() => setLeadType('seller')}
				>
					<Image src={SELLER_PNG} width={64} center />
					<Typography className="mt-2 font-semibold">I'm Selling</Typography>
				</div>
			</div>
		</div>
	)
}
