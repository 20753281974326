import {
	CheckedItemListType,
	DayType,
	ForceGenericObjectType,
	OnboardingStep,
	UserAgentType,
} from './index'

export type AgentSettingsType = {
	account: UserAgentType
	availability: any
	checklists: any
	listingProfile: any
	onboarding: {
		complete: boolean
		completedSteps: number
		steps: OnboardingStep[]
	}
	routine: CheckedItemListType[]
}

export type AvailabilityBlockType = {
	startTime: string
	endTime: string
}

export type AvailabilityDateType = {
	day: DayType
	checked: boolean
	blocks: AvailabilityBlockType[]
}

export type AvailabilityType = {
	stepInMinutes: number
	timezone: string
	data: AvailabilityDateType[]
}

export type FAQBlockType = {
	key: string
	question: string
	answer: string
}

export type FAQType = FAQBlockType[]

export type QuestionnaireBlockType = {
	freeResponse: boolean
	question: string
	answers: string[]
}

export type QuestionnaireType = QuestionnaireBlockType[]

export type RecentListingCardType = {
	line1: string
	line2: string | undefined
	city: string
	state: string
	zip: string
	closePrice: string
	timeOnMarket: string
}

export type RecentListingType = RecentListingCardType[]

export type ExperienceChipType = {
	label: string
	color: string
	checked: boolean
}

export type ExperienceType = ExperienceChipType[]

export type LeadConversionIndexType =
	| 'prospects'
	| 'scheduled'
	| 'waiting'
	| 'archived'

export type BuyerOrSellerType = 'buyer' | 'seller'

export type LeadConversionBlockType = {
	key?: string
	index: number
	label: LeadConversionIndexType
	type: BuyerOrSellerType
	uuid: string
	agentUuid: string
	name: string
	email: string
	phone: string
	source: string
	meeting: {
		scheduled: boolean
		date: string
		timezone: string
		region: string
		link?: string
	}
	contract: {
		signed: boolean
		publicUrl: string
		label: string
		filename: string
		key: string
	}
	questionnaire: object
	schedulingAttempt: Date
	followUpAttempt: Date
	archived: number
}

export type LeadConversionType = {
	prospects: LeadConversionBlockType[]
	scheduled: LeadConversionBlockType[]
	waiting: LeadConversionBlockType[]
	archived: LeadConversionBlockType[]
}

export type LeadDialogType = {
	title: string
	lead: LeadConversionBlockType
	leadIndex: number
	source: LeadConversionIndexType
	destination: LeadConversionIndexType
}

export type LeadMeetingLinkEmail = {
	agentUuid: string
	agentName: string
	leads: {
		leadUuid: string
		leadName: string
		leadEmail: string
		updates: ForceGenericObjectType[]
	}[]
}

export enum AgentLeadActionEnum {
	MOVE_FORWARD = 1,
	MEET_AGAIN = 2,
	ARCHIVE = 3,
	REMIND_ME_LATER = 4,
	SCHEDULE_MEETING = 5,
	FOLLOW_UP = 6,
}

export enum LeadConversionLabelEnum {
	'prospects' = 'Prospects',
	'scheduled' = 'Meetings',
	'waiting' = 'Contract Waiting',
	'archived' = 'Archive',
}

export enum QuestionnaireEnum {
	SELLER_QUESTIONNAIRE = 'sellerQuestionnaire',
	BUYER_QUESTIONNAIRE = 'buyerQuestionnaire',
}
