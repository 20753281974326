import React from 'react'
import { Article, Public, YouTube } from '@mui/icons-material'
import { UploadBlock } from './upload-block'
import { AgentSetSettingsData, AgentSetWebsiteSettings } from 'services'
import { getUser, setUser } from 'utils'
import { useSnackbar } from 'notistack'
import { isListingProfileComplete } from '../helpers'

export const FileUploads = () => {
	const [profile, setProfile] = React.useState<any>(getUser())
	const { enqueueSnackbar } = useSnackbar()

	const handleLinkUpdated = async (link: string) => {
		const context = 'Tips & Resources Link'
		const newListingProfile = getUser().settings.listingProfile

		newListingProfile.tipsAndResources.link = link

		handleSave(newListingProfile, context)
	}

	const handleUploadComplete = (
		publicUrl: string,
		filename: string,
		label: string,
		key: string,
		context: string,
		type: string
	) => {
		const newProfile = getUser()

		newProfile.settings.listingProfile[type] = {
			publicUrl,
			filename,
			label,
			key,
		}
		newProfile.settings.listingProfile.completed[type] = true

		let shouldUpdateWebsite = false
		if (context !== 'tipsAndResources') {
			newProfile.website.settings[type] = publicUrl
			shouldUpdateWebsite = true
		}

		handleSave(newProfile, context, shouldUpdateWebsite)
	}

	const handleSave = async (
		newProfile: any,
		context: string,
		websiteUpdated?: boolean
	) => {
		const updates = [
			{
				key: 'listingProfile',
				value: newProfile.settings.listingProfile,
			},
		]

		if (isListingProfileComplete(newProfile)) {
			newProfile.settings.onboarding.steps[1].complete = true

			updates.push({
				key: 'onboarding',
				value: newProfile.settings.onboarding,
			})
		}

		const result = await AgentSetSettingsData({
			agentUuid: profile.user.uuid,
			updates,
		})

		let result2 = undefined
		if (websiteUpdated) {
			result2 = await AgentSetWebsiteSettings({
				settings: newProfile.website.settings,
				agentUuid: profile.user.uuid,
			})
		}

		if (result?.status === 'success') {
			if (websiteUpdated && result2.status === 'success') {
				enqueueSnackbar(`${context} Updated!`, {
					variant: 'success',
				})
			} else {
				enqueueSnackbar(`${context} Updated!`, {
					variant: 'success',
				})
			}

			setUser(newProfile)
			setProfile(newProfile)
		} else {
			enqueueSnackbar('Error saving', {
				variant: 'error',
			})
		}
	}

	return (
		<>
			<UploadBlock
				icon={<Article color="secondary" />}
				label="Listing Presentation (.pdf, .doc, .docx)"
				description="Your listing presentation should be a complete, professional, and detailed description of your services."
				context="Listing Presentation"
				fileTypes=".pdf,.doc,.docx"
				maxSize={2}
				onUploadComplete={({ publicUrl, filename, label, key, context }) =>
					handleUploadComplete(
						publicUrl,
						filename,
						label,
						key,
						context,
						'listingPresentation'
					)
				}
				value={profile.settings.listingProfile.listingPresentation}
			/>
			<UploadBlock
				icon={<YouTube color="secondary" />}
				label="Welcome Video (.mp4, .m4v, .mov)"
				description="30 seconds maximum. Your welcome video should be professional, simple, and is the perfect way to win a potential client."
				addText="Choose Video"
				context="Welcome Video"
				fileTypes=".mp4,.m4v,.mov"
				maxSize={250}
				onUploadComplete={({ publicUrl, filename, label, key, context }) =>
					handleUploadComplete(
						publicUrl,
						filename,
						label,
						key,
						context,
						'welcomeVideo'
					)
				}
				value={profile.settings.listingProfile.welcomeVideo}
				video
			/>
			<UploadBlock
				hideTag
				icon={<Public color="secondary" />}
				label="Client Tips & Resources"
				description="We’ve included a standard pdf and website link to give your clients some guidance on the selling process. Feel free to add your own document or link."
				context="Client Tips & Resources"
				fileTypes=".pdf,.doc,.docx"
				maxSize={2}
				onLinkUpdated={handleLinkUpdated}
				onUploadComplete={({ publicUrl, filename, label, key, context }) =>
					handleUploadComplete(
						publicUrl,
						filename,
						label,
						key,
						context,
						'tipsAndResources'
					)
				}
				value={profile.settings.listingProfile.tipsAndResources}
			/>
		</>
	)
}
