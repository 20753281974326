import React from 'react'
import { Popover, Typography, TextField } from '@mui/material'
import { IconButton } from 'components/molecules'
import { AvailabilityBlockType } from 'types'
import { Delete } from '@mui/icons-material'
import { Button } from './button'
import { getAvailableTimes } from 'utils'

interface Props {
	index: number
	block: AvailabilityBlockType
	prevBlock?: AvailabilityBlockType
	nextBlock?: AvailabilityBlockType
	onChange: (
		index: number,
		value: AvailabilityBlockType,
		complete: boolean
	) => void
	hideDelete: boolean
	onDelete: (index: number) => void
}

export const TimeBlock = ({
	index,
	block,
	prevBlock,
	nextBlock,
	onChange,
	hideDelete,
	onDelete,
}: Props) => {
	const [instance, setInstance] = React.useState('')
	const [timeBlock, setTimeBlock] = React.useState<AvailabilityBlockType>(block)
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

	const handleChange = (value: string) => {
		if (instance) {
			setTimeBlock({
				...timeBlock,
				[instance]: value,
			})

			handleClose()
		}

		const newEndTime = timeBlock.startTime && instance === 'endTime' && value
		const updatedTime = timeBlock.startTime && timeBlock.endTime

		if (newEndTime || updatedTime) {
			onChange(
				index,
				{
					...timeBlock,
					[instance]: value,
				},
				true
			)
		} else {
			onChange(
				index,
				{
					...timeBlock,
					[instance]: value,
				},
				false
			)
		}
	}

	const handleDelete = () => {
		onDelete(index)
	}

	const handleClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		instance: string
	) => {
		if (instance === 'endTime' && Boolean(!timeBlock.startTime)) {
			return
		}

		setAnchorEl(event.currentTarget)
		setInstance(instance)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	let availableTimes: string[] = []

	React.useMemo(() => {
		if (instance === 'startTime') {
			availableTimes = getAvailableTimes({
				indexFor: prevBlock?.endTime || '12:00am',
				jump: prevBlock?.endTime ? true : false,
				limitTo: block.endTime || undefined,
			})
		} else if (instance === 'endTime') {
			availableTimes = getAvailableTimes({
				indexFor: block.startTime,
				jump: true,
				limitTo: nextBlock?.startTime || undefined,
			})
		}

		return availableTimes
	}, [anchorEl])

	return (
		<div className="ml-3">
			<div className="flex items-center">
				<div
					className="max-w-[95px]"
					onClick={e => handleClick(e, 'startTime')}
				>
					<TextField
						name="startTime"
						label="Start"
						size="small"
						value={timeBlock.startTime}
					/>
				</div>
				<Typography className="mx-2 font-black mb-3" sx={{ fontSize: 18 }}>
					-
				</Typography>
				<div
					className="max-w-[95px]"
					onClick={e => handleClick(e, 'endTime')}
					style={{ opacity: Boolean(!timeBlock.startTime) ? 0.5 : 1 }}
				>
					<TextField
						name="endTime"
						label="End"
						size="small"
						value={timeBlock.endTime}
						disabled={Boolean(!timeBlock.startTime)}
					/>
				</div>
				{!hideDelete && (
					<IconButton
						tooltip="Delete Time Block"
						className="ml-1 mb-3"
						noBorder
						onClick={handleDelete}
					>
						<Delete />
					</IconButton>
				)}
			</div>
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<div className="max-h-[300px] w-[95px]">
					{availableTimes.map(time => {
						return (
							<Button
								key={time}
								variant="flat"
								size="small"
								sx={{ padding: '8px 4px', width: '100%' }}
								onClick={() => handleChange(time)}
							>
								{time}
							</Button>
						)
					})}
				</div>
			</Popover>
		</div>
	)
}
