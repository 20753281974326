import React, { FC } from 'react'
import { Popover, PopoverProps } from '@mui/material'
import { Button } from '../atoms'

interface Props extends Omit<PopoverProps, 'open' | 'onClick'> {
	title?: string
	close?: boolean
	customButton?: React.ReactNode
	onPromptButtonClick?: () => void
	dropdown?: boolean
	endIcon?: React.ReactNode
}

export const PopoverButton: FC<Props> = ({
	title,
	close,
	customButton,
	children,
	endIcon,
	onPromptButtonClick,
	className,
	...rest
}) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

	const handleClick = (event: React.MouseEvent<any>) => {
		setAnchorEl(event.currentTarget)
	}

	React.useEffect(() => {
		if (close) {
			setAnchorEl(null)
		}
	}, [close])

	return (
		<>
			{customButton ? (
				<div onClick={handleClick}>{customButton}</div>
			) : (
				<Button variant="outlined" onClick={handleClick} endIcon={endIcon}>
					{title}
				</Button>
			)}
			<Popover
				{...rest}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
			>
				<div onClick={() => setAnchorEl(null)} className={className}>
					{children}
					{onPromptButtonClick && (
						<div className="flex mt-4 items-center justify-end p-2 border-t border-solid border-gray-200">
							<Button variant="outlined" onClick={() => setAnchorEl(null)}>
								Cancel
							</Button>
							<Button
								className="ml-3"
								variant="contained"
								onClick={onPromptButtonClick}
							>
								Confirm
							</Button>
						</div>
					)}
				</div>
			</Popover>
		</>
	)
}
