export enum LeadStatusEnum {
	NEW = 1,
	SCHEDULED = 2,
	WAITING = 3,
}
export type AddressType = {
	key: string
	value: string
	street: string
	line2: string | undefined
	city: string
	state: string
	zip: string
	beds: number
	bath: number
	sqft: number
	listingOnline: boolean
	notes: [] | null
	stage: number
	contacts: [] | null
	closed: number
	archived: number
	questionnaireData: [] | null
}
export type ClientActivityType = {
	first: string
	last: string
	activity: string
	updatedAt: string
}
export type ClientActivityItemType = {
	title: string
}
export type ClientListingType = {
	type: string
	index: number
	client: string
	address: Omit<AddressType, 'key'>
}
