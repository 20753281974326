import { createTheme, alpha, darken } from '@mui/material'
import { Shadows } from '@mui/material/styles/shadows'
import type {} from '@mui/x-data-grid/themeAugmentation'

export const BADHOUS_ICON = require('./img/badhous-icon.svg')
export const ZILLOW_PNG = require('./img/zillow.png')
export const INSTAGRAM_PNG = require('./img/instagram.png')
export const BUYER_STAMP_PNG = require('./img/buyer.png')
export const SELLER_STAMP_PNG = require('./img/seller.png')
export const BUYER_PNG = require('./img/buy.png')
export const SELLER_PNG = require('./img/sell.png')
export const DEFAULT_USER_PHOTO = require('./img/default-user.png')
export const FOR_SALE_IMG = require('./img/home-for-sale.png')
export const SAMPLE_HOME_PHOTO = require('./img/sample-home.png')
export const SAMPLE_PROPERTY_PHOTO = require('./img/sample-property-photo.png')
export const EMOJI_SMILE = '😃'
export const EMOJI_FLATTER = '🥰'
export const EMOJI_SILLY = '🤪'
export const EMOJI_PAID = '🤑'
export const EMOJI_STUD = '😎'
export const EMOJI_COLD = '🥶'
export const EMOJI_HOT = '🥵'
export const EMOJI_FIRE = '🔥'
export const EMOJI_WOW = '🤩'

const makeShadowArray = (): Shadows => {
	const getValue = (index: number, multiplier: number) => {
		return Math.floor((index + 1) * multiplier)
	}

	const getShadow = (
		index: number,
		params: {
			hOffset: number
			vOffset: number
			blur: number
			spread: number
			color: string
		}
	) => {
		return `${getValue(index, params.hOffset)}px ${getValue(
			index,
			params.vOffset
		)}px ${getValue(index, params.blur)}px ${getValue(
			index,
			params.spread
		)}px ${params.color}`
	}

	return Array.from({ length: 25 }, (_, index) => {
		if (index === 0) return 'none'
		return `${getShadow(index, {
			hOffset: 0,
			vOffset: 0,
			blur: 2,
			spread: -1.5,
			color: 'rgba(0, 0, 0, 0.01)',
		})}, ${getShadow(index, {
			hOffset: 0,
			vOffset: 0,
			blur: 3,
			spread: 0.25,
			color: 'rgba(0,0,0,0.02)',
		})}, ${getShadow(index, {
			hOffset: 0,
			vOffset: 0,
			blur: 4,
			spread: 0.25,
			color: 'rgba(0,0,0,0.04)',
		})}`
	}) as Shadows
}

export const colors = {
	line: '#e4e4e4',
}

const defaultTheme = createTheme({
	spacing: 8,
	shadows: makeShadowArray(),
	shape: {
		borderRadius: 8,
	},
	breakpoints: {
		values: {
			xs: 480,
			sm: 600,
			xmd: 920,
			md: 1094,
			lg: 1200,
			xl: 1536,
			giant: 1760,
		},
	},
	typography: {
		button: {
			textTransform: 'none',
		},
		fontFamily: `Mulish`,
	},
	palette: {
		primary: {
			main: '#252422',
			light: '#e4e4e4',
		},
		secondary: {
			main: '#0c4d25',
			light: '#d1ebdb',
			dark: '#09401e',
		},
		green: {
			main: '#099800',
			light: '#06bc08',
		},
		blue: {
			main: '#047aff',
			dark: '#0064d6',
			light: '#38affd',
		},
		red: {
			main: '#d62839',
			light: '#fd3838',
			dark: '#c60707',
		},
		icon: {
			main: '#365584',
			dark: '#3e4d5b',
		},
		text: {
			primary: '#252422',
		},
		calendar: {
			main: '#006BFF',
		},
	},
})

// Create a theme instance.
export const theme = createTheme({
	...defaultTheme,

	components: {
		MuiAccordion: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
					margin: defaultTheme.spacing(2, 0),
					'&:first-of-type': {
						marginTop: 0,
					},
					'&::before': {
						display: 'none',
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: `${defaultTheme.palette.icon.main} !important`,
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					'& .MuiIconButton-root': {
						border: 'none',
						'& svg': {
							fill: defaultTheme.palette.primary.main,
						},
					},
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontWeight: '700',
					fontSize: 16,
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				root: {
					'& .MuiBackdrop-root': {
						backgroundColor: 'rgba(0, 0, 0, .7) !important',
					},
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					'& .MuiAlert-message': {
						padding: '6px 0',
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'&.variant-card': {
						margin: 0,
						backgroundColor: '#e5e7eb',
						borderRadius: 8,
						'& .Mui-disabled + fieldset': {
							border: 'none',
						},
						'& .MuiInputBase-root:not(.Mui-error) .MuiInputBase-input + fieldset':
							{
								borderColor: '#c5cad3',
								boxShadow: 'none',
							},
						'& .MuiInputBase-root:not(.Mui-error) .MuiInputBase-input:hover + fieldset':
							{
								borderColor: '#8995aa',
							},
						'& .MuiInputBase-root:not(.Mui-error) .MuiInputBase-input:focus + fieldset':
							{
								borderColor: '#8995aa',
								borderWidth: 2,
							},
					},
				},
			},
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: 'none',
					backgroundColor: defaultTheme.palette.common.white,
					'&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
						outline: 'none',
					},
					'&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
						backgroundColor: defaultTheme.palette.grey[100],
						'&:not(.MuiDataGrid-columnHeaderCheckbox)': {
							pointerEvents: 'none',
						},
						'&:focus-within': {
							outline: 'none',
						},
						'& .MuiDataGrid-columnHeaderTitle': {
							fontSize: 16,
							fontWeight: 700,
						},
					},
					'&.MuiDataGrid-root .MuiDataGrid-columnHeader:last-of-type .MuiDataGrid-columnSeparator':
						{
							display: 'none',
						},
					'&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
						padding: defaultTheme.spacing(1, 2),
					},
					'&.MuiDataGrid-root .MuiDataGrid-row': {
						cursor: 'pointer',
						'&:last-of-type > div': {
							border: 'none',
						},
						'& .MuiDataGrid-cell .MuiTypography-root': {
							fontWeight: 600,
						},
					},
				},
			},
		},
		MuiTypography: {
			variants: [
				{
					props: { variant: 'iconLabel' },
					style: {
						fontWeight: defaultTheme.typography.fontWeightBold,
						color: defaultTheme.palette.icon.main,
						fontSize: 18,
					},
				},
				{
					props: { variant: 'contentLabel' },
					style: {
						fontWeight: defaultTheme.typography.fontWeightBold,
						color: defaultTheme.palette.icon.main,
					},
				},
			],
			styleOverrides: {
				root: {
					color: '#333333',
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					borderBottom: `solid 1px ${defaultTheme.palette.grey[200]}`,
				},
			},
		},
		MuiListItemSecondaryAction: {
			styleOverrides: {
				root: {
					right: defaultTheme.spacing(4),
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					padding: `${defaultTheme.spacing(2, 4, 2, 4)} !important`,
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				root: {
					'& *': {
						fontWeight: 600,
					},
					'& .MuiTypography-root': {
						display: 'flex',
					},
					'& .badhous-list-item-checked': {
						textDecoration: 'line-through',
					},
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 'unset',
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					'& svg': {
						fill: defaultTheme.palette.icon.main,
					},
					border: `solid 1px #e0e0e0`,
					'&.noBorder': {
						border: 'none',
					},
					'&.badge': {
						position: 'relative',
						'&::after': {
							position: 'absolute',
							display: 'block',
							content: '""',
							top: 7,
							right: 7,
							height: 10,
							width: 10,
							borderRadius: '50%',
							backgroundColor: defaultTheme.palette.secondary.main,
						},
					},
				},
			},
		},
		MuiButton: {
			variants: [
				{
					props: { variant: 'text' },
					style: {
						padding: defaultTheme.spacing(1, 2),
						boxShadow: 'none',
						'& .MuiTypography-root': {
							color: defaultTheme.palette.primary.main,
							margin: defaultTheme.spacing(0, 'auto', 0, 0),
							transition: '0.2s',
							flex: 1,
							textAlign: 'center',
						},
					},
				},
				{
					props: { variant: 'sidebar' },
					style: {
						padding: defaultTheme.spacing(1, 2.5),
						borderTopRightRadius: 9999,
						borderTopLeftRadius: 0,
						borderBottomRightRadius: 9999,
						borderBottomLeftRadius: 0,
						[defaultTheme.breakpoints.up('xl')]: {
							borderTopLeftRadius: 9999,
							borderBottomLeftRadius: 9999,
						},
						backgroundColor: 'transparent',
						boxShadow: 'none',
						marginBottom: defaultTheme.spacing(1.5),
						'& .MuiTouchRipple-child': {
							backgroundColor: '#bfe3cd !important',
						},
						'& .MuiTypography-root': {
							color: defaultTheme.palette.primary.main,
							margin: defaultTheme.spacing(0, 'auto', 0, 0),
							transition: '0.3s',
							transitionDelay: '0.05s',
							'& svg': {
								position: 'relative',
								top: -1,
								fontSize: defaultTheme.typography.h6.fontSize,
								marginRight: defaultTheme.spacing(1.5),
								transition: '0.35s',
							},
						},
						'&:hover .MuiTypography-root': {
							color: defaultTheme.palette.secondary.main,
							'& svg': {
								fill: `${defaultTheme.palette.secondary.main} !important`,
							},
						},
					},
				},
				{
					props: { variant: 'sidebarSlim' },
					style: {
						justifyContent: 'flex-start',
						margin: `${defaultTheme.spacing(1, 0)} !important`,
						padding: `0 !important`,
						backgroundColor: 'transparent',
						boxShadow: 'none',
						marginLeft: 1,
						'&:hover': {
							backgroundColor: 'transparent !important',
						},
						'& .MuiTouchRipple-child': {
							backgroundColor: 'transparent !important',
						},
						'& .MuiTypography-root': {
							color: defaultTheme.palette.primary.main,
							fontWeight: 700,
							transition: '0.3s',
							transitionDelay: '0.05s',
							'& svg': {
								position: 'relative',
								top: -1,
								fontSize: defaultTheme.typography.h6.fontSize,
								marginRight: defaultTheme.spacing(1.5),
								transition: '0.35s',
							},
						},
						'&:hover .MuiTypography-root': {
							color: defaultTheme.palette.secondary.main,
							'& svg': {
								fill: `${defaultTheme.palette.secondary.main} !important`,
							},
						},
					},
				},
				{
					props: { variant: 'complete' },
					style: {
						position: 'relative',
						padding: defaultTheme.spacing(1, 2.5),
						boxShadow: 'none !important',
						'& .MuiTypography-root': {
							color: defaultTheme.palette.primary.main,
							margin: defaultTheme.spacing(0, 'auto', 0, 0),
							paddingRight: 43,
							transition: '0.2s',
						},
						'& .badhous-divider': {
							position: 'absolute',
							display: 'block',
							content: '""',
							top: 0,
							right: 43,
							bottom: 0,
							width: 1,
						},
						'& .badhous-icon': {
							display: 'flex',
							position: 'relative',
							left: defaultTheme.spacing(1),
						},
					},
				},
				{
					props: { variant: 'marketing' },
					style: {
						backgroundColor: defaultTheme.palette.blue.main,
						'&:hover': {
							backgroundColor: darken(defaultTheme.palette.blue.main, 0.2),
						},
					},
				},
				{
					props: { variant: 'cta' },
					style: {
						backgroundColor: defaultTheme.palette.secondary.main,
						'&:hover': {
							backgroundColor: defaultTheme.palette.secondary.dark,
						},
					},
				},
				{
					props: { variant: 'flat' },
					style: {
						padding: defaultTheme.spacing(2),
						borderRadius: 0,
						boxShadow: 'none',
						'& .MuiTypography-root': {
							color: defaultTheme.palette.primary.main,
							margin: defaultTheme.spacing(0, 'auto', 0, 0),
							transition: '0.2s',
							flex: 1,
							textAlign: 'center',
						},
					},
				},
				{
					props: { variant: 'addNew' },
					style: {
						borderRadius: 4,
						boxShadow: 'none',
						border: `dashed 2px ${defaultTheme.palette.grey[300]}`,
						backgroundColor: defaultTheme.palette.grey[50],
						'& svg': {
							fill: defaultTheme.palette.grey[500],
						},
						'& .MuiTypography-root': {
							color: defaultTheme.palette.grey[600],
							fontWeight: defaultTheme.typography.fontWeightBold,
							marginLeft: defaultTheme.spacing(1),
							transition: '0.2s',
						},
					},
				},
				{
					props: { variant: 'questionnaire' },
					style: {
						borderRadius: 9999,
						width: '100%',
						boxShadow: `0 2px 4px -2px rgba(0,0,0,.25)`,
						marginBottom: defaultTheme.spacing(1.2),
						padding: defaultTheme.spacing(1.6, 4),
						border: `solid 2px ${defaultTheme.palette.grey[300]}`,
						backgroundColor: defaultTheme.palette.common.white,
						'& .MuiTypography-root': {
							color: defaultTheme.palette.primary.main,
							fontWeight: 900,
							fontSize: 20,
							transition: '0.2s',
						},
						'&.active': {
							borderColor: defaultTheme.palette.secondary.main,
						},
					},
				},
				{
					props: { variant: 'calendar' },
					style: {
						backgroundColor: defaultTheme.palette.calendar.main,
						boxShadow: 'none',
						'&:hover': {
							backgroundColor: darken(defaultTheme.palette.calendar.main, 0.2),
						},
						'&.Mui-disabled': {
							backgroundColor: defaultTheme.palette.grey[800],
							'& .MuiTypography-root': {
								color: defaultTheme.palette.common.white,
							},
						},
					},
				},
				{
					props: { variant: 'calendarOutlined' },
					style: {
						padding: defaultTheme.spacing(0.75, 2),
						boxShadow: 'none',
						borderColor: defaultTheme.palette.calendar.main,
						'&:hover': {
							backgroundColor: alpha(defaultTheme.palette.calendar.main, 0.1),
						},
						'& .MuiTouchRipple-child': {
							backgroundColor: `${defaultTheme.palette.calendar.main} !important`,
						},
						'& .MuiTypography-root': {
							color: defaultTheme.palette.calendar.main,
						},
					},
				},
				{
					props: { variant: 'white' },
					style: {
						backgroundColor: '#FFFFFF',
						'&:hover': {
							backgroundColor: '#DDDDDD',
						},
						'& .MuiTypography-root': {
							color: defaultTheme.palette.text.primary,
						},
					},
				},
			],
			styleOverrides: {
				root: {
					padding: defaultTheme.spacing(1, 4),
					borderRadius: 9999,
					boxShadow: `0 2px 8px 0 rgba(0,0,0,.35)`,
					'& .MuiTypography-root': {
						color: defaultTheme.palette.common.white,
						fontWeight: 700,
						lineHeight: '1.75 !important',
						whiteSpace: 'nowrap',
					},
				},
				outlined: {
					'&.Mui-disabled .MuiTypography-root': {
						opacity: 0.5,
					},
					'& .MuiTypography-root': {
						color: defaultTheme.palette.primary.main,
					},
					padding: defaultTheme.spacing(0.75, 3),
					boxShadow: 'none',
				},
			},
		},
	},
})

export const MenuButtonActiveStyle = {
	pointerEvents: 'none',
	backgroundColor: `${theme.palette.secondary.light} !important`,
	'& .MuiTypography-root': {
		color: `${theme.palette.secondary.main} !important`,
	},
}
export const MenuButtonSlimActiveStyle = {
	pointerEvents: 'none',
	backgroundColor: 'transparent !important',
	'& .MuiTypography-root': {
		color: `${theme.palette.secondary.main} !important`,
	},
}

declare module '@mui/material/styles' {
	interface Palette {
		green: Palette['primary']
		blue: Palette['primary']
		red: Palette['primary']
		icon: Palette['primary']
		calendar: Palette['primary']
	}
	interface PaletteOptions {
		green: PaletteOptions['primary']
		blue: PaletteOptions['primary']
		red: PaletteOptions['primary']
		icon: PaletteOptions['primary']
		calendar: PaletteOptions['primary']
	}
}
declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		sidebar: true
		sidebarSlim: true
		marketing: true
		complete: true
		cta: true
		flat: true
		addNew: true
		questionnaire: true
		calendar: true
		calendarOutlined: true
		white: true
	}
}
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		iconLabel: true
		contentLabel: true
	}
}
declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		xmd: true
		giant: true
	}
}
